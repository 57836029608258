const state = {
  page_platform: "",
  page_width: "",
};

const mutations = {
  set_page_platform(state, data) {
    state.page_platform = data;
  },
  set_page_width(state, data) {
    state.page_width = data;
  },
};

const actions = {
  async getEmailCode({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.getEmailCode, data);

    return res;
  },
  async login({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.login, data);

    return res;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
