<template>
  <div class="board-components" :style="!hideMenu ? 'padding-top:0;' : ''">
    <commentTopControl v-if="hideMenu" :title="headerTitle">
      <template slot="right">
        <div
          class="hot-line f12 cg hover"
          @click="isVisibleInfo = !isVisibleInfo"
        >
          <img src="../../../assets/hot-line.png" alt="" />
        </div>
      </template>
    </commentTopControl>
    <section class="rank-wrap" v-for="(item, index) in listData" :key="index">
      <h2
        class="f16"
        :class="{ 'title-open': item.open, 'title-close': !item.open }"
      >
        <div class="left">
          <img
            class="ml"
            :src="
              require(`../../../assets/report/board/number${index + 1}.png`)
            "
          />
          <span class="rank-wrap-title-sp">{{ item.title }}</span>

          <img
            src="../../../assets/hot-line.png"
            class="rank-wrap-title-img"
            alt=""
            @click="isVisibleInfo = !isVisibleInfo"
          />
        </div>
        <img
          v-if="item.open"
          class="click-btn"
          src="../../../assets/report/board/close.png"
          @click="viewDetail(item.open, index)"
        />
        <img
          v-else
          class="click-btn"
          src="../../../assets/report/board/open.png"
          @click="viewDetail(item.open, index)"
        />
      </h2>
      <div v-if="item.open">
        <div class="one-to-three mb16" v-if="item.single">
          <div
            v-for="member in item.data1to3"
            :key="member.rank"
            class="member"
          >
            <span
              class="member-img member-img-sp"
              :class="{ 'member-img-rank1': member.rank == 1 }"
            >
              <img
                :src="
                  require(`../../../assets/report/board/rank${member.rank}.png`)
                "
              />
            </span>
            <span class="member-name member-name-sp f12">
              {{ member.listArr[0].userNameEN }}
            </span>
            <p class="member-score-wrap">
              <span
                class="member-score"
                :class="{ f30: member.rank == 1, f24: member.rank !== 1 }"
                >{{
                  item.typeOut == 2 || item.typeOut == 3
                    ? (member.score * 100).toFixed(2) + "%"
                    : member.score
                }}</span
              >
              <span class="f10" v-if="index == 0">Invitations</span>
              <span class="f10" v-else-if="index == 3 || index == 4"
                >Feedbacks</span
              >
              <span class="f10" v-else-if="index == 5">Words</span>
              <span class="f10" v-else></span>
            </p>
          </div>
        </div>
        <div class="one-to-three mb16" v-else style="flex-wrap: wrap">
          <div
            v-for="member in item.data1to3"
            :key="member.rank"
            class="member multiple"
            :class="{ 'one-to-three-border': member.rank >= 4 }"
          >
            <div class="left">
              <span class="member-img" v-if="member.rank < 4">
                <img
                  :src="
                    require(`../../../assets/report/board/rank${member.rank}.png`)
                  "
                />
              </span>
              <span v-else>
                {{ member.rank }}
              </span>
              <p>
                <span
                  class="member-name"
                  v-for="(name, index) in member.listArr"
                  :key="index"
                  >{{ name["userNameEN"] }}</span
                >
              </p>
            </div>
            <p class="member-score-wrap">
              <span
                class="member-score"
                :class="{ f30: member.rank == 1, f24: member.rank !== 1 }"
                >{{
                  item.typeOut == 2 || item.typeOut == 3
                    ? (member.score * 100).toFixed(2) + "%"
                    : member.score
                }}</span
              >
              <span class="f10" v-if="index == 0">Invitations</span>
              <span class="f10" v-else-if="index == 3 || index == 4"
                >Feedbacks</span
              >
              <span class="f10" v-else-if="index == 5">Words</span>
              <span class="f10" v-else></span>
            </p>
          </div>
        </div>
        <div
          class="four-to-five"
          v-for="member in item.data4to5"
          :key="member.rank"
        >
          <div class="member">
            <p>
              <span
                v-if="member.rank !== 1"
                class="f12"
                :class="
                  member.rankType == 'up'
                    ? 'member-rank-up'
                    : 'member-rank-down'
                "
                >{{ member.rank }}</span
              >
              <span class="member-name f12">
                <span v-for="(name, index) in member.listArr" :key="index">{{
                  index == 0 ? name["userNameEN"] : " ，" + name["userNameEN"]
                }}</span>
              </span>
            </p>
            <p class="member-score-wrap">
              <span class="member-score f24">{{
                item.typeOut == 2 || item.typeOut == 3
                  ? (member.score * 100).toFixed(2) + "%"
                  : member.score
              }}</span>
              <span class="f10" v-if="index == 0">Invitations</span>
              <span class="f10" v-else-if="index == 3 || index == 4"
                >Feedbacks</span
              >
              <span class="f10" v-else-if="index == 5">Words</span>
              <span class="f10" v-else></span>
            </p>
          </div>
        </div>
      </div>
    </section>
    <customModal :visible="isVisibleInfo" @onClose="isVisibleInfo = false">
      <div class="mb16" v-for="item in modalData" :key="item.key">
        <span class="f16 fb">{{ item.title }}</span> -
        <span>{{ item.description }}</span>
      </div>
    </customModal>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import commentTopControl from "@/src/components/comment/commentTopControl.vue";
import customModal from "./components/customModal";
export default {
  name: "board",
  components: {
    commentTopControl,
    customModal,
  },
  data() {
    return {
      hideMenu: true,
      headerTitle: "Individual Board",
      listData: [
        {
          open: true,
          title: "Most Trusted Board",
          data1to3: [],
          data4to5: [],
        },
        {
          open: false,
          title: "Eagle Eye Board",
          data1to3: [],
          data4to5: [],
        },
        {
          open: false,
          title: "Self Awareness Board",
          data1to3: [],
          data4to5: [],
        },
        {
          open: false,
          title: "Most Enthusiastic Board",
          data1to3: [],
          data4to5: [],
        },
        {
          open: false,
          title: "Popularity Star Board",
          data1to3: [],
          data4to5: [],
        },
        {
          open: false,
          title: "Best Contribution Board",
          data1to3: [],
          data4to5: [],
        },
      ],
      isVisibleInfo: false,
      modalData: [
        {
          key: 1,
          title: "Most Trusted Board",
          description: "Employees with the most invitations",
        },
        {
          key: 2,
          title: "Eagle Eye Board",
          description:
            "Employees whose feedback has the smallest gap to the average feedback given for each individual",
        },
        {
          key: 3,
          title: "Self Awareness Board ",
          description:
            "Employees whose self evaluation matches others evaluations of them",
        },
        {
          key: 4,
          title: "Most Enthusiastic Board",
          description: "Employees who proactively give feedback on others",
        },
        {
          key: 5,
          title: "Popularity Star Board ",
          description: "Employees who attract the most un-invited feedback",
        },
        {
          key: 6,
          title: "Best Contribution Board",
          description: "Employees who give the most written comments",
        },
      ],
    };
  },
  created() {
    this.pageInit();
  },
  mounted() {
    if (this.$route.query.menu == "hide") {
      this.hideMenu = false;
    }
    $(window).scrollTop(0);
  },
  methods: {
    ...mapActions({
      getBoardIndividual: "board/getBoardIndividual",
    }),
    async pageInit() {
      let res = await this.getBoardIndividual();
      let result = res.data.data.map((item) => {
        return {
          ...item,
          rptRankingList: this.sortfn(item.rptRankingListDTOS),
        };
      });
      console.log(result);
      for (let i = 0; i < this.listData.length; i++) {
        let resultChoose = result.find((item) => item.type == i + 1);

        this.listData[i].typeOut = resultChoose.type;
        this.listData[i].data1to3 = resultChoose.rptRankingList.slice(0, 3);
        this.listData[i].data4to5 = resultChoose.rptRankingList.slice(
          3,
          resultChoose.rptRankingList.length
        );
        let single = this.listData[i].data1to3.every((item) => {
          return item.listArr.length === 1;
        });
        this.listData[i]["single"] = single;
        if (single) {
          let rank0 = this.listData[i]["data1to3"][0];
          let rank1 = this.listData[i]["data1to3"][1];
          this.listData[i]["data1to3"][0] = rank1;
          this.listData[i]["data1to3"][1] = rank0;
        }
      }
      console.log(this.listData);
    },
    sortfn(demo) {
      let resultArr = [];
      for (let i = 0; i < demo.length; i++) {
        const demo_item = demo[i];

        let index = resultArr.findIndex((item) => {
          return item["score"] == demo_item["score"];
        });
        if (index != -1) {
          resultArr[index]["listArr"].push(demo_item);
          if (index > 0) {
            let rank = 0;
            for (let j = 0; j < index; j++) {
              rank += resultArr[j].listArr.length;
            }
            resultArr[index]["rank"] = rank;
          }
        } else {
          let obj = {
            score: demo_item["score"],
            listArr: [demo_item],
            rank: resultArr.length + 1,
          };
          resultArr.push(obj);
        }
      }
      resultArr = resultArr.map((item, index) => {
        let rank = 1;
        for (let j = 0; j < index; j++) {
          rank += resultArr[j].listArr.length;
        }
        return {
          ...item,
          rank,
        };
      });
      return resultArr;
    },
    viewDetail(open, index) {
      this.listData[index].open = !open;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/variable";
@media screen and (max-width: 760px) {
  .hot-line {
    margin: auto;
    img {
      width: toMRem(16);
      height: toMRem(16);
    }
  }
  .board-components {
    padding-top: toMRem(76);
    padding-bottom: toMRem(50);
  }
  .rank-wrap {
    width: toMRem(338);
    margin: 0 auto toMRem(20);
    background: #ffffff;
    box-shadow: 0px 4px 9px rgba(209, 209, 209, 0.25);
    border-radius: toMRem(16);
    padding: toMRem(20) 0;
    position: relative;

    .rank-wrap-title-sp {
      width: toMRem(140);
    }

    .rank-wrap-title-img {
      width: toMRem(16) !important;
      cursor: pointer;
      margin-left: toMRem(10);
    }
    .f30 {
      font-size: toMRem(30);
    }
    .title-open,
    .title-close {
      display: flex;
      align-items: center;
      padding: 0 toMRem(20);
      .left {
        img {
          width: toMRem(40);
        }
      }
    }
    .title-open {
      justify-content: center;
      margin-bottom: toMRem(24);
      .left {
        position: relative;
        img {
          position: absolute;
          left: toMRem(-50);
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .click-btn {
        position: absolute;
        right: toMRem(20);
        top: toMRem(30);
      }
    }

    .title-close {
      justify-content: space-between;
      .left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          display: inline-block;
          width: toMRem(140);
          margin-left: toMRem(16);
        }
      }
    }

    .one-to-three {
      display: flex;
      justify-content: space-between;
      // padding: 0 toMRem(20);
      .member {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: toMRem(8);
        box-sizing: border-box;
        width: 33.33%;
        &-name {
          height: toMRem(32);
          overflow: hidden;
          text-align: center;
        }
        &-img {
          img {
            width: toMRem(70);
          }
        }
        &-score-wrap {
          height: toMRem(60);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .member-score {
            line-height: 1;
          }
          .member-feedbacks {
            font-weight: 500;
          }
        }
        &-img-rank1 {
          img {
            width: toMRem(78);
          }
        }
      }
      .multiple {
        min-width: 100%;
        flex-direction: row;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          p {
            display: flex;
            flex-direction: column;
            .member-name {
              text-align: left;
              line-height: 24px;
              height: 24px;
            }
          }
        }
      }
      .one-to-three-border {
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        padding: toMRem(8) toMRem(20);
        padding-right: 0;
        .member-name {
          margin-left: toMRem(20);
        }
      }
    }
    .four-to-five {
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      padding: toMRem(8) toMRem(20);
      .member {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-name {
          margin-left: toMRem(20);
        }
      }
      .member-score-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
@media screen and (min-width: 761px) {
  .hot-line {
    margin: auto;
    img {
      width: toPad(16);
      height: toPad(16);
    }
  }
  .board-components {
    padding-top: toPad(100);
    padding-bottom: toPad(50);
  }
  .rank-wrap {
    width: 96vw;
    margin: 0 auto toPad(20);
    background: #ffffff;
    box-shadow: 0px 4px 9px rgba(209, 209, 209, 0.25);
    border-radius: toPad(16);
    padding: toPad(20) 0;
    position: relative;

    .rank-wrap-title-sp {
      width: toPad(140);
    }

    .rank-wrap-title-img {
      width: toPad(16) !important;
      cursor: pointer;
      margin-left: toPad(10);
    }

    .f30 {
      font-size: toPad(30);
    }
    .title-open,
    .title-close {
      display: flex;
      align-items: center;
      padding: 0 toPad(20);
      justify-content: space-between;
      .left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: toPad(40);
        }
        span {
          display: inline-block;

          margin-left: toPad(16);
        }
      }
    }
    .title-close {
      .left {
        span {
          width: toPad(140);
        }
      }
    }
    .one-to-three {
      display: flex;
      justify-content: center;
      padding: 0 toPad(20);
      .member {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: toPad(120);

        .member-img-sp {
          display: block;
          width: 100%;
          height: toPad(80);
          display: flex;
          align-items: flex-end;
          justify-content: center;
        }
        .member-name-sp {
          display: block;
          width: 100%;
          height: toPad(32);
          text-align: center;
          margin-top: toPad(10);
        }

        &-img {
          img {
            width: toPad(70);
          }
        }
        &-score-wrap {
          height: toPad(60);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .member-score {
            line-height: 1;
          }
          .member-feedbacks {
            font-weight: 500;
          }
        }
        &-img-rank1 {
          img {
            width: toPad(78);
          }
        }
      }
      .multiple {
        min-width: 100%;
        flex-direction: row;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          p {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    .one-to-three-border {
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      padding: toPad(8) toPad(20);
      padding-right: 0;
      .member-name {
        margin-left: toPad(20);
      }
    }
    .four-to-five {
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      padding: toPad(8) toPad(20);
      .member {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-name {
          margin-left: toPad(20);
        }
      }
      .member-score-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
</style>
