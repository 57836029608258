<template>
  <div class="invalidFeedback-comp">
    <div class="invalidFeedback-comp-in" v-if="!hasStatus">
      <section class="invalidFeedback-comp-title">
        <i class="el-icon-close"></i>

        <label>Invalid feedback</label>

        <em @click="openStatus = !openStatus">{{
          openStatus ? "Close -" : "More +"
        }}</em>
      </section>

      <section class="invalidFeedback-comp-sp" v-if="openStatus">
        Complete to feedback request
      </section>

      <section class="invalidFeedback-comp-list" v-if="openStatus">
        <ul>
          <li
            v-for="(item, index) in pageList"
            :key="'invalidFeedbackcomp' + index"
            :class="{ selected: item.selected }"
          >
            <i class="el-icon-close" v-if="!item.selected"></i>
            <i class="el-icon-check" v-if="item.selected"></i>

            <p>{{ item.name }}</p>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  props: { userIds: String },
  data() {
    return {
      userId: this.$route.query.userId,
      openStatus: true,
      hasStatus: true,
      pageList: [
        {
          mapId: 1,
          name: "Line Manager",
        },
        {
          mapId: 3,
          name: "At least 2 colleague who is more senior (higher grade)",
        },
        {
          mapId: 2,
          name: "At least 2 cross-functional colleague (Within enterprise)",
        },
        {
          mapId: 4,
          name: "At least 2 colleague who is a peer (same grade)",
        },
        {
          mapId: 5,
          name: "At least 1 member of your team who reports to you (if applicable)",
        },
        {
          mapId: 6,
          name: "Receive at least 8 feedbacks",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      indvList: "indv/indvList",
    }),
    async pageInit() {
      let params = [];
      if (this.userId) {
        params = [this.userId];
      }
      let res = await this.indvList(params);

      if (res.data && res.data.data && res.data.data.length > 0) {
        this.hasStatus = res.data.data[0].standards;
        if (this.hasStatus == true) {
          this.$store.commit("individual/setIsInvalid", true);
        } else {
          this.$store.commit("individual/setIsInvalid", false);
        }
        if (res.data.data[0].reasons) {
          this.pageList.forEach((val) => {
            if (
              val.mapId < 6 &&
              !res.data.data[0].reasons.includes(val.mapId)
            ) {
              this.$set(val, "selected", true);
            }
          });
        }
      }
    },
  },
  mounted() {
    console.log(this.userId);
    this.pageInit();
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.invalidFeedback-comp {
  width: 100%;
}

@media screen and (max-width: 760px) {
  .invalidFeedback-comp {
    padding-bottom: toMRem(20);
  }
  .invalidFeedback-comp-in {
    width: 100%;
    background: #fff;
    border-radius: toMRem(8);
    box-shadow: 0 toMRem(16) toMRem(23) rgba(66, 77, 68, 0.1);
    padding: toMRem(20) toMRem(16);
  }
  .invalidFeedback-comp-title {
    display: flex;
    width: 100%;
    align-items: center;

    i {
      display: inline-block;
      width: toMRem(32);
      flex-shrink: 0;
      font-size: toMRem(20);
      color: #e96e6e;
      font-weight: bold;
    }
    label {
      display: inline-block;
      flex-basis: 100%;
      font-size: toMRem(16);
      padding: 0 toMRem(13) 0 0;
    }
    em {
      display: inline-block;
      width: toMRem(80);
      flex-shrink: 0;
      text-align: right;
      color: #3e886d;
      font-weight: normal;
      font-style: normal;
      cursor: pointer;
    }
  }
  .invalidFeedback-comp-sp {
    width: 100%;
    opacity: 0.4;
    padding: toMRem(6) 0 0 toMRem(32);
    line-height: 1.4;
  }
  .invalidFeedback-comp-list {
    width: 100%;

    ul {
      display: block;
      width: 100%;
      overflow: hidden;
      padding-bottom: toMRem(10);

      li {
        display: flex;
        width: 100%;
        float: left;
        padding: toMRem(20) 0 0 toMRem(32);

        i {
          display: inline-block;
          width: toMRem(28);
          flex-shrink: 0;
          font-size: toMRem(16);
          color: #e96e6e;
          font-weight: bold;
        }
        p {
          flex-basis: 100%;
          opacity: 0.2;
          line-height: 1.4;
        }

        &.selected {
          p {
            opacity: 1;
          }
          i {
            color: #3e886d;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 761px) {
  .invalidFeedback-comp {
    padding-bottom: toRem(20);
  }
  .invalidFeedback-comp-in {
    width: 100%;
    background: #fff;
    border-radius: toRem(8);
    box-shadow: 0 toRem(16) toRem(23) rgba(66, 77, 68, 0.1);
    padding: toRem(22);
  }
  .invalidFeedback-comp-title {
    display: flex;
    width: 100%;
    align-items: center;

    i {
      display: inline-block;
      width: toRem(32);
      flex-shrink: 0;
      font-size: toRem(20);
      color: #e96e6e;
      font-weight: bold;
    }
    label {
      display: inline-block;
      flex-basis: 100%;
      font-size: toRem(16);
      padding: 0 toRem(13) 0 0;
    }
    em {
      display: inline-block;
      width: toRem(80);
      flex-shrink: 0;
      text-align: right;
      color: #3e886d;
      font-weight: normal;
      font-style: normal;
      cursor: pointer;
    }
  }
  .invalidFeedback-comp-sp {
    width: 100%;
    opacity: 0.4;
    padding: toRem(26) 0 0 toRem(32);
    line-height: 1.4;
  }
  .invalidFeedback-comp-list {
    width: 100%;

    ul {
      display: block;
      width: 100%;
      overflow: hidden;

      li {
        display: flex;
        width: 50%;
        float: left;
        padding: toRem(20) 0 0 toRem(64);

        i {
          display: inline-block;
          width: toRem(28);
          flex-shrink: 0;
          font-size: toRem(16);
          color: #e96e6e;
          font-weight: bold;
        }
        p {
          flex-basis: 100%;
          opacity: 0.2;
        }

        &.selected {
          p {
            opacity: 1;
          }
          i {
            color: #3e886d;
          }
        }
      }
    }
  }
}
</style>
