<template>
  <div>
    <section
      class="question-seeAll-content"
      :class="{ show: questionSeeAll }"
      v-if="questionSeeAll"
    >
      <div
        class="question-seeAll-content-op"
        @click="questionSeeAllFn_close"
      ></div>

      <div class="question-seeAll-content-in">
        <div class="top-nav cg f12 fjsa">
          <p class="nav-item fjc hover" @click="linkTo(`/individual`)">
            <img src="../assets/my-rep.png" alt="" /> My report
          </p>
          <p
            class="nav-item fjc hover"
            v-if="isShowEnt"
            @click="linkTo(`/enterprise`)"
          >
            <img src="../assets/ent-rep.png" alt="" /> Enterprise report
          </p>
        </div>

        <div class="tab-wrap f14 fjsb">
          <div
            class="tab-item fjc hover"
            :class="tabIndex == 0 ? 'active' : ''"
            @click="swatchTab(0)"
          >
            BU
          </div>
          <div
            class="tab-item fjc hover"
            :class="tabIndex == 1 ? 'active' : ''"
            @click="swatchTab(1)"
          >
            Individual
          </div>
        </div>
        <van-search
          v-model="value"
          placeholder="Search"
          @search="pageInit"
          left-icon=""
        >
          <template #left-icon>
            <img class="search-icon" src="../assets/search.png" alt="" />
          </template>
          <template #right-icon>
            <div class="search-btn hover" @click="onSearch">
              <!-- <van-icon name="plus" color="#fff" /> -->
              Search
            </div>
          </template>
        </van-search>
        <div class="list-wrap">
          <template v-if="tabIndex == 0">
            <el-menu
              :default-openeds="openArr"
              class="el-menu-vertical-demo"
              v-for="(item, i) in deptList"
              :key="i"
            >
              <el-submenu :index="`${i + 1}`">
                <template slot="title">
                  <p class="user-title fjsb f14">
                    <span class="part-name">{{ item.name }}</span>
                    <span
                      v-if="item.view"
                      class="link-btn tac f10 hover"
                      @click.stop="
                        linkTo(
                          `/department?dictId=${item.depId}&Name=${item.name}`
                        )
                      "
                      >view</span
                    >
                  </p>
                </template>
                <el-submenu
                  :index="`${i + 1}${j + 1}`"
                  v-for="(items, j) in item.evpList"
                  :key="j"
                >
                  <template slot="title">
                    <p class="sub-user-title fjsb f14">
                      <span class="part-name">{{ items.name }}</span>
                      <span
                        v-if="items.view"
                        class="link-btn tac f10 hover"
                        @click.stop="
                          linkTo(
                            `/department?dictId=${items.depId}&Name=${items.name}`
                          )
                        "
                        >view</span
                      >
                    </p></template
                  >
                  <el-menu-item
                    style="padding: 0 !important; color: #000 !important"
                    :index="`${i + 1}${j + 1}${k + 1}`"
                    v-for="(itema, k) in items.vpsList"
                    :key="k"
                    ><p class="sub-user-title fjsb f14">
                      <span class="part-name">{{ itema.name }}</span>
                      <span
                        v-if="itema.view"
                        class="link-btn tac f10 hover"
                        @click="
                          linkTo(
                            `/department?dictId=${itema.depId}&Name=${itema.name}`
                          )
                        "
                        >view</span
                      >
                    </p></el-menu-item
                  >
                </el-submenu>
              </el-submenu>
            </el-menu>
          </template>
          <template v-else>
            <el-menu
              :default-openeds="openArr"
              class="el-menu-vertical-demo"
              v-for="(item, i) in userList"
              :key="i"
            >
              <el-submenu :index="`${i + 1}`">
                <template slot="title">
                  <p class="user-title fjsb f14">
                    <span>{{ item.name }}</span>
                    <span></span>
                  </p>
                </template>
                <el-menu-item
                  style="
                    padding: 0 !important;
                    color: #000 !important;
                    height: 60px !important;
                    line-height: 60px !important;
                  "
                  :index="`${i + 1}${k + 1}`"
                  v-for="(itema, k) in item.userList"
                  :key="k"
                  ><div class="sub-user-title fjsb f14">
                    <div class="fcjsb name-text f10">
                      <span>{{ itema.nameEn }}</span>
                      <span class="c39">{{ itema.email }}</span>
                    </div>

                    <span
                      v-if="itema.view"
                      class="link-btn tac f10 hover"
                      @click="
                        linkTo(
                          `/individual?userId=${itema.userId}&Name=${itema.nameEn}`
                        )
                      "
                      >view</span
                    >
                  </div></el-menu-item
                >
                <!-- <el-submenu
                  :index="`${i + 1}${j + 1}`"
                  v-for="(items, j) in item.evpList"
                  :key="j"
                >
                  <template slot="title">
                    <p class="sub-user-title fjsb f14">
                      <span>{{ items.name }}</span>
                      <span
                        class="link-btn tac f10"
                        @click="linkTo(`/department?dictId=${items.depId}`)"
                        >view</span
                      >
                    </p></template
                  >
                  <el-menu-item
                    style="padding: 0 !important; color: #000 !important"
                    :index="`${i + 1}${j + 1}${k + 1}`"
                    v-for="(itema, k) in items.vpsList"
                    :key="k"
                    ><p class="sub-user-title fjsb f14">
                      <span>{{ itema.name }}</span>
                      <span
                        class="link-btn tac f10"
                        @click="linkTo(`/department?dictId=${itema.depId}`)"
                        >view</span
                      >
                    </p></el-menu-item
                  >
                </el-submenu> -->
              </el-submenu>
            </el-menu>
          </template>
        </div>

        <div class="question-seeAll-back" @click="questionSeeAllFn_close"></div>
      </div>
    </section>
    <div
      class="question-seeAll"
      v-if="!questionSeeAll"
      @click="questionSeeAllFn"
    ></div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      isShowEnt: false,
      questionSeeAll: false,
      radio1: "Enterprise",
      tabIndex: 0,
      value: "",
      userList: [],
      deptList: [],
      openArr: ["1", "11", "111"],
    };
  },
  mounted() {
    this.pageInit();
    this.getDept();
    this.getUsers();
  },
  methods: {
    ...mapActions({
      isEnterPrise: "invite/isEnterPrise",
      queryDept: "invite/queryDept",
      queryUser: "invite/queryUser",
    }),
    async pageInit() {
      //   this.loadingShow = true;
      let res = await this.isEnterPrise();
      this.isShowEnt = res.data.data;
      //   this.loadingShow = false;
    },
    async getDept() {
      //   this.loadingShow = true;

      let res = await this.queryDept({ name: this.value });
      this.deptList = res.data.data;
      //   this.loadingShow = false;
    },
    async getUsers() {
      //   this.loadingShow = true;

      let res = await this.queryUser({ name: this.value });
      this.userList = res.data.data;
      //   this.loadingShow = false;
    },
    onSearch() {
      if (this.tabIndex) {
        this.getUsers();
      } else {
        this.getDept();
      }
    },
    questionSeeAllFn() {
      this.questionSeeAll = true;
    },
    questionSeeAllFn_close() {
      this.questionSeeAll = false;
    },
    swatchTab(val) {
      this.value = "";
      this.tabIndex = val;
      if (this.tabIndex) {
        this.getUsers();
      } else {
        this.getDept();
      }
    },
    linkTo(val) {
      this.$router.push(val);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/variable";
@media screen and (max-width: 760px) {
  .question-seeAll {
    position: fixed;
    z-index: 30;
    width: toMRem(50);
    height: toMRem(50);
    right: toMRem(-20);
    top: 50%;
    background: url("~@/src/assets/comment/de-left2.png") no-repeat #3a3b3b
      toMRem(12) center;
    background-size: auto toMRem(24);
    border-radius: 100%;
    box-shadow: 0 0 toMRem(6) #666;
    cursor: pointer;
  }
  .question-seeAll-back {
    position: absolute;
    z-index: 31;
    width: toMRem(50);
    height: toMRem(50);
    left: toMRem(-50);
    top: 50%;
    background: url("~@/src/assets/comment/de-right.png") no-repeat #3a3b3b
      center center;
    background-size: auto toMRem(24);
    border-radius: 100%;
    box-shadow: 0 0 toMRem(6) #666;
    cursor: pointer;
  }
  @keyframes mymove {
    from {
      right: toMRem(-284);
    }
    to {
      right: 0;
    }
  }
  .question-seeAll-content {
    position: fixed;
    z-index: 30;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    .question-seeAll-content-op {
      position: relative;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.5;
      z-index: 30;
    }
    .question-seeAll-content-in {
      position: absolute;
      z-index: 31;
      width: toMRem(330);
      height: 100%;
      right: 0;
      top: 0;
      background: #fff;
      box-shadow: 0 0 toMRem(12) #333;
      padding: toMRem(56) 0 toMRem(10);
      animation: mymove 0.5s;

      .top-nav {
        padding: toMRem(16);
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        .nav-item {
          img {
            width: toMRem(18);
            height: toMRem(18);
            margin-right: toMRem(6);
          }
        }
      }
      .tab-wrap {
        width: 100%;
        .tab-item {
          width: 50%;
          height: toMRem(50);
          background: rgba($color: #c4c4c4, $alpha: 0.1);
          &.active {
            background: #ffffff;
            font-weight: bold;
          }
        }
      }
      /deep/ .van-search__content {
        padding-left: toMRem(6);
        background-color: #fff;
        .van-field__control {
          text-indent: 1em;
        }
        .search-icon {
          margin-top: toMRem(2);
          width: toMRem(18);
          height: toMRem(18);
        }
        .search-btn {
          display: inline-block;
          padding: toMRem(3) toMRem(14);
          border-radius: toMRem(20);
          // border: 1px solid #3e886d;
          background: rgba($color: #3e886d, $alpha: 1);
          color: #fff;
        }
      }
      .list-wrap {
        height: 70vh;
        overflow-y: scroll;
        padding-bottom: toMRem(100);
        .user-title {
          padding: toMRem(4) toMRem(10) toMRem(4) toMRem(40);
          background: rgba($color: #c9cece, $alpha: 0.13);
          line-height: toMRem(50);
        }
        .sub-user-title {
          align-items: center;
          height: toMRem(60);
          line-height: toMRem(60);
          padding: toMRem(0) toMRem(10) toMRem(0) toMRem(40);
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          .name-text {
            width: 75%;
            span {
              line-height: 1 !important;
              margin-bottom: toMRem(6);
              font-weight: 400;
              display: inline-block;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .part-name {
            display: inline-block;
            width: 75%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .link-btn {
          display: inline-block;
          width: toMRem(67);
          height: toMRem(26);
          line-height: toMRem(26);
          background: #fff;
          border-radius: toMRem(26);
          color: #3e886d;
          border: 1px solid #3e886d;
        }
        /deep/ .el-submenu__title {
          //   height: toMRem(50);
          line-height: 1 !important;
          padding-left: toMRem(16) !important;
          padding: 0 !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          .el-submenu__icon-arrow {
            // left: toMRem(10);
            right: 90%;
            top: 50%;
            font-size: toMRem(20);
          }
          /deep/.el-menu {
            padding: 0 !important;
            .el-menu-item {
              padding-left: 0 !important;
              padding: 0 !important;
              color: #000 !important;
              height: toMRem(60) !important;
              line-height: toMRem(60) !important;
              &.is-active {
                color: #000 !important;
              }
            }
            /deep/.el-submenu {
              padding: 0 !important;
              height: toMRem(60) !important;
              line-height: toMRem(60) !important;
              .el-menu-item {
                padding-left: 0 !important;
                padding: 0 !important;
                height: toMRem(60) !important;
                line-height: toMRem(60) !important;
                &.is-active {
                  color: #000 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 761px) {
  .question-seeAll {
    position: fixed;
    z-index: 30;
    width: toPad(64);
    height: toPad(64);
    right: toPad(-32);
    top: 50%;
    background: url("~@/src/assets/comment/de-left2.png") no-repeat #3a3b3b
      toPad(12) center;
    background-size: auto toPad(24);
    border-radius: 100%;
    box-shadow: 0 0 toPad(6) #666;
    cursor: pointer;
  }
  .question-seeAll-back {
    position: absolute;
    z-index: 31;
    width: toPad(50);
    height: toPad(50);
    left: toPad(-50);
    top: 50%;
    background: url("~@/src/assets/comment/de-right.png") no-repeat #3a3b3b
      center center;
    background-size: auto toPad(24);
    border-radius: 100%;
    box-shadow: 0 0 toPad(6) #666;
    cursor: pointer;
  }
  @keyframes mymove {
    from {
      right: toPad(-284);
    }
    to {
      right: 0;
    }
  }
  .question-seeAll-content {
    position: fixed;
    z-index: 30;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    .question-seeAll-content-op {
      position: relative;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.5;
      z-index: 30;
    }
    .question-seeAll-content-in {
      position: absolute;
      z-index: 31;
      width: toPad(330);
      height: 100%;
      right: 0;
      top: 0;
      background: #fff;
      box-shadow: 0 0 toPad(12) #333;
      padding: toPad(76) 0 toPad(10);
      animation: mymove 0.5s;

      .top-nav {
        padding: toPad(16);
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        .nav-item {
          img {
            width: toPad(18);
            height: toPad(18);
            margin-right: toPad(6);
          }
        }
      }
      .tab-wrap {
        width: 100%;
        .tab-item {
          width: 50%;
          height: toPad(50);
          background: rgba($color: #c4c4c4, $alpha: 0.1);
          &.active {
            background: #ffffff;
            font-weight: bold;
          }
        }
      }
      /deep/ .van-search__content {
        padding-left: toPad(6);
        background-color: #fff;
        .van-field__control {
          text-indent: 1em;
        }
        .search-icon {
          margin-top: toPad(2);
          width: toPad(18);
          height: toPad(18);
        }
        .search-btn {
          display: inline-block;
          padding: toPad(3) toPad(14);
          border-radius: toPad(20);
          // border: 1px solid #3e886d;
          background: rgba($color: #3e886d, $alpha: 1);
          color: #fff;
        }
      }
      .list-wrap {
        height: 65vh;
        overflow-y: scroll;
        padding-bottom: toPad(100);
        .user-title {
          padding: toPad(4) toPad(20) toPad(4) toPad(40);
          background: rgba($color: #c9cece, $alpha: 0.13);
          line-height: toPad(50);
        }
        .sub-user-title {
          align-items: center;
          height: toPad(60);
          line-height: toPad(60);
          padding: toPad(0) toPad(20) toPad(0) toPad(40);
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          .name-text {
            width: 75%;
            span {
              line-height: 1 !important;
              margin-bottom: toMRem(6);
              font-weight: 400;
              display: inline-block;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .part-name {
            display: inline-block;
            width: 75%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .link-btn {
          display: inline-block;
          width: toPad(67);
          height: toPad(26);
          line-height: toPad(26);
          background: #fff;
          border-radius: toPad(26);
          color: #3e886d;
          border: 1px solid #3e886d;
        }
        /deep/ .el-submenu__title {
          //   height: toPad(50);
          line-height: 1 !important;
          padding-left: toPad(16) !important;
          padding: 0 !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          .el-submenu__icon-arrow {
            // left: toPad(10);
            right: 90%;
            top: 50%;
            font-size: toPad(20);
          }
          /deep/.el-menu {
            padding: 0 !important;
            .el-menu-item {
              padding-left: 0 !important;
              padding: 0 !important;
              color: #000 !important;
              height: toPad(60) !important;
              line-height: toPad(60) !important;
              &.is-active {
                color: #000 !important;
              }
            }
            /deep/.el-submenu {
              padding: 0 !important;
              height: toPad(60) !important;
              line-height: toPad(60) !important;
              .el-menu-item {
                padding-left: 0 !important;
                padding: 0 !important;
                height: toPad(60) !important;
                line-height: toPad(60) !important;
                &.is-active {
                  color: #000 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
