const state = {
  isInivte: false,
  emailOut: "",
  emailVip: ["lgu7@jaguarlandrover.com", "QPAN@JAGUARLANDROVER.COM"],
  emailVipStatus: false,
  checkMail: "",
  isApp: "",
};

const mutations = {
  set_isApp(state, data) {
    state.isApp = data;
  },
  setCheckMail(state, data) {
    state.checkMail = data;
  },
  setIsInivte(state, data) {
    state.isInivte = data;
  },
  setemail(state, data) {
    state.emailOut = data.data;

    if (state.emailOut) {
      let vipTf = state.emailVip.some((val) => {
        let valLs = val.toLowerCase(),
          resLs = state.emailOut.toLowerCase();
        if (valLs === resLs) {
          return true;
        }
      });

      if (vipTf) {
        state.emailVipStatus = true;
      } else {
        state.emailVipStatus = false;
      }
    }
  },
};

const actions = {
  async questionList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.questionList + "/" + data.code,
      { params: data.params }
    );

    return res;
  },
  async submit_question({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.submit_question, data);

    return res;
  },
  async emailInfo({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.emailInfo, {
      params: data,
    });

    commit("setemail", res.data);

    return res;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
