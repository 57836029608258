import Vue from "vue";
import Router from "vue-router";
import store from "./store/store";
Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

var router = new Router({
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/login",
      name: "login",
      component: () => import("views/login"),
      children: [],
    },
    {
      path: "/home",
      name: "invitaHome",
      component: () => import("views/invitation/home"),
      children: [],
    },
    {
      path: "/add",
      name: "add",
      component: () => import("views/invitation/addPeople"),
      children: [],
    },
    {
      path: "/choose",
      name: "choose",
      component: () => import("views/invitation/choose"),
      children: [],
    },
    {
      path: "/addEmail",
      name: "addEmail",
      component: () => import("views/invitation/addEmail"),
      children: [],
    },
    {
      path: "/individual",
      name: "individual",
      component: () => import("views/report/individual/index"),
      children: [],
    },
    {
      path: "/commentProcess",
      name: "commentProcess",
      component: (resolve) =>
        require(["@/src/views/comment/process.vue"], resolve),
    },
    {
      path: "/department",
      name: "department",
      component: (resolve) =>
        require(["@/src/views/report/department/index.vue"], resolve),
    },
    {
      path: "/enterprise",
      name: "enterprise",
      component: (resolve) =>
        require(["@/src/views/report/enterprise/index.vue"], resolve),
    },
    {
      path: "/board",
      name: "board",
      component: (resolve) => require(["@/src/views/report/board"], resolve),
    },
    {
      path: "/departmentBoard",
      name: "departmentBoard",
      component: (resolve) =>
        require(["@/src/views/report/board/dept"], resolve),
    },
  ],
});

router.afterEach((to, from, next) => {
  if (to.name != "login") {
    sessionStorage.currentUrlName = to.fullPath;
  }
  if (to.query.inviteCode) {
    store.commit("comment/setIsInivte", true);
  } else {
    store.commit("comment/setIsInivte", false);
  }
});

export default router;
