const state = {
  isInvalid: true,
};

const mutations = {
  setIsInvalid(state, data) {
    state.isInvalid = data;
  },
};

const actions = {
  async getReportReceived({ rootState, commit, state }, userId) {
    let res = await rootState.axios.get(rootState.api.reportReceived, {
      params: { userId },
    });
    return res;
  },
  async getReportAssess({ rootState, commit, state }, userId) {
    let res = await rootState.axios.get(rootState.api.reportAssess, {
      params: { userId },
    });
    return res;
  },
  async getReportDiagram({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(
      rootState.api.reportDiagram + "?userId=" + data.userId,
      data.arr
    );
    return res;
  },
  async getReportComments({ rootState, commit, state }, userId) {
    let res = await rootState.axios.get(rootState.api.reportComments, {
      params: { userId },
    });
    return res;
  },
  async indQesRank({ rootState, commit, state }, userId) {
    let res = await rootState.axios.get(rootState.api.indQesRank, {
      params: { userId },
    });
    return res;
  },
  async scoreDistribute({ rootState, commit, state }, userId) {
    let res = await rootState.axios.get(rootState.api.scoreDistribute, {
      params: { userId },
    });
    return res;
  },
  async indExport({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.indExport,
      {
        params: data,
      },
      { responseType: "blob" }
    );
    return res;
  },
  async getEvpList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.evpList, {
      params: data,
    });
    return res;
  },
  async evpAnalys({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.evpAnalys, data);
    return res;
  },
  async linemanagerAnalys({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.linemanagerAnalys, data);
    return res;
  },
  async hrAnalys({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.hrAnalys, data);
    return res;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  mutations,
};
