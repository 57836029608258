<template>
  <div>
    <div class="enterDe-state-title">
      <label>Ranking of score</label>

      <div class="enterDe-state-title-middle">
        <i class="el-icon-question qs5iclick"></i>

        <div class="businessUnit">
          <h3>5 Business Units:</h3>
          <ul>
            <li>JLR China</li>
            <li>IMSS</li>
            <li>Purchasing</li>
            <li>Product Engineering</li>
            <li>CJLR</li>
          </ul>
        </div>
      </div>

      <ul class="enterDe-state-title-control">
        <li
          v-for="(item, index) in roleList"
          :key="'roleFeed' + index"
          :class="{ selected: item.selected }"
          @click="roleChange_feed(item, index)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>

    <div class="enterDe-state-square-content">
      <section class="rankScore-rank">
        <ul>
          <li
            v-for="(item, index) in buRankList"
            :key="'buRank' + index"
            :class="{ selected: item.selected }"
          >
            <div
              class="rankScore-rank-num"
              :class="{
                rbc1: index == 0,
                rbc2: index == 1,
                rbc3: index == 2,
                rbc4: index >= 3,
              }"
            >
              {{ index + 1 }}
            </div>

            <div class="rankScore-rank-square">
              <div class="rankScore-rank-name">{{ item.deptName }}</div>
              <div class="rankScore-rank-score">{{ item.scoreAvg }}</div>
            </div>

            <div class="rankScore-rank-enAverage" v-if="item.selected">
              <div class="rankScore-rank-enAverage-line">
                <label></label>
                <em></em>
              </div>

              <div class="rankScore-rank-enAverage-score">
                <label>Enterprise<br />average score</label>
                <em>{{ enterPriceAvage }}</em>
              </div>
            </div>
          </li>
        </ul>
      </section>
    </div>

    <div class="enterDe-state-title enterDe-state-title-sp">
      <label>Question score</label>
    </div>

    <ul class="topBottom-colorLabel">
      <li>
        <em>&nbsp;</em>
        <p>Top 3</p>
      </li>
      <li>
        <em>&nbsp;</em>
        <p>Bottom 3</p>
      </li>
    </ul>

    <section class="enterDe-question-qesContent">
      <swiper
        :ref="'mySwiper_chartSwp'"
        @slideChangeTransitionStart="onSlideChange_star_start"
        v-show="!pdfStatus"
      >
        <swiper-slide
          v-for="(item, index) in questionList"
          :key="'chartSwpItem' + index"
          class="enterDe-swiper-slide-enterprise"
        >
          <section class="enterDe-question-qesWord">
            <label>{{ "Q" + (index + 1) }}</label>
            <p class="enWord">
              {{ item.stemEn }}
            </p>
            <p class="zhWord">
              {{ item.stem }}
            </p>
          </section>

          <section
            class="enterDe-question-qesChart"
            :id="'chartSwpItem' + index"
          ></section>
        </swiper-slide>
      </swiper>

      <section class="pdf-swiper-content" v-show="pdfStatus">
        <div
          class="enterDe-swiper-slide-enterprise enterDe-swiper-slide-enterprise-sp"
          v-for="(item, index) in questionList"
          :key="'chartSwpItemPdf' + index"
        >
          <section class="enterDe-question-qesWord">
            <label>{{ "Q" + (index + 1) }}</label>
            <p class="enWord">
              {{ item.stemEn }}
            </p>
            <p class="zhWord">
              {{ item.stem }}
            </p>
          </section>

          <section
            class="enterDe-question-qesChart"
            :id="'chartSwpItemPdf' + index"
          ></section>
        </div>
      </section>
    </section>

    <div class="swiper-pagination-qes" v-show="!pdfStatus">
      <span
        v-for="(item, index) in questionList"
        :key="'qesPageCir' + index"
        :class="{ active: index == activeIndex }"
        @click="paginationClick(index)"
      ></span>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: ["data1", "data2", "data3", "pdfStatus"],
  data() {
    return {
      enterPriceAvage: "",
      activeIndex: 0,
      questionList: [],
      roleList: [
        { name: "Business Unit", type: "bu", selected: true },
        { name: "EVP", type: "evp" },
      ],
      buRankList: [],
    };
  },
  methods: {
    onSlideChange_star_start() {
      this.activeIndex = this.$refs.mySwiper_chartSwp.swiper.activeIndex;
    },
    roleChange_feed(data, index) {
      this.roleList.forEach((val) => {
        this.$set(val, "selected", false);
      });

      this.$set(this.roleList[index], "selected", true);
      this.pageInit(data.type);
    },
    paginationClick(index) {
      this.activeIndex = index;
      this.$refs.mySwiper_chartSwp.swiper.slideTo(index, 1000, false);
    },
    pdfInit() {
      this.questionList.forEach((val, e) => {
        let params = [],
          paramsTag = [],
          lowAry = [],
          lowAryTag = [];

        val.rankQsDetails.forEach((val2, e2) => {
          let dataIn = {
            deptName: val2.deptName,
            value: e2 < 3 ? val2.scoreAvg : -val2.scoreAvg,
            label: { position: "top", formatter: "{b}({c})" },
            itemStyle: {
              color: e2 < 3 ? "#6BAB8F" : "#EFC96E",
              borderRadius: e2 < 3 ? [0, 22, 22, 0] : [22, 0, 0, 22],
            },
          };

          if (e2 < 3) {
            params.unshift(dataIn);
            paramsTag.unshift(val2.deptName);
          } else {
            lowAry.push(dataIn);
            lowAryTag.push(val2.deptName);
          }
        });

        params = lowAry.concat(params);
        paramsTag = lowAryTag.concat(paramsTag);

        let option = {
          grid: {
            top: 20,
            bottom: 10,
          },

          xAxis: {
            type: "value",
            axisLabel: { show: false },
            position: "top",
            splitLine: {
              show: false,
            },
          },
          yAxis: {
            type: "category",
            axisLine: { show: true },
            axisLabel: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            data: paramsTag,
          },
          series: [
            {
              type: "bar",
              barWidth: 20,
              stack: "Total",
              label: {
                show: true,
                fontSize: 10,
                lineHeight: 12,
              },
              data: params,
            },
          ],
        };

        val.chartDomPdf = document.getElementById("chartSwpItemPdf" + e);

        echarts.init(val.chartDomPdf).dispose();

        val.myChartPdf = echarts.init(val.chartDomPdf);

        val.myChartPdf.setOption(JSON.parse(JSON.stringify(option)));

        $(window).resize(function () {
          val.myChartPdf.resize();
        });
      });
    },
    pageInit(type) {
      this.questionList = [];

      if (type === "evp") {
        this.questionList = this.data2.rankQsEvp;
        this.buRankList = this.data1.rankEvp;
      } else {
        this.questionList = this.data2.rankQsBu;
        this.buRankList = this.data1.rankBu;
      }

      this.$forceUpdate();

      setTimeout(() => {
        let minNumAvg = 101;

        this.buRankList.forEach((val) => {
          this.$set(val, "selected", false);
          let miniNum =
            val.scoreAvg > this.enterPriceAvage
              ? ""
              : this.enterPriceAvage - val.scoreAvg;
          val.miNum = miniNum;

          if (miniNum && miniNum < minNumAvg) {
            minNumAvg = miniNum;
          }
        });

        this.buRankList.some((val) => {
          if (minNumAvg == val.miNum) {
            this.$set(val, "selected", true);
          }
        });

        this.questionList.forEach((val, e) => {
          let params = [],
            paramsTag = [],
            lowAry = [],
            lowAryTag = [];

          val.rankQsDetails.forEach((val2, e2) => {
            let dataIn = {
              deptName: val2.deptName,
              value: e2 < 3 ? val2.scoreAvg : -val2.scoreAvg,
              label: { position: "top", formatter: "{b}({c})" },
              itemStyle: {
                color: e2 < 3 ? "#6BAB8F" : "#EFC96E",
                borderRadius: e2 < 3 ? [0, 22, 22, 0] : [22, 0, 0, 22],
              },
            };

            if (e2 < 3) {
              params.unshift(dataIn);
              paramsTag.unshift(val2.deptName);
            } else {
              lowAry.push(dataIn);
              lowAryTag.push(val2.deptName);
            }
          });

          params = lowAry.concat(params);
          paramsTag = lowAryTag.concat(paramsTag);

          let option = {
            grid: {
              top: 20,
              bottom: 10,
            },

            xAxis: {
              type: "value",
              axisLabel: { show: false },
              position: "top",
              splitLine: {
                show: false,
              },
            },
            yAxis: {
              type: "category",
              axisLine: { show: true },
              axisLabel: { show: false },
              axisTick: { show: false },
              splitLine: { show: false },
              data: paramsTag,
            },
            series: [
              {
                type: "bar",
                barWidth: 20,
                stack: "Total",
                label: {
                  show: true,
                  fontSize: 10,
                  lineHeight: 12,
                },
                data: params,
              },
            ],
          };

          val.chartDom = document.getElementById("chartSwpItem" + e);

          echarts.init(val.chartDom).dispose();

          val.myChart = echarts.init(val.chartDom);

          val.myChart.setOption(JSON.parse(JSON.stringify(option)));

          $(window).resize(function () {
            val.myChart.resize();
          });
        });

        this.$forceUpdate();
      }, 100);
    },
  },
  mounted() {
    this.enterPriceAvage = this.data3.questionEnt.scoreAvg;
    this.pageInit();
  },
};
</script>
