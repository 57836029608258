<template>
  <div class="select-tag">
    <div class="lable-wrap">
      <span
        class="lable-item f14 hover"
        :class="item.check ? 'active' : ''"
        @click="onSelect(item, i)"
        v-for="(item, i) in list"
        :key="i"
      >
        {{ item.name }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "selectTag",
  data() {
    return {
      texts: [],
      list: [],
    };
  },
  props: {
    datas: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: Boolean,
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    tags: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    isDisabled() {
      return this.disabled;
    },
  },
  watch: {
    datas(val) {
      let arrs = [];
      val.forEach((e) => {
        let item = { name: e, check: false };
        arrs.push(item);
      });
      this.list = arrs;
    },
    tags(val) {
      if (!val.length) {
        this.list.map((e) => {
          return (e.check = false);
        });
      }
    },
  },
  mounted() {
    setTimeout(() => {
      let arrs = [],
        tagsLs = [];
      if (this.tags) {
        tagsLs = this.tags;
      }
      this.datas.forEach((e) => {
        let item;
        item = { name: e, check: false };
        tagsLs.forEach((f) => {
          if (f == e) {
            item = { name: e, check: true };
          } else {
            item = { name: e, check: false };
          }
        });
        arrs.push(item);
      });
      this.list = arrs;
    }, 10);
  },
  methods: {
    onSelect(val, index) {
      if (this.isDisabled) {
        return false;
      }
      // this.list.forEach((e) => {
      //   if (e.check) {
      //     e.check = false;
      //   }
      // });
      this.list[index].check = !this.list[index].check;

      //if (val.check) {
      //  this.message
      //    ? (this.message += "，" + val.name)
      //    : (this.message += val.name);
      //}
      // else{
      //   this.texts.splice(this.texts.indexOf(val.name),1);
      // }

      let tags = [];
      this.list.forEach((e) => {
        if (e.check) {
          tags.push(e.name);
        }
      });
      this.$emit("update:tags", tags);
    },
  },
};
</script>
<style lang="scss">
@import "../styles/variable";
.select-tag {
  .txts {
    background-color: rgb(244, 244, 244);
    border-radius: toMRem(6);
  }
  .lable-wrap {
    display: flex;
    flex-wrap: wrap;
    .lable-item {
      margin-top: toMRem(5);
      background-color: rgba($color: #c9cece, $alpha: 0.2);
      color: #c9cece;
      &.active {
        background: rgba($color: #3e886d, $alpha: 0.2);
        color: #3e886d;
      }
    }
  }

  .van-field__word-limit {
    color: #222;
    opacity: 0.3;
  }
}
</style>
