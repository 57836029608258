<template>
  <div class="qesRank-comp" v-show="compStatus">
    <div class="qesRank-comp-title">Question ranking</div>

    <section class="qesRank-comp-tophand">
      <img src="@/src/assets/comment/tophand.png" alt="" />

      <div class="qesRank-comp-tophand-content">
        <label>Top 3</label>
        <p>Over enterprise average score</p>
      </div>
    </section>

    <section class="qesRank-comp-chart" id="qesRank_comp_chart"></section>

    <section class="qesRank-comp-bottomhand">
      <div class="qesRank-comp-bottomhand-content">
        <img src="@/src/assets/comment/bottomhand.png" alt="" />
        <label>Bottom 3</label>
      </div>

      <p>Below enterprise average score</p>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import * as echarts from "echarts";
export default {
  data() {
    return {
      userId: this.$route.query.userId,
      compStatus: true,
      option: {
        tooltip: {
          show: true,
          confine: true,
          triggerOn: "click",
          formatter: (val) => {
            return val.data.valueEn + "<br />" + val.data.valueZn;
          },
        },
        grid: {
          containLabel: false,
        },
        xAxis: {
          type: "category",
          data: [],
          axisLabel: {
            show: false,
          },
          axisTick: { show: false },
          splitLine: { show: false },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisLabel: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
        },
        series: [
          {
            type: "bar",
            barWidth: 16,
            label: {
              show: true,
              formatter: "{b}\n({c})",
              fontSize: 12,
              lineHeight: 18,
              position: "top",
            },
            data: [],
          },
        ],
      },
    };
  },
  mounted() {
    this.pageInit();
  },
  methods: {
    ...mapActions({
      indQesRank: "indv/indQesRank",
    }),
    async pageInit() {
      let params = {
        userId: this.userId,
      };
      let res = await this.indQesRank(params);

      // if (
      //   !res ||
      //   !res.data ||
      //   !res.data.data ||
      //   !res.data.data.highAvgQuestionList ||
      //   !res.data.data.lowAvgQuestionList
      // ) {
      //   this.compStatus = false;
      // }

      if (
        res &&
        res.data &&
        res.data.data &&
        res.data.data.highAvgQuestionList &&
        res.data.data.highAvgQuestionList.length > 0
      ) {
        res.data.data.highAvgQuestionList.forEach((val, e) => {
          this.option.xAxis.data.unshift(val.questionNum);

          let params = {
            value: val.deviationScore,
            valueZn: val.questionStem,
            valueEn: val.questionStemEN,
            itemStyle: {
              color: "#6BAB8F",
              borderRadius: 32,
            },
          };

          this.option.series[0].data.unshift(params);
        });
      }
      if (
        res &&
        res.data &&
        res.data.data &&
        res.data.data.lowAvgQuestionList &&
        res.data.data.lowAvgQuestionList.length > 0
      ) {
        let lsAry1 = [],
          lsAry2 = [];
        res.data.data.lowAvgQuestionList.forEach((val, e) => {
          lsAry1.unshift(val.questionNum);

          let params = {
            value: -val.deviationScore,
            valueZn: val.questionStem,
            valueEn: val.questionStemEN,
            itemStyle: {
              color: "#F8D37A",
              borderRadius: 32,
            },
          };

          lsAry2.unshift(params);
        });

        this.option.xAxis.data = this.option.xAxis.data.concat(lsAry1);
        this.option.series[0].data = this.option.series[0].data.concat(lsAry2);
      }

      let chartDom = document.getElementById("qesRank_comp_chart");
      this.myChart = echarts.init(chartDom);
      this.myChart.setOption(this.option);

      window.addEventListener("resize", () => {
        this.myChart.setOption(this.option, true);
        this.myChart.resize();
      });
    },
  },
  created() {},
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

@media screen and (max-width: 760px) {
  .qesRank-comp {
    width: 100%;
    padding: toMRem(30) 0 0 0;
  }
  .qesRank-comp-title {
    width: 100%;
    font-size: toMRem(15);
  }
  .qesRank-comp-tophand {
    display: flex;
    width: 100%;
    margin: toMRem(18) 0 0 0;
    align-items: center;

    img {
      width: toMRem(22);
      height: auto;
      flex-shrink: 0;
    }
  }
  .qesRank-comp-tophand-content {
    flex-basis: 100%;
    padding-left: toMRem(10);

    label {
      display: block;
      width: 100%;
      opacity: 0.8;
    }
    p {
      opacity: 0.6;
      line-height: 1.4;
      font-size: toMRem(12);
      padding: toMRem(6) 0 0 0;
    }
  }

  .qesRank-comp-bottomhand {
    width: 100%;

    .qesRank-comp-bottomhand-content {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;

      img {
        width: toMRem(22);
        height: auto;
        flex-shrink: 0;
      }
      label {
        display: inline-block;
        opacity: 0.8;
        padding-left: toMRem(10);
      }
    }
    p {
      width: 100%;
      opacity: 0.6;
      line-height: 1.4;
      font-size: toMRem(12);
      padding: toMRem(6) 0 0 0;
      text-align: right;
    }
  }
  .qesRank-comp-chart {
    width: 100%;
    height: toMRem(200);
  }
}

@media screen and (min-width: 761px) {
  .qesRank-comp {
    width: 100%;
    padding: toRem(30) 0 0 0;
  }
  .qesRank-comp-title {
    width: 100%;
    font-size: toRem(15);
  }
  .qesRank-comp-tophand {
    display: flex;
    width: 100%;
    margin: toRem(18) 0 0 0;
    align-items: center;

    img {
      width: toRem(22);
      height: auto;
      flex-shrink: 0;
    }
  }
  .qesRank-comp-tophand-content {
    flex-basis: 100%;
    padding-left: toRem(10);

    label {
      display: block;
      width: 100%;
      opacity: 0.8;
    }
    p {
      opacity: 0.6;
      line-height: 1.4;
      font-size: toRem(12);
      padding: toRem(6) 0 0 0;
    }
  }

  .qesRank-comp-bottomhand {
    width: 100%;

    .qesRank-comp-bottomhand-content {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;

      img {
        width: toRem(22);
        height: auto;
        flex-shrink: 0;
      }
      label {
        display: inline-block;
        opacity: 0.8;
        padding-left: toRem(10);
      }
    }
    p {
      width: 100%;
      opacity: 0.6;
      line-height: 1.4;
      font-size: toRem(12);
      padding: toRem(6) 0 0 0;
      text-align: right;
    }
  }
  .qesRank-comp-chart {
    width: 100%;
    height: toRem(200);
  }
}
</style>
