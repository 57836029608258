<template>
  <div class="login">
    <section class="login-content">
      <h1>360 Feedback</h1>

      <section class="login-line">
        <div class="login-line-label">Email</div>

        <div class="login-line-label-sp">
          For IMSS employees, please log in with your IMSS email address
        </div>

        <div class="login-line-content">
          <van-field
            v-model="email.value"
            type="text"
            maxlength="50"
            placeholder="Please type"
            :class="{ errorText: !email.status }"
          />

          <div class="error-word" v-if="!email.status">
            {{ email.errorWord }}
          </div>
        </div>
      </section>

      <section class="login-line">
        <div class="login-line-label">Verification code</div>

        <div class="login-line-content">
          <van-field
            v-model="checkWord.value"
            type="text"
            maxlength="10"
            placeholder="Please type"
            :class="{ errorText: !checkWord.status }"
          />

          <button
            class="login-line-controlBtn"
            @click="getCodeFn"
            :disabled="!getCode_status"
          >
            {{ getCode_word }}
          </button>

          <div class="error-word" v-if="!checkWord.status">
            {{ checkWord.errorWord }}
          </div>
        </div>
      </section>

      <section class="login-buttonLine">
        <button
          class="login-buttonLine-btn"
          @click="login_submit"
          :disabled="!loginBtnStatus"
        >
          Login
        </button>
      </section>

      <section class="login-button-spWord" v-if="wxStatus">
        <p>
          It is recommended to open with a browser to avoid repeated logins.
        </p>
        <p>建议使用浏览器打开，以避免重复登录。</p>
      </section>
    </section>

    <van-dialog
      v-model="getCode_show"
      :showConfirmButton="false"
      className="login-getCode-dialog"
    >
      <div class="login-getCode-dialog-cotent">
        <em>Please insert code</em>

        <!--img code-->
        <section class="login-getCode-imgContent">
          <div class="sgi-img" @click="refreshCode">
            <img :src="randomImg" alt="" />
          </div>

          <div class="sgi-control" @click="refreshCode"></div>
        </section>

        <section class="login-line">
          <div class="login-line-content">
            <van-field
              v-model="getImgCode.value"
              type="text"
              maxlength="10"
              placeholder="Please type"
              :class="{ errorText: !getImgCode.status }"
            />
          </div>
        </section>
        <!--img code end-->

        <section class="login-getCode-buttonLine">
          <span class="login-getCode-btn" @click="cancelCode">Cancel</span>

          <button
            class="login-getCode-btn primary"
            @click="subToEmail"
            :disabled="!getImgCodeStatus"
          >
            Submit
          </button>
        </section>
      </div>
    </van-dialog>

    <van-dialog
      v-model="sendSuccessDialog"
      :showConfirmButton="false"
      className="login-getCode-dialog short"
    >
      <div class="login-getCode-dialog-cotent">
        <em>Successfully sent</em>
        <em class="small">Please check your E-mail.</em>
        <section class="login-getCode-buttonLine">
          <button
            class="login-getCode-btn long"
            @click="sendSuccessDialog = false"
          >
            I got it!
          </button>
        </section>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { v4 as uuidv4 } from "uuid";
export default {
  name: "login",
  data() {
    return {
      wxStatus: "",
      getCode_word: "Get code",
      getCode_status: true,
      loginBtnStatus: true,
      getImgCodeStatus: true,
      getCode_show: false,
      email: {
        value: "",
        status: true,
        errorWord: "",
      },
      checkWord: {
        value: "",
        status: true,
        errorWord: "",
      },
      getImgCode: {
        value: "",
        status: true,
      },
      randomCode: "",
      randomCode_url:
        process.env.VUE_APP_BASE_PATH + "api/user/sys/captcha.jpg",
      randomImg: "",
      sendSuccessDialog: false,
    };
  },
  created() {
    if (
      window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
      "micromessenger"
    ) {
      this.wxStatus = true;
    } else {
      this.wxStatus = false;
    }
  },
  methods: {
    ...mapActions({
      getEmailCode: "login/getEmailCode",
      login: "login/login",
    }),
    async login_submit() {
      let subStatus = true;

      if (!this.email.value) {
        this.email.status = false;
        subStatus = false;
        this.email.errorWord = "Please insert correct Email";
      } else {
        if (!this.checkEmail(this.email.value)) {
          this.email.status = false;
          subStatus = false;
          this.email.errorWord = "Wrong Email format";
        } else {
          this.email.status = true;
        }
      }

      if (!this.checkWord.value) {
        this.checkWord.status = false;
        subStatus = false;
        this.checkWord.errorWord = "Please insert verification code";
      } else {
        this.checkWord.status = true;
      }

      if (subStatus) {
        this.loginBtnStatus = false;
        let loginParams = {
          code: this.checkWord.value,
          email: this.email.value,
        };

        let res = await this.login(loginParams);

        if (res.data.code == 0) {
          this.$toast("Successfully logged in");
          localStorage.token = res.data.result.token;

          setTimeout(() => {
            if (sessionStorage.currentUrlName) {
              this.$router.push({ path: sessionStorage.currentUrlName });
            } else {
              this.$router.push({ name: "invitaHome" });
            }
          }, 1000);
        }

        this.loginBtnStatus = true;
      }
    },
    getCodeFn() {
      if (!this.email.value) {
        this.$toast("Please insert correct Email");
        return false;
      }

      if (!this.checkEmail(this.email.value)) {
        this.$toast("Wrong Email format");
        return false;
      }

      this.randomCode = uuidv4();

      this.randomImg = this.randomCode_url + "?uuid=" + this.randomCode;

      this.getCode_show = true;

      /////////////////////////////

      this.email.status = true;
      this.checkWord.status = true;
    },
    refreshCode() {
      this.randomImg = "";

      this.randomCode = uuidv4();

      this.randomImg = this.randomCode_url + "?uuid=" + this.randomCode;
    },
    timeDf() {
      let num = 60,
        numSt = setInterval(() => {
          if (num > 0) {
            this.getCode_word = num + "s";
            num -= 1;
          } else {
            clearInterval(numSt);
            this.getCode_word = "Get code";
            this.getCode_status = true;
          }
        }, 1000);
    },
    checkEmail(val) {
      let checkZz = /^.+@.+\..{2,4}$/;

      return checkZz.test(val);
    },
    async subToEmail() {
      this.getImgCodeStatus = false;

      if (!this.getImgCode.value) {
        this.$toast("Wrong verification");

        this.getImgCodeStatus = true;

        return false;
      }

      let params = {
        captcha: this.getImgCode.value,
        email: this.email.value,
        uuid: this.randomCode,
      };

      let res = await this.getEmailCode(params);

      if (res.data.code == 0) {
        this.getCode_status = false;

        this.getCode_show = false;
        this.sendSuccessDialog = true;
        this.getImgCode.value = "";

        this.timeDf();
      } else {
        this.refreshCode();
      }

      this.getImgCodeStatus = true;
    },
    cancelCode() {
      this.getCode_show = false;
      this.getImgCode.value = "";
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/login.scss";
</style>
