<template>
  <div>
    <div class="enterDe-state-title">
      <label>Department distribution</label>
    </div>

    <div class="distribution-content">
      <section class="distribution-empAvgScore-title">
        Employee average score
      </section>

      <section
        class="distribution-empAvgScore"
        id="distribution_empAvgScore"
      ></section>

      <section class="distribution-highlow">
        <!--top-->
        <div class="distribution-highlow-content">
          <div class="distribution-highlow-title">
            <div class="distribution-highlow-img">
              <img src="@/src/assets/comment/handdown.png" />
            </div>
            <label>The highest variance</label>
          </div>

          <p>Top 3 question</p>

          <ul>
            <li
              v-for="(item, index) in highQuestionInfos"
              :key="'top3var' + index"
            >
              <label>{{ item.questionNum }}</label>
              <em>{{ item.deviationScore }}</em>
            </li>
          </ul>
        </div>

        <!--top end-->

        <!--bottom-->

        <div
          class="distribution-highlow-content distribution-highlow-content-bottom"
        >
          <div class="distribution-highlow-title">
            <div class="distribution-highlow-img">
              <img src="@/src/assets/comment/handup.png" />
            </div>
            <label>The lowest variance</label>

            <i
              class="el-icon-question questionWordList-icon"
              @click="emitQesList"
            ></i>
          </div>

          <p>Bottom 3 question</p>

          <ul>
            <li
              v-for="(item, index) in lowQuestionInfos"
              :key="'bottom3var' + index"
            >
              <label>{{ item.questionNum }}</label>
              <em>{{ item.deviationScore }}</em>
            </li>
          </ul>
        </div>

        <!--bottom end-->
      </section>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import * as echarts from "echarts";
export default {
  data() {
    return {
      dictId: this.$route.query.dictId,
      highQuestionInfos: [],
      lowQuestionInfos: [],
    };
  },
  methods: {
    ...mapActions({
      departHistogram: "depart/departHistogram",
    }),
    emitQesList() {
      this.$emit("emitQesList");
    },
  },
  async mounted() {
    let params = {
      dictId: this.dictId,
    };

    let res = await this.departHistogram(params);

    this.highQuestionInfos = res.data.data.highQuestionInfos;
    this.lowQuestionInfos = res.data.data.lowQuestionInfos;

    let tagDataList = [],
      personDataList = [];

    function areaPt(value) {
      let indexNum = "",
        valueP = parseFloat(value);

      if (valueP < 50) {
        indexNum = 0;
      }
      if (valueP >= 50 && valueP < 60) {
        indexNum = 1;
      }
      if (valueP >= 60 && valueP < 70) {
        indexNum = 2;
      }
      if (valueP >= 70 && valueP < 80) {
        indexNum = 3;
      }
      if (valueP >= 80 && valueP < 90) {
        indexNum = 4;
      }
      if (valueP >= 90 && valueP <= 100) {
        indexNum = 5;
      }

      return indexNum;
    }

    let enterPriceAvgIndex = areaPt(res.data.data.enterpriseAvgScore),
      depAvgUbdex = areaPt(res.data.data.depAvgScore);

    let enterPriceAvgData = {
      type: "bar",
      barWidth: 1,
      barGap: 2,
      itemStyle: {
        color: "#fff",
        borderWidth: 1,
        borderColor: "#629FD7",
        borderType: "dashed",
      },

      data: [
        { value: 0, itemStyle: { opacity: 0 } },
        { value: 0, itemStyle: { opacity: 0 } },
        { value: 0, itemStyle: { opacity: 0 } },
        { value: 0, itemStyle: { opacity: 0 } },
        { value: 0, itemStyle: { opacity: 0 } },
        { value: 0, itemStyle: { opacity: 0 } },
      ],
      label: {
        color: "#5184B8",
        show: true,
        formatter: (val) => {
          return val.value == 0
            ? ""
            : "Enterprise\naverage score\n" + val.data.valueNew;
        },

        fontSize: 10,
        lineHeight: 12,
      },
    };

    let depAvgData = {
      type: "bar",
      barWidth: 1,
      barGap: 2,
      itemStyle: {
        color: "#fff",
        borderWidth: 1,
        borderColor: "#629FD7",
        borderType: "dashed",
      },

      data: [
        { value: 0, itemStyle: { opacity: 0 } },
        { value: 0, itemStyle: { opacity: 0 } },
        { value: 0, itemStyle: { opacity: 0 } },
        { value: 0, itemStyle: { opacity: 0 } },
        { value: 0, itemStyle: { opacity: 0 } },
        { value: 0, itemStyle: { opacity: 0 } },
      ],
      label: {
        color: "#5184B8",
        show: true,
        formatter: (val) => {
          return val.value == 0
            ? ""
            : "Department\naverage score\n" + val.data.valueNew;
        },

        fontSize: 10,
        lineHeight: 12,
      },
    };

    let maxValueLs = 0;

    let dataIn = {
      type: "bar",
      barMaxWidth: 16,
      data: [],
      label: {
        show: true,
        formatter: (val) => {
          return val.value == 0 ? "" : val.value;
        },
        fontSize: 10,
        lineHeight: 12,
      },
    };

    res.data.data.rptHistograms.forEach((val, e) => {
      dataIn.data[e] = { value: val.userTotal, label: { position: "top" } };

      if (parseInt(val.userTotal) >= maxValueLs) {
        maxValueLs = parseInt(val.userTotal);
      }

      tagDataList.push({
        value: val.sectionName,
      });
    });

    personDataList.push(dataIn);

    enterPriceAvgData.data[enterPriceAvgIndex] = {
      valueNew: res.data.data.enterpriseAvgScore,
      value: maxValueLs + 38,
      label: {
        position: [enterPriceAvgIndex <= depAvgUbdex ? -10 : 10, -20],
        align: enterPriceAvgIndex <= depAvgUbdex ? "right" : "left",
      },
    };

    depAvgData.data[depAvgUbdex] = {
      valueNew: res.data.data.depAvgScore,
      value: maxValueLs + 38,
      label: {
        position: [enterPriceAvgIndex <= depAvgUbdex ? 10 : -10, -20],
        align: enterPriceAvgIndex <= depAvgUbdex ? "left" : "right",
      },
    };

    personDataList.unshift(enterPriceAvgData);
    personDataList.unshift(depAvgData);

    let option = {
      grid: {
        top: 40,
        bottom: 40,
      },
      xAxis: [
        {
          axisLabel: {
            rotate: 40,
          },
          type: "category",
          data: tagDataList,
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "Persons",
          nameLocation: "end",
          nameTextStyle: { padding: [0, 0, 10, -20] },
        },
      ],
      series: personDataList,
    };

    let chartDom = document.getElementById("distribution_empAvgScore");
    let myChart = echarts.init(chartDom);

    myChart.setOption(option);

    $(window).resize(function () {
      myChart.resize();
    });
  },
};
</script>

<style></style>
