<template>
  <div class="add-people">
    <div class="titles f24 fjsa">
      <p class="text">
        <strong>Jacky's feedback invitation </strong>
        <img class="epic" src="../../assets/edit.png" alt="" />
      </p>
      <img class="tic" src="../../assets/rc.png" alt="" />
    </div>
    <div class="invit-card pt15 pb20">
      <p class="f16 fb tin1 pt10 fac">
        <img class="epic" src="../../assets/users.png" alt="" /> Nominated
        people
      </p>
      <selectTag
        class="p20"
        :disabled="readonly"
        :tags.sync="starLabels"
        :datas.sync="starLabelList"
      ></selectTag>

      <div class="user-avatar">
        <span
          class="avatar"
          v-lazy:background-image="item ? item : defaultAvatar"
          style="
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 0px 0px;
          "
          v-for="(item, index) in imgs"
          :key="index"
        ></span>
        <span class="avatar f18 num">8</span>
      </div>
      <div class="select-wrap">
        <el-select class="sel" v-model="value" placeholder="请选择">
          <el-option
            v-for="(item, index) in options"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <button class="invit-btn f14" @click="linkTo('choose')">Add to</button>
      <div class="line"></div>
      <p class="f16 fb tin1 pt10 fac mb24">
        <img class="epic" src="../../assets/date.png" alt="" /> Duration
      </p>
      <p class="tin2 f14">2022-03-02 — 2022-03-08</p>
    </div>
    <!-- <button class="subt f14 cf">Submit</button> -->
  </div>
</template>
<script>
import selectTag from "@/src/components/selectTag.vue";
export default {
  name: "addPeople",
  components: {
    selectTag,
  },
  data() {
    return {
      readonly: true,
      starLabels: [
        "Senior",
        "Peer",
        "Cross-functional",
        "Reports to you",
        "Others",
      ],
      starLabelList: [
        "Senior",
        "Peer",
        "Cross-functional",
        "Reports to you",
        "Others",
      ],
      defaultAvatar: "./images/null-img.png",
      imgs: [
        "./images/user1.png",
        "./images/user2.png",
        "./images/user1.png",
        "./images/user2.png",
        "./images/user1.png",
        "./images/user2.png",
      ],
      options: [
        "Siwon0212@gmail.com",
        "Ada.Lau12@gmail.com",
        "Zoe.Mi@gmail.com",
        "ciwon0212@gmail.com",
      ],
      value: "Siwon0212@gmail.com",
    };
  },
  mounted() {
    document.title = "Feedback Invitation";
  },
  methods: {
    linkTo(val) {
      this.$router.push(val);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variable";
.add-people {
  .titles {
    padding: toMRem(25) toMRem(30);
    .text {
      line-height: 1.4;
      .epic {
        width: toMRem(16);
        height: toMRem(14);
      }
    }
    .tic {
      width: toMRem(86);
      height: toMRem(53);
    }
  }
  .invit-card {
    width: toMRem(338);
    margin: 0 auto;
    background: #ffffff;
    box-shadow: 0px 4px 9px rgba(209, 209, 209, 0.25);
    border-radius: toMRem(16);
    .epic {
      width: toMRem(24);
      height: toMRem(18);
      margin-left: toMRem(24);
    }
    .user-avatar {
      margin: 0 toMRem(20) toMRem(26);
      .avatar {
        box-shadow: 0 0 0 1px #fff;
        margin-right: toMRem(-15) !important;
        width: toMRem(32);
        height: toMRem(32);
        display: inline-block;
        background-color: #ffff;
        position: relative;
        text-align: center;
        color: #fff;
        // font-weight: 600;
        vertical-align: bottom;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-radius: 50%;
      }
      .num {
        background-color: #f7c85b;
        line-height: toMRem(32);
      }
    }
    .select-wrap {
      margin-left: toMRem(20);
      .sel {
        width: toMRem(240);
      }
    }
    .invit-btn {
      display: block;
      margin: toMRem(30) auto toMRem(40);
      width: toMRem(100);
      height: toMRem(30);
      line-height: toMRem(30);
      background: #ffffff;
      color: #3e886d;
      border: 1px solid #3e886d;
      box-sizing: border-box;
      border-radius: 81px;
    }
    .line {
      margin: 0 auto;
      width: toMRem(298);
      height: toMRem(1);
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .subt {
    position: fixed;
    left: toMRem(118);
    bottom: toMRem(124);
    width: toMRem(138);
    height: toMRem(42);
    line-height: toMRem(30);
    background: #3e886d;
    border-radius: toMRem(81);
  }
}
</style>
<style>
.el-input__inner {
  color: #000;
  border: 0;
}
.el-select .el-input .el-select__caret {
  color: #000;
}
.el-select-dropdown__item.selected {
  color: #000;
  font-weight: normal;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #fff;
}
.el-select-dropdown__item {
  color: #000;
}
</style>
