<template>
  <div
    class="modal-box-warpper"
    v-if="visible"
    @click="closeModal"
    @touchmove.prevent
  >
    <div class="modal-box" @click.stop>
      <slot></slot>
    </div>
    <div class="close-icon">x</div>
  </div>
</template>
<script>
export default {
  name: "customModal",
  props: {
    visible: Boolean,
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("onClose");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../../styles/variable";
@media screen and (max-width: 760px) {
  .modal-box-warpper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: toMRem(56);
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(6px);
    z-index: 1;
    left: 0;
    .modal-box {
      position: absolute;
      top: toMRem(4);
      left: toMRem(18.5);
      width: toMRem(338);
      // height: toMRem(366);
      padding: toMRem(24) toMRem(20);
      background: #ffffff;
      box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.3);
      border-radius: 16px;
    }
    .close-icon {
      position: absolute;
      top: toMRem(8);
      right: toMRem(24);
      font-size: toMRem(20);
      width: toMRem(30);
      height: toMRem(30);
      line-height: toMRem(30);
      text-align: center;
      cursor: pointer;
    }
  }
}
@media screen and (min-width: 760px) {
  .modal-box-warpper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: toPad(56);
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(6px);
    z-index: 1;
    .modal-box {
      position: absolute;
      width: 96vw;
      left: 50%;
      transform: translateX(-50%);
      top: toPad(30);
      padding: toPad(20);
      background: #ffffff;
      box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.3);
      border-radius: 16px;
    }
    .close-icon {
      position: absolute;
      top: toPad(30);
      right: toPad(24);
      font-size: toPad(20);
      width: toPad(30);
      height: toPad(30);
      line-height: toPad(30);
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
