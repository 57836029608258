<template>
  <div class="invitation-home" :style="!hideMenu ? 'padding-top:0;' : ''">
    <commentTopControl v-if="hideMenu" :title="headerTitle" :leftSlot="true">
      <template slot="left">
        <div class="add hover" @click="showLogout = true">
          <img src="../../assets/login/login.png" alt="" />
        </div>
      </template>
      <template slot="right">
        <div class="hot-line f12 cg hover" @click="switchHot">
          <!-- Hotline -->
          <img src="../../assets/hot-line.png" alt="" />
        </div>
      </template>
      <!-- <img slot="right" :src="headImg" /> -->
    </commentTopControl>
    <div class="invit-card">
      <div class="card-wrap">
        <p class="f18 fb titles fjsb">
          <span>My Feedback</span>
          <span class="f14 cg hover" @click="switchOn">More detail</span>
        </p>
        <img class="pic" src="../../assets/feedback.png" alt="" />
        <p class="f20 fb tac lh15">Step 1 Self-assessment</p>
        <button
          class="invit-btn f14 cf hover"
          :class="
            datas.isSelfFinish == 1 || isAssessOver == true ? 'disable' : ''
          "
          :disabled="datas.isSelfFinish == 1 || isAssessOver == true"
          @click="linkTo('commentProcess')"
        >
          {{ datas.isSelfFinish == 1 ? "Completed" : "Start evaluation" }}
        </button>
      </div>
      <div class="card-wrap">
        <p class="f20 fb tac lh15">Step 2 Invite other people</p>
        <!-- <button
        :disabled="datas.isInvited == 1"
        class="invit-btn f16 invit fb hover"
        :class="datas.isInvited ? 'disabled' : ''"
        @click="linkTo(`choose?isInvited=${datas.isInvited}`)"
      >
        Invite
      </button> -->

        <button
          :disabled="datas.isSurveyActive == 0"
          class="invit-btn f16 invit fb hover"
          :class="datas.isSurveyActive == 0 ? 'disableds' : ''"
          @click="linkTo(`choose?isInvited=${datas.isInvited}&id=0`)"
        >
          Invite
        </button>
        <p class="f10 tac cr mb8" v-if="datas.isInviteStatusDone == 0">
          You haven't met the invitation request yet,<br />
          please continue to invite.
        </p>
        <div class="line"></div>
        <div class="detail-wrap">
          <p class="f16 fb pb10">I invited</p>
          <div class="user-avatar mb8">
            <span
              class="avatar"
              v-lazy:background-image="item ? item : defaultAvatar"
              style="
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 0px 0px;
              "
              v-for="(item, index) in imgs"
              :key="index"
            ></span>
            <span class="avatar f18 num">{{ datas.invites.length }}</span>
            <span class="pro f14"
              >Progress {{ datas.surveyResults.length }} /
              {{ datas.invites.length }}</span
            >
            <img
              class="pro-tips hover"
              @click="switchPro"
              src="../../assets/hot-line.png"
              alt=""
            />
            <span
              v-if="datas.invites.length"
              class="show-more cg f12 tin1 hover"
              @click="isFoldInvites = !isFoldInvites"
              >{{ isFoldInvites ? "Close+" : "More+" }}</span
            >
          </div>
          <!-- <p class="invites-tips"> <img src="../../assets/check-black" alt="">
          <img src="../../assets/check-black" alt="">
          Completed to invite at least 8 people base on rules
          </p> -->
          <div
            class="tips-item fac f12"
            :class="datas.isInviteStatusDone == 0 ? 'c04' : ''"
          >
            <div class="check">
              <img
                src="../../assets/check-black.png"
                v-if="datas.isInviteStatusDone == 1"
                alt=""
              />
              <img src="../../assets/check-off.png" v-else alt="" />
            </div>
            Completed to invite at least 8 people base on rules
          </div>
          <div class="invites-wrap" :class="isFoldInvites ? 'active' : ''">
            <div class="line"></div>
            <p
              class="f12 invites-item"
              v-for="(item, i) in datas.invites"
              :key="i"
            >
              · {{ item.nameEN }}
            </p>
          </div>

          <p class="f16 fb">My feedback status</p>
          <div class="tips">
            <div
              class="tips-item fac f12"
              :class="!datas.isLineManagerDone ? 'c04' : ''"
            >
              <div class="check">
                <img
                  src="../../assets/check-on.png"
                  v-if="datas.isLineManagerDone"
                  alt=""
                />
                <img src="../../assets/check-off.png" v-else alt="" />
              </div>
              Line Manager
            </div>
            <div
              class="tips-item fac f12"
              :class="!datas.isCrossFunctionalDone ? 'c04' : ''"
            >
              <div class="check">
                <img
                  src="../../assets/check-on.png"
                  v-if="datas.isCrossFunctionalDone"
                  alt=""
                />
                <img src="../../assets/check-off.png" v-else alt="" />
              </div>
              At least 2 cross-functional colleague (Within enterprise)
            </div>
            <div
              class="tips-item fac f12"
              :class="!datas.isSeniorDone ? 'c04' : ''"
            >
              <div class="check">
                <img
                  src="../../assets/check-on.png"
                  v-if="datas.isSeniorDone"
                  alt=""
                />
                <img src="../../assets/check-off.png" v-else alt="" />
              </div>
              At least 2 colleague who is more senior (higher grade)
            </div>
            <div
              class="tips-item fac f12"
              :class="!datas.isPeerDone ? 'c04' : ''"
            >
              <div class="check">
                <img
                  src="../../assets/check-on.png"
                  v-if="datas.isPeerDone"
                  alt=""
                />
                <img src="../../assets/check-off.png" v-else alt="" />
              </div>
              At least 2 colleague who is a peer (same grade)
            </div>
            <div
              class="tips-item fac f12"
              :class="!datas.isReportsToYouDone ? 'c04' : ''"
            >
              <div class="check">
                <img
                  src="../../assets/check-on.png"
                  v-if="datas.isReportsToYouDone"
                  alt=""
                />
                <img src="../../assets/check-off.png" v-else alt="" />
              </div>
              At least 1 member of your team who reports to you (if applicable)
            </div>
            <div
              class="tips-item fac f12"
              :class="!datas.isSatisfyNum ? 'c04' : ''"
            >
              <div class="check">
                <img
                  src="../../assets/check-on.png"
                  v-if="datas.isSatisfyNum"
                  alt=""
                />
                <img src="../../assets/check-off.png" v-else alt="" />
              </div>
              At least 8 colleagues
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="other-card pb20">
      <p class="f18 fb">Feedback I gave</p>
      <p class="otitle fjsb mb16 pt15">
        <span class="names f14">Who invited me</span>
        <span class="f12"
          >（Progress {{ datas.surveyInviteNum }}/{{
            datas.surveyInvites.length
          }}）</span
        >
      </p>
      <div class="fold-list" :class="isFoldone ? 'active' : ''">
        <div v-if="datas.surveyInvites.length">
          <p
            class="o-item fjsb mb16"
            v-for="(item, index) in datas.surveyInvites"
            :key="index"
          >
            <span class="f12">· Feedback for {{ item.nameEn }}</span>
            <span class="f12 tac cg done" v-if="item.isAssess">Done</span>
            <button
              v-else
              class="rate tac cg f11 hover"
              :class="isAssessOver ? 'disable' : ''"
              :disabled="isAssessOver"
              @click="
                linkTo(
                  `commentProcess?userId=${item.assessee}&name=${item.nameEn}`
                )
              "
            >
              Score now
            </button>
          </p>
        </div>
        <!-- <p class="f20 empty" v-else>Here is empty.</p> -->
        <div v-else class="empty f12">
          <img src="../../assets/empty.png" alt="" />
          <p>List is empty.</p>
        </div>
      </div>

      <p
        class="cg f12 tin1 hover"
        v-if="datas.surveyInvites.length > 3"
        @click="toggleFoldone"
      >
        {{ isFoldone ? "Close-" : "More+" }}
      </p>
      <p class="otitle fjsb mb16 pt15">
        <span class="names f14 bgreen">Who I chose</span>
        <span class="f12"
          >（Progress {{ datas.activeAssessNum }}<span class="f14">/</span
          >{{ datas.activeAssesss.length }}）</span
        >
      </p>
      <div class="fold-list" :class="isFoldtwo ? 'active' : ''">
        <div v-if="datas.activeAssesss.length">
          <p
            class="o-item fjsb mb16"
            v-for="(item, index) in datas.activeAssesss"
            :key="index"
          >
            <span class="f12">· Feedback for {{ item.nameEn }}</span>
            <span class="f12 tac cg done" v-if="item.isAssess">Done</span>
            <button
              class="rate tac cg f11 hover"
              :class="isAssessOver ? 'disable' : ''"
              :disabled="isAssessOver"
              v-else
              @click="
                linkTo(
                  `commentProcess?userId=${item.assessee}&name=${item.nameEn}`
                )
              "
            >
              Score now
            </button>
          </p>
        </div>
        <!-- <p class="f20 empty" v-else>Here is empty.</p> -->
        <div class="empty f12" v-else>
          <img src="../../assets/empty.png" alt="" />
          <p>List is empty.</p>
        </div>
      </div>
      <p
        class="cg f12 tin1 hover"
        v-if="datas.activeAssesss.length > 3"
        @click="toggleFoldtwo"
      >
        {{ isFoldtwo ? "Close-" : "More+" }}
      </p>
      <div v-if="!isAssessOver" class="add-to fjc f14 fb mb10 pt10">
        <img
          class="hover"
          src="../../assets/add-to.png"
          alt=""
          @click="linkTo('choose?id=1')"
        />
        <span class="hover" @click="linkTo('choose?id=1')">Initiative</span>
      </div>
    </div>
    <!-- <section class="home-holeBtn-content" v-if="isReportStart"> -->
    <section class="home-holeBtn-content" v-if="isReportStart">
      <div
        class="home-holeBtn-content-in"
        @click="$router.push({ name: 'individual' })"
      >
        <div class="home-holeBtn-content-img">
          <img src="@/src/assets/comment/indv_enter_icon.png" alt="" />
        </div>
        <em>Check Report</em>
      </div>

      <div
        class="home-holeBtn-content-in home-holeBtn-content-in2"
        @click="$router.push({ name: 'board' })"
      >
        <div class="home-holeBtn-content-img">
          <img src="@/src/assets/comment/person.png" alt="" />
        </div>
        <em>Individual<br />Board</em>
      </div>

      <div
        class="home-holeBtn-content-in home-holeBtn-content-in2"
        @click="$router.push({ name: 'departmentBoard' })"
      >
        <div class="home-holeBtn-content-img">
          <img src="@/src/assets/comment/table.png" alt="" />
        </div>
        <em>Department<br />Board</em>
      </div>
    </section>

    <div class="survey-card">
      <FeedbackSurvey />
    </div>
    <!-- 规则弹窗 -->
    <div class="rulers" v-if="showRule">
      <img
        class="close hover"
        @click="switchOn"
        src="../../assets/close.png"
        alt=""
      />
      <p class="f20 pb10 p20">
        <strong>360° Feedback</strong> – Questions & Answers
      </p>
      <p class="desc c10 f14">
        <strong>What is 360° Feedback?</strong><br />
        • 360? Feedback lets you receive confidential, anonymous feedback from
        the key people who work around you. Typically, it is completed by
        yourself, your line manager, colleagues, key customers, and direct
        reports (if applicable).<br />
        • At the completion of the process, everyone and their line manager will
        receive a feedback report to support development activity
        <br />
        <strong>Why are we doing 360° Feedback? </strong><br />• To give all
        employees a voice, & create the opportunity to give more regular
        feedback to each other<br />
        • Increase transparency<br />
        • Help us understand how others see us – “what” you do and “how” you do
        it<br />
        • Provide an input into current performance & career development<br />
        • To be inclusive & have some fun<br />
        <strong> Who can take part?</strong> <br />• All JLRC, Purchasing,
        Engineering & IMSS colleagues in China can request and receive
        feedback<br />
        • New starters after 1st January 2022 are optional<br />
        <strong>Who completes the 360° Feedback?</strong><br />
        • You will complete a self-assessment<br />• You can choose at least 8
        people to respond (from across China, global & external) These
        selections should include as a minimum: <br />- Your line Manager<br />
        - At least 2 cross-functional colleagues (includes internal & external
        customer)<br />
        - At least 2 colleagues who are more senior (higher grade)<br />
        - At least 2 colleagues who are peers (same grade)<br />
        - At least 1 member of your team who reports to you (if applicable)<br />
        You are also able to proactively provide feedback to any colleagues in
        the China organisation (even if you haven’t been invited). We actively
        encourage this – the more ratings complete, the more accurate the
        assessment.<br />
        We will have some fun ranking boards built within the system, so be
        proactive & enthusiastic!<br />
        <strong>What will be assessed in the 360° Feedback?</strong><br />
        • There will be 10 simple questions (with a 1-10 scoring range) that
        assess aspects that are important to us and our future culture.<br />
        • There will also be a further 2 open questions, that invite deeper or
        specific feedback.<br />
        <strong>Confidentiality Assurances</strong> <br />• Feedback will be
        anonymous, and other than line manager, respondents cannot be
        identified.<br />
        • The 360° Feedback System has been developed in partnership with a 3rd
        party (NTT) to fulfil confidentially requirements.<br />
        <strong>When will the 360° Feedback process run?</strong><br />
        • The 360 Feedback window will be live <strong>2</strong
        ><span class="f10">nd</span> – <strong>16</strong
        ><span class="f10">th</span><strong>March</strong>.<br />
        • You will need to have selected the individuals to give you feedback by
        latest <strong>COB 8</strong> <span class="f10">th</span
        ><strong>March.</strong> <br />
        • We will provide more information shortly on what happens after 16<span
          class="f10"
          >th</span
        >
        March, when you will receive your report – with additional support &
        guidance made available.
      </p>
    </div>
    <!-- Hotline -->
    <div class="rulers hotline-wrap" v-if="showHotline">
      <img
        class="close hover"
        @click="switchHot"
        src="../../assets/close.png"
        alt=""
      />
      <p class="desc c10 f14">
        <span class="mb8"
          >If you have any questions during the evaluation, please reach
          <strong
            >Christina Liu in Digital Lab, 12F(Mon. to Fri. 9:00~16:00)</strong
          ><br
        /></span>

        On a scale of 1-10 (1 = Not effective / 10 = Extremely Effective), to
        what extent do I effectively demonstrate the behaviors
      </p>
    </div>
    <!-- progress 弹窗 -->
    <div class="rulers pro-wrap" v-if="showPro">
      <img
        class="close hover"
        @click="switchPro"
        src="../../assets/close.png"
        alt=""
      />
      <p class="desc c10 f14">
        Progress = Received from invitation / Invitation sent
      </p>
    </div>
    <!-- 登出弹窗 -->
    <div class="logout" v-if="showLogout">
      <div class="loginout-wrap">
        <p class="f20 tac mt30 mb40">Are you sure to log out？</p>
        <div class="fjsb">
          <button class="butn-item cg" @click="showLogout = false">No</button>
          <button class="butn-item bg cf" @click="logout">yes</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import commentTopControl from "@/src/components/comment/commentTopControl.vue";
import FeedbackSurvey from "components/feedbackSurvey";
export default {
  name: "invitation-home",
  components: {
    FeedbackSurvey,
    commentTopControl,
  },
  data() {
    return {
      hideMenu: true,
      isAssessOver: false,
      isReportStart: true,
      showPro: false,
      showHotline: false,
      showLogout: false,
      headerTitle: "360 Feedback",
      showRule: false,
      isFoldInvites: false,
      isFoldone: false,
      isFoldtwo: false,
      defaultAvatar: "./images/avatar.png",
      imgs: [],
      datas: {
        activeAssessNum: 0,
        activeAssesss: [
          {
            assessee: "string",
            isAssess: 0,
            nameEn: "string",
          },
        ],
        invites: [
          {
            headUrl: "string",
            invitee: "string",
            surveyModelCode: "string",
          },
        ],
        isCrossFunctionalDone: 0,
        isInviteStatusDone: 0,
        isInvited: 0,
        isLineManagerDone: 0,
        isPeerDone: 0,
        isReportsToYouDone: 0,
        isSatisfyNum: 0,
        isSelfFinish: 0,
        isSeniorDone: 0,
        surveyInviteNum: 0,
        surveyInvites: [],
        surveyResults: [],
      },
    };
  },
  mounted() {
    // this.$toast(
    //   "ThisEmailisinternalEmail\nJWEN5@jaguarlandrover.com\nJoe.Xu@china.nttdata.com"
    // );
    if (this.$route.query.menu == "hide") {
      this.hideMenu = false;
    }
    this.pageInit();
  },
  methods: {
    ...mapActions({
      homePage: "invite/homePage",
    }),
    async pageInit() {
      let params = {
        surveyModelCode: "JLRCFBM000001",
      };

      let res = await this.homePage(params);

      this.datas = res.data.data;
      let currentDate = new Date(this.datas.currentTime);
      let AssessOverCompareDate = new Date("2022-03-16 16:00:00");
      let reportCompareDate = new Date("2022-03-21 10:00:00");
      let time = parseInt(this.datas.currentTime.substr(11, 2));
      //this.isAssessOver = currentDate >= AssessOverCompareDate;
      this.isAssessOver = true;
      // this.isReportStart = currentDate >= reportCompareDate;
      // let arr = [];
      // this.datas.invites.forEach((element, i) => {
      //   if (i < 8) {
      //     arr.push("./images/default-avatar.png");
      //   }
      // });
      // this.imgs = arr;
    },

    toggleFoldone() {
      this.isFoldone = !this.isFoldone;
    },
    toggleFoldtwo() {
      this.isFoldtwo = !this.isFoldtwo;
    },
    switchOn() {
      if (this.showHotline) {
        this.showHotline = false;
      }
      if (this.showPro) {
        this.showPro = false;
      }
      this.showRule = !this.showRule;
    },
    switchHot() {
      if (this.showRule) {
        this.showRule = false;
      }
      if (this.showPro) {
        this.showPro = false;
      }
      this.showHotline = !this.showHotline;
    },
    switchPro() {
      if (this.showRule) {
        this.showRule = false;
      }
      if (this.showHotline) {
        this.showHotline = false;
      }
      this.showPro = !this.showPro;
    },
    logout() {
      localStorage.removeItem("token");
      this.showLogout = false;
      this.linkTo("login");
    },
    linkTo(val) {
      this.$router.push(val);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variable";
@media screen and (max-width: 760px) {
  .home-holeBtn-content {
    width: 100%;
    padding: toMRem(20);
    overflow: hidden;

    .home-holeBtn-content-in {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: toMRem(12);
      background: #fff;
      overflow: hidden;
      box-shadow: 0px 4px 9px #ccc;
      padding: toMRem(13) toMRem(10);
      cursor: pointer;
      float: left;

      &.home-holeBtn-content-in2 {
        width: 48%;
        margin-top: toMRem(16);
        justify-content: flex-start;

        &:first-child {
          float: left;

          .home-holeBtn-content-img {
            background: #86bab4;
          }
        }
        &:last-child {
          float: right;

          .home-holeBtn-content-img {
            background: #7da0c3;
          }
        }
      }
    }
    .home-holeBtn-content-img {
      width: toMRem(34);
      height: toMRem(34);
      overflow: hidden;
      border-radius: 100%;
      background: #6ab892;

      img {
        display: block;
        position: relative;
        width: 50%;
        height: auto;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    em {
      margin-left: toMRem(12);
      font-style: normal;
    }
  }
  .invitation-home {
    padding-top: toMRem(76);
    padding-bottom: toMRem(50);
    .add {
      width: toMRem(24);
      height: toMRem(24);
      img {
        width: toMRem(24) !important;
        height: toMRem(24) !important;
      }
    }
    .hot-line {
      margin: auto;
      img {
        width: toMRem(16);
        height: toMRem(16);
      }
    }
    .invit-card {
      .card-wrap {
        width: toMRem(338);
        padding: toMRem(10) 0;
        margin: 0 auto toMRem(20);
        background: #ffffff;
        box-shadow: 0px 4px 9px rgba(209, 209, 209, 0.25);
        border-radius: toMRem(16);
        .titles {
          padding: toMRem(0) toMRem(22) toMRem(10);
        }
        .pic {
          display: block;
          width: toMRem(70);
          margin: 0 auto toMRem(10);
        }
        .invit-btn {
          display: block;
          margin: toMRem(10) auto;
          width: toMRem(167);
          height: toMRem(42);
          line-height: toMRem(30);
          background: #3e886d;
          border-radius: toMRem(81);
          &.disable {
            background: #c9cece;
          }
          &.disabled {
            color: #fff !important;
            background: #c9cece !important;
            border: 1px solid #c9cece !important;
          }
          &.disableds {
            color: #fff !important;
            border: 1px solid #c9cece !important;
            background: #c9cece !important;
          }
          &.invit {
            margin-bottom: toMRem(6);
            color: #3e886d;
            background: #fff;
            border: 1px solid #3e886d;
          }
        }
        .line {
          margin: toMRem(6) auto 0;
          width: toMRem(211);
          height: toMRem(1);
          background: rgba(0, 0, 0, 0.1);
        }
        .detail-wrap {
          padding: toMRem(20) toMRem(30) 0;
          .user-avatar {
            // margin: 0;
            .avatar {
              box-shadow: 0 0 0 1px #fff;
              margin-right: toMRem(-15) !important;
              width: toMRem(26);
              height: toMRem(26);
              display: inline-block;
              background-color: #ffff;
              position: relative;
              text-align: center;
              color: #fff;
              // font-weight: 600;
              vertical-align: bottom;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              border-radius: 50%;
            }
            .num {
              background-color: #f7c85b;
              line-height: toMRem(26);
            }
            .pro {
              line-height: toMRem(26);
              margin-left: toMRem(26);
            }
            .pro-tips {
              position: relative;
              top: toMRem(2);
              width: toMRem(15);
              height: toMRem(15);
              margin-left: toMRem(10);
            }
            .show-more {
              float: right;
              line-height: toMRem(26);
            }
          }
          .tips-item {
            margin-bottom: toMRem(4);
            .check {
              width: toMRem(12);
              height: toMRem(13);
              margin-right: toMRem(9);
              img {
                position: relative;
                bottom: toMRem(2);
                width: toMRem(13);
                height: toMRem(13);
              }
            }
          }
          .tips-item {
            margin-bottom: toMRem(4);
            .check {
              width: toMRem(12);
              height: toMRem(13);
              margin-right: toMRem(9);
              img {
                position: relative;
                bottom: toMRem(2);
                width: toMRem(13);
                height: toMRem(13);
              }
            }
          }
          .invites-wrap {
            margin: 0 toMRem(10) toMRem(10);
            overflow: hidden;
            height: 0;
            &.active {
              overflow: visible;
              height: auto;
            }
            .line {
              margin: toMRem(5) toMRem(12) 0 0;
              width: toMRem(265);
              height: toMRem(1);
              background: rgba(0, 0, 0, 0.1);
            }
            .invites-item {
              line-height: toMRem(24);
            }
          }

          .tips {
            padding-top: toMRem(15);
            // margin-left: toMRem(18);
            .tips-item {
              margin-bottom: toMRem(4);
              .check {
                width: toMRem(12);
                height: toMRem(13);
                margin-right: toMRem(9);
                img {
                  position: relative;
                  bottom: toMRem(2);
                  width: toMRem(13);
                  height: toMRem(13);
                }
              }
            }
          }
        }
      }
    }
    .other-card {
      width: toMRem(338);
      margin: 0 auto;
      padding: toMRem(20);
      background: #ffffff;
      box-shadow: 0px 4px 9px rgba(209, 209, 209, 0.25);
      border-radius: toMRem(16);
      .otitle {
        align-items: center;
        .names {
          padding: toMRem(5) toMRem(18);
          background-color: rgba(97, 214, 204, 0.41);
        }
      }
      .fold-list {
        overflow: hidden;
        height: toMRem(115);
        &.active {
          overflow: auto;
          height: auto;
        }
        .empty {
          height: toMRem(100);
          padding: toMRem(20);
          color: #c9cece;
          text-align: center;
          img {
            margin: 0 toMRem(20);
            width: toMRem(54);
            height: toMRem(46);
          }
        }
        .o-item {
          .rate {
            width: toMRem(74);
            height: toMRem(26);
            line-height: toMRem(26);
            border: 1px solid #3e886d;
            border-radius: toMRem(26);
            background: #ffffff;
            &.disable {
              border: 1px solid #c9cece;
              color: #c9cece;
            }
          }
          .done {
            width: toMRem(74);
            height: toMRem(26);
            line-height: toMRem(26);
          }
        }
      }

      .add-to {
        img {
          width: toMRem(30);
          height: toMRem(30);
          margin-right: toMRem(10);
        }
      }
    }
    .survey-card {
      width: toMRem(338);
      margin: toMRem(20) auto auto;
    }
    .rulers {
      position: absolute;
      top: toMRem(120);
      left: toMRem(18.5);
      width: toMRem(338);
      height: toMRem(366);
      padding: toMRem(20);
      background: #ffffff;
      box-shadow: 0 4px 19px rgba(0, 0, 0, 0.3);
      border-radius: 16px;
      .close {
        z-index: 50;
        position: absolute;
        top: toMRem(27);
        right: toMRem(26);
        width: toMRem(20);
        height: toMRem(20);
      }
      .desc {
        max-height: toMRem(240);
        overflow-y: scroll;
        line-height: toMRem(25);
        padding-right: toMRem(5);
      }
    }
    .hotline-wrap {
      position: fixed;
      top: toMRem(60);
      height: auto;
      .desc {
        height: auto;
      }
    }
    .pro-wrap {
      position: absolute;
      top: toMRem(496);
      left: toMRem(84);
      width: toMRem(240);
      height: auto;
      padding: toMRem(10);
      .close {
        z-index: 50;
        position: absolute;
        top: toMRem(17);
        right: toMRem(16);
        width: toMRem(14);
        height: toMRem(14);
      }
      .desc {
        height: auto;
      }
    }
    .logout {
      z-index: 110;
      position: fixed;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.5);

      .loginout-wrap {
        overflow: hidden;
        width: toMRem(330);
        padding: toMRem(30);
        margin: toMRem(200) auto;
        background: #ffffff;
        box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.3);
        border-radius: toMRem(16);

        .butn-item {
          width: toMRem(129);
          height: toMRem(42);
          border: 1px solid #3e886d;
          box-sizing: border-box;
          border-radius: toMRem(81);
          background: #fff;
          &.bg {
            background: #3e886d;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 761px) {
  .home-holeBtn-content {
    display: flex;
    width: 100%;
    padding: toPad(20);

    .home-holeBtn-content-in {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: toPad(12);
      background: #fff;
      overflow: hidden;
      box-shadow: 0px 4px 9px #ccc;
      padding: toPad(13) toPad(10);
      cursor: pointer;
      margin: 0 toPad(13);

      &.home-holeBtn-content-in2 {
        &:first-child {
          .home-holeBtn-content-img {
            background: #86bab4;
          }
        }
        &:last-child {
          .home-holeBtn-content-img {
            background: #7da0c3;
          }
        }
      }
    }
    .home-holeBtn-content-img {
      width: toPad(34);
      height: toPad(34);
      overflow: hidden;
      border-radius: 100%;
      background: #6ab892;

      img {
        display: block;
        position: relative;
        width: 50%;
        height: auto;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    em {
      margin-left: toPad(12);
      font-style: normal;
    }
  }
  .invitation-home {
    padding-top: toPad(100);
    // overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: toPad(50);
    .add {
      width: toPad(24);
      height: toPad(24);
      img {
        width: toPad(24) !important;
        height: toPad(24) !important;
      }
    }
    .hot-line {
      margin: auto;
      img {
        width: toPad(16);
        height: toPad(16);
      }
    }
    .invit-card {
      margin: 0 auto;
      .card-wrap {
        width: 45vw;
        padding: toPad(10) 0;
        margin: 0 auto toPad(20);
        background: #ffffff;
        box-shadow: 0px 4px 9px rgba(209, 209, 209, 0.25);
        border-radius: toPad(16);
        .titles {
          padding: toPad(16) toPad(22);
        }
        .pic {
          display: block;
          width: toPad(70);
          margin: 0 auto toPad(10);
        }
        .invit-btn {
          display: block;
          margin: toPad(10) auto;
          width: toPad(167);
          height: toPad(42);
          line-height: toPad(30);
          background: #3e886d;
          border-radius: toPad(81);
          &.disable {
            background: #c9cece;
          }
          &.disabled {
            color: #fff !important;
            background: #c9cece !important;
            border: 1px solid #c9cece !important;
          }
          &.disableds {
            color: #fff !important;
            border: 1px solid #c9cece !important;
            background: #c9cece !important;
          }
          &.invit {
            margin-bottom: toPad(6);
            color: #3e886d;
            background: #fff;
            border: 1px solid #3e886d;
          }
        }
        .line {
          margin: toPad(6) auto 0;
          width: toPad(211);
          height: toPad(1);
          background: rgba(0, 0, 0, 0.1);
        }
        .detail-wrap {
          padding: toPad(20) toPad(30) 0;
          .user-avatar {
            // margin: 0;
            .avatar {
              box-shadow: 0 0 0 1px #fff;
              margin-right: toPad(-15) !important;
              width: toPad(26);
              height: toPad(26);
              display: inline-block;
              background-color: #ffff;
              position: relative;
              text-align: center;
              color: #fff;
              // font-weight: 600;
              vertical-align: bottom;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              border-radius: 50%;
            }
            .num {
              background-color: #f7c85b;
              line-height: toPad(26);
            }
            .pro {
              line-height: toPad(26);
              margin-left: toPad(26);
            }
            .pro-tips {
              position: relative;
              top: toPad(2);
              width: toPad(15);
              height: toPad(15);
              margin-left: toPad(10);
            }
            .show-more {
              float: right;
              line-height: toPad(26);
            }
          }
          .tips-item {
            margin-bottom: toPad(4);
            .check {
              width: toPad(12);
              height: toPad(12);
              margin-right: toPad(9);
              img {
                position: relative;
                // top: toPad(1);
                width: toPad(13);
                height: toPad(13);
              }
            }
          }
          .invites-wrap {
            margin: 0 toPad(10) toPad(10);
            overflow: hidden;
            height: 0;
            &.active {
              overflow: visible;
              height: auto;
            }
            .line {
              margin: toPad(5) toPad(12) 0 0;
              width: 37vw;
              height: toPad(1);
              background: rgba(0, 0, 0, 0.1);
            }
            .invites-item {
              line-height: toPad(24);
            }
          }
          .tips {
            padding-top: toPad(15);
            // margin-left: toPad(18);
            .tips-item {
              margin-bottom: toPad(4);
              .check {
                width: toPad(12);
                height: toPad(13);
                margin-right: toPad(9);
                img {
                  position: relative;
                  top: toPad(1);
                  width: toPad(13);
                  height: toPad(13);
                }
              }
            }
          }
        }
      }
    }
    .other-card {
      width: 40vw;
      margin: 0 auto toPad(20);
      padding: toPad(20);
      background: #ffffff;
      box-shadow: 0px 4px 9px rgba(209, 209, 209, 0.25);
      border-radius: toPad(16);
      .otitle {
        align-items: center;
        .names {
          padding: toPad(5) toPad(18);
          background-color: rgba(97, 214, 204, 0.41);
        }
      }
      .fold-list {
        overflow: hidden;
        height: toPad(115);
        &.active {
          overflow: auto;
          height: auto;
        }
        .empty {
          height: toPad(100);
          padding: toPad(20);
          color: #c9cece;
          text-align: center;
          img {
            margin: 0 toPad(20);
            width: toPad(54);
            height: toPad(46);
          }
        }
        .o-item {
          .rate {
            width: toPad(74);
            height: toPad(26);
            line-height: toPad(26);
            border: 1px solid #3e886d;
            border-radius: toPad(26);
            background: #ffffff;
            &.disable {
              border: 1px solid #c9cece;
              color: #c9cece;
            }
          }
          .done {
            width: toPad(74);
            height: toPad(26);
            line-height: toPad(26);
          }
        }
      }

      .add-to {
        img {
          width: toPad(30);
          height: toPad(30);
          margin-right: toPad(10);
        }
      }
    }
    .survey-card {
      width: 45vw;
      margin-left: 3.8vw;
    }
    .rulers {
      position: absolute;
      top: toPad(150);
      left: 6vw;
      width: 40vw;
      height: toPad(358);
      padding: toPad(30);
      background: #ffffff;
      box-shadow: 4px 4px 9px rgba(209, 209, 209, 0.25);
      border-radius: 16px;
      .close {
        z-index: 50;
        position: absolute;
        top: toPad(27);
        right: toPad(26);
        width: toPad(20);
        height: toPad(20);
      }
      .desc {
        max-height: toPad(240);
        overflow-y: scroll;
        line-height: toPad(25);
        padding-right: toPad(5);
      }
    }
    .hotline-wrap {
      position: fixed;
      top: toPad(90);
      left: 56.4vw;
      height: auto;
      .desc {
        height: auto;
      }
    }
    .pro-wrap {
      position: absolute;
      top: toPad(496);
      left: toPad(84);
      width: toPad(240);
      height: auto;
      padding: toPad(10);
      .close {
        z-index: 50;
        position: absolute;
        top: toPad(17);
        right: toPad(16);
        width: toPad(14);
        height: toPad(14);
      }
      .desc {
        height: auto;
      }
    }
    .logout {
      z-index: 110;
      position: fixed;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.5);
      .loginout-wrap {
        overflow: hidden;
        max-width: 30vw;
        padding: toPad(30);
        margin: toPad(200) auto;
        background: #ffffff;
        box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.3);
        border-radius: toPad(16);
        .butn-item {
          width: toPad(129);
          height: toPad(42);
          border: 1px solid #3e886d;
          box-sizing: border-box;
          border-radius: toPad(81);
          background: #fff;
          &.bg {
            background: #3e886d;
          }
        }
      }
    }
  }
}
</style>
