<template>
  <div class="choose">
    <commentTopControl :title="headerTitle" :leftSlot="true">
      <template slot="left">
        <div class="hover" @click="goHome">
          <i class="el-icon-arrow-left"></i>
        </div>
      </template>
      <template slot="right">
        <div v-if="!isAdd" class="add cg f10 hover" @click="goEmail">
          <!-- <img src="../../assets/add-icon.png" alt="" /> -->
          Add external
        </div>
      </template>
      <!-- <img slot="right" :src="headImg" /> -->
    </commentTopControl>
    <van-search
      v-model="value"
      placeholder="Search"
      @search="pageInit"
      left-icon=""
    >
      <template #left-icon>
        <img class="search-icon" src="../../assets/search.png" alt="" />
      </template>
      <template #right-icon>
        <img
          class="clear-tag hover"
          src="../../assets/clear.png"
          @click="clearTag"
          alt=""
        />
        <div class="search-btn hover" @click="onSearch">
          <!-- <van-icon name="plus" color="#fff" /> -->
          Search
        </div>
      </template>
    </van-search>
    <p class="no-ideas fjc f10">
      <img class="icons" src="../../assets/user-icon.png" alt="" />
      No ideas? Go to
      <a
        target="_blank"
        href="https://my.jlrinfo.cn/_layouts/JLRPortal/Pages/JLRChinaFamily.aspx"
      >
        <span class="fb hover">JLR FAMILY</span>
      </a>
      to search for the employee detail.
    </p>
    <p class="tag-title f14 pt10 fjsb">
      Grade
      <!-- <img
        class="clear-tag"
        src="../../assets/clear.png"
        @click="clearTag"
        alt=""
      /> -->
    </p>
    <selectTag
      class="p120 bf"
      :disabled="readonly"
      :tags.sync="selLabels"
      :datas.sync="labelList"
    ></selectTag>

    <div class="user-wrap">
      <!-- line manager -->
      <van-checkbox
        v-model="alsoTrue"
        disabled
        v-if="showlineManagers && !isAdd"
      >
        <template #icon="props">
          <img
            v-if="isInvited"
            class="img-icon line-icon"
            src="../../assets/block.png"
            alt=""
          />
          <img
            v-else
            class="img-icon line-icon"
            :src="props.checked ? activeIcon : inactiveIcon"
          />
        </template>
        <div class="line-manager mb8">
          <p class="line-title cg">Line-manager</p>
          <div class="user-item">
            <img class="avatar" src="../../assets/default.png" alt="" />
            <div class="names">
              <p class="f16 fb c01">{{ lineManagers.nameEN }}</p>
              <p class="f10 c39">{{ lineManagers.email }}</p>
            </div>
          </div>
          <!-- <p class="f10 tac c01">
            （If the information of line manager is wrong or incomplete, please
            invite the correct line manager to score）
          </p> -->
        </div>
      </van-checkbox>
      <p class="f10 bf c01 plr20" v-if="!isAdd">
        Please invite the correct Line manager or add external in case
        incomplete or wrong.
      </p>
      <!-- 组织架构 -->
      <van-checkbox-group v-model="checked">
        <div v-if="users.length">
          <el-menu
            :default-openeds="openArr"
            class="el-menu-vertical-demo"
            v-for="(item, i) in users"
            :key="i"
          >
            <el-submenu :index="`${i + 1}`">
              <template slot="title">
                <p class="user-title f14">{{ item.name }}</p>
              </template>
              <!-- <el-menu-item
            style="padding: 0 !important; color: #000 !important"
            index="1-1"
            >二级1</el-menu-item
          > -->
              <van-checkbox
                :name="itemb.id"
                v-for="(itemb, k) in item.data"
                :key="`l${k}`"
                :disabled="
                  (!isAdd && itemb.isInviteDone) ||
                  (isAdd && itemb.isActiveAssessDone == 1)
                "
                @click="selOne(itemb)"
              >
                <template #icon="props">
                  <img
                    v-if="
                      (!isAdd && itemb.isInviteDone) ||
                      (isAdd && itemb.isActiveAssessDone == 1)
                    "
                    class="img-icon"
                    src="../../assets/block.png"
                    alt=""
                  />
                  <img
                    v-else
                    class="img-icon"
                    :src="props.checked ? activeIcon : inactiveIcon"
                  />
                </template>
                <div class="user-item">
                  <img class="avatar" src="../../assets/default.png" alt="" />
                  <div class="names">
                    <p
                      class="f16 fb"
                      :class="
                        (!isAdd && itemb.isInviteDone) ||
                        (isAdd && itemb.isActiveAssessDone == 1)
                          ? 'c04'
                          : ''
                      "
                    >
                      {{ itemb.nameEN }}
                    </p>
                    <p
                      class="f10"
                      :class="
                        (!isAdd && itemb.isInviteDone) ||
                        (isAdd && itemb.isActiveAssessDone == 1)
                          ? 'c04'
                          : ''
                      "
                    >
                      {{ itemb.email }}
                    </p>
                  </div>
                </div>
              </van-checkbox>
              <el-submenu
                :index="`${i + 1}${j + 1}`"
                v-for="(items, j) in item.childList"
                :key="j"
              >
                <template slot="title">
                  <p class="sub-user-title f12">{{ items.name }}</p></template
                >
                <!-- <el-menu-item
              style="padding: 0 !important; color: #000 !important"
              index="1-4-1"
              >三级1</el-menu-item
            > -->
                <van-checkbox
                  :name="itema.id"
                  v-for="(itema, j) in items.data"
                  :key="`${i}${j}`"
                  :disabled="
                    (!isAdd && itema.isInviteDone) ||
                    (isAdd && itema.isActiveAssessDone == 1)
                  "
                  @click="selOne(itema)"
                >
                  <template #icon="props">
                    <img
                      v-if="
                        (!isAdd && itema.isInviteDone) ||
                        (isAdd && itema.isActiveAssessDone == 1)
                      "
                      class="img-icon"
                      src="../../assets/block.png"
                      alt=""
                    />
                    <img
                      v-else
                      class="img-icon"
                      :src="props.checked ? activeIcon : inactiveIcon"
                    />
                  </template>
                  <div class="user-item">
                    <img class="avatar" src="../../assets/default.png" alt="" />
                    <div class="names">
                      <p
                        class="f16 fb"
                        :class="
                          (!isAdd && itema.isInviteDone) ||
                          (isAdd && itema.isActiveAssessDone == 1)
                            ? 'c04'
                            : ''
                        "
                      >
                        {{ itema.nameEN }}
                      </p>
                      <p
                        class="f10"
                        :class="
                          (!isAdd && itema.isInviteDone) ||
                          (isAdd && itema.isActiveAssessDone == 1)
                            ? 'c04'
                            : ''
                        "
                      >
                        {{ itema.email }}
                      </p>
                    </div>
                  </div>
                </van-checkbox>
              </el-submenu>
            </el-submenu>
          </el-menu>
        </div>
        <van-empty
          v-else
          class="user-empty"
          image="./images/no-user.png"
          description="User not found."
        />
      </van-checkbox-group>
      <!-- <van-checkbox-group v-model="checked">
        <div v-for="(item, i) in users" :key="i">
          <p class="f14 tin1 fac user-title">{{ item.name }}</p>
          <van-checkbox
            :name="items.id"
            v-for="(items, j) in item.data"
            :key="j"
            @click="selOne(items)"
          >
            <template #icon="props">
              <img
                class="img-icon"
                :src="props.checked ? activeIcon : inactiveIcon"
              />
            </template>
            <div class="user-item">
              <img class="avatar" src="../../assets/default.png" alt="" />
              <div class="names">
                <p class="f16 fb">{{ items.nameEN }}</p>
                <p class="f10 c39">{{ items.email }}</p>
              </div>
            </div>
          </van-checkbox>
        </div>
      </van-checkbox-group> -->
    </div>
    <!-- <div class="subts-wrap" v-if="isInvited">
      <button
        class="subts f14 cf"
        :class="checked.length < 1 ? 'disable' : ''"
        :disabled="checked.length < 1"
        @click="onSubmit"
      >
        Choose
      </button>
    </div> -->
    <div class="subts-wrap" v-if="isAdd">
      <button
        class="subts f14 cf"
        :class="checked.length < 1 ? 'disable' : ''"
        :disabled="checked.length < 1"
        @click="onActive"
      >
        Choose
      </button>
    </div>
    <div v-else>
      <div class="bot-wrap" :class="isFold ? 'active' : 'bctive'">
        <div class="tips">
          <div class="tips-item fac f12" :class="!ruleLm.value ? 'c04' : ''">
            <div class="check">
              <img src="../../assets/checked.png" v-if="ruleLm.value" alt="" />
              <span v-else></span>
            </div>
            Line Manager
          </div>
          <div class="tips-item fac f12" :class="!ruleCf.value ? 'c04' : ''">
            <div class="check">
              <img src="../../assets/checked.png" v-if="ruleCf.value" alt="" />
              <span v-else></span>
            </div>
            At least 2 cross-functional colleague (Within enterprise)
          </div>
          <div class="tips-item fac f12" :class="!ruleSe.value ? 'c04' : ''">
            <div class="check">
              <img src="../../assets/checked.png" v-if="ruleSe.value" alt="" />
              <span v-else></span>
            </div>
            At least 2 colleague who is more senior (higher grade)
          </div>
          <div class="tips-item fac f12" :class="!rulePe.value ? 'c04' : ''">
            <div class="check">
              <img src="../../assets/checked.png" v-if="rulePe.value" alt="" />
              <span v-else></span>
            </div>
            At least 2 colleague who is a peer (same grade)
          </div>
          <div class="tips-item fac f12" :class="!ruleRt.value ? 'c04' : ''">
            <div class="check">
              <img src="../../assets/checked.png" v-if="ruleRt.value" alt="" />
              <span v-else></span>
            </div>
            At least 1 member of your team who reports to you (if applicable)
          </div>
          <!-- 提示 -->
          <div
            class="tips-item fac f12"
            :class="checked.length + historyNum < 8 ? 'c04' : ''"
          >
            <div class="check">
              <img src="../../assets/checked.png" v-if="allTotal >= 8" alt="" />
            </div>
            At least 8 colleagues
          </div>
        </div>
      </div>
      <div class="buttons fjsb">
        <div class="nums fac">
          <span class="f16 fb">Total : {{ allTotal }}</span>
          <img
            class="hover"
            src="../../assets/arrow-up.png"
            :class="{ active: isFold }"
            alt=""
            @click="fold"
          />
        </div>
        <button
          class="subt f14 cf"
          :class="checked.length < 1 ? 'disable' : ''"
          :disabled="checked.length < 1"
          @click="onSubmit"
        >
          Choose
        </button>
      </div>
    </div>
    <van-overlay
      :show="loadingShow"
      z-index="120"
      :custom-style="{ background: 'rgba(0,0,0,0.2)' }"
    >
      <div class="loading-wrap fjc">
        <van-loading size="50px" color="#3E886D" text-color="#3E886D" vertical
          >Loading...</van-loading
        >
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import commentTopControl from "@/src/components/comment/commentTopControl.vue";
import selectTag from "@/src/components/selectTag.vue";
export default {
  name: "choose",
  components: {
    selectTag,
    commentTopControl,
  },
  data() {
    return {
      isInvited: false,
      loadingShow: true,
      showlineManagers: true,
      lineManagers: {},
      openArr: ["1", "11"],
      alsoTrue: true,
      headerTitle: "Choose  colleague",
      isPassd: false,
      isAdd: false,
      isFold: true,
      value: "",
      readonly: false,
      selLabels: [],
      labelList: ["Senior", "Peer", "Cross-functional", "Reports to you"],
      checked: [],
      checkedArr: [],
      activeIcon: "./images/on.png",
      inactiveIcon: "./images/off.png",
      users: [],
      ruleLm: {
        value: false,
        arr: 0,
        length: 0,
      },
      ruleCf: {
        value: false,
        arr: 0,
        length: 0,
      },
      ruleSe: {
        value: false,
        arr: 0,
        length: 0,
      },
      rulePe: {
        value: false,
        arr: 0,
        length: 0,
      },
      ruleRt: {
        value: false,
        arr: 0,
        length: 0,
      },
      historyNum: 0,
      isResultContainLineManager: 0,
      staticNum: null,
    };
  },
  watch: {
    selLabels(newVal, oldVal) {
      if (newVal) {
        console.log(111);
        this.pageInit();
      }
    },
    // value(val) {
    //   this.pageInit();
    // },
  },
  computed: {
    allTotal() {
      if (this.checked.length) {
        return this.checked.length + this.historyNum;
      } else {
        return 0 + this.historyNum;
      }
    },
  },
  mounted() {
    console.log(this.$route.query.isInvited, this.$route.query.id);
    if (this.$route.query.isInvited == 1) {
      this.isInvited = true;
      // this.showlineManagers = false;
    }

    if (this.$route.query.id == 1) {
      this.isResultContainLineManager = 1;
      this.isAdd = true;
      this.showlineManagers = false;
    }
    this.getTagNum();
    this.getInviteNum();
    this.pageInit();
  },
  methods: {
    ...mapActions({
      inviteSubmit: "invite/inviteSubmit",
      userList: "invite/userList",
      tagNum: "invite/tagNum",
      inviteNum: "invite/inviteNum",
      activeAssess: "invite/activeAssess",
    }),
    async getInviteNum() {
      let res = await this.inviteNum({ code: "JLRCFBM000001" });
      let nums = res.data.data;
      let objStr = JSON.stringify(nums);
      console.log(nums);
      if (objStr != "{}") {
        console.log(111);
        this.staticNum = nums;
        this.ruleLm.arr = nums.lineManager;
        this.ruleCf.arr = nums.crossFunctional;
        this.ruleSe.arr = nums.senior;
        this.rulePe.arr = nums.peer;
        this.ruleRt.arr = nums.reportsToYou;
        this.historyNum = nums.total;
      } else {
        this.staticNum = null;
      }
      console.log(this.historyNum);
    },
    async getTagNum() {
      let res = await this.tagNum();
      let tags = res.data.data;
      this.ruleLm.length = tags.lineManager;
      this.ruleCf.length = tags.crossFunctional;
      this.ruleSe.length = tags.senior;
      this.rulePe.length = tags.peer;
      this.ruleRt.length = tags.reportsToYou;
      console.log(this.ruleCf.length);
    },
    async pageInit() {
      console.log(this.$route.query.id);
      let params = {
        param: this.value,
        grade: this.selLabels,
        isResultContainLineManager: this.isResultContainLineManager,
      };
      this.loadingShow = true;
      let res = await this.userList(params);
      this.loadingShow = false;
      this.users = res.data.data.childList;
      if (this.value.length > 0 || this.selLabels.length) {
        let arr = [];
        this.users.forEach((e, i) => {
          arr.push(`${i + 1}`);
          if (e.childList) {
            e.childList.forEach((f, j) => {
              arr.push(`${i + 1}${j + 1}`);
            });
          }
        });
        this.openArr = arr;
      } else {
        this.openArr = ["1", "11"];
      }

      /* eslint-disable */
      if (res.data.data.hasOwnProperty("lineManager") && !this.isAdd) {
        this.showlineManagers = true;
        this.lineManagers = res.data.data.lineManager;
        if (!this.isInvited&&this.ruleLm.arr<1) {
          this.checked.push(this.lineManagers.id);
          this.checkedArr.push(this.lineManagers)
          this.ruleLm.arr++
        }
      } else {
        this.showlineManagers = false;
      }
      /* eslint-enable */

      // this.users.forEach((e) => {
      //   if (e.name == "Line-manager") {
      //     this.ruleLm.length = e.data.length;
      //   }
      //   if (e.name == "Cross-functional") {
      //     this.ruleCf.length = e.data.length;
      //   }
      //   if (e.name == "Senior") {
      //     this.ruleSe.length = e.data.length;
      //   }
      //   if (e.name == "Peer") {
      //     this.rulePe.length = e.data.length;
      //   }
      //   if (e.name == "Reports to you") {
      //     this.ruleRt.length = e.data.length;
      //   }
      // });
      // console.log(this.ruleRt);
      // console.log(this.user[0].childList[0].data.length);
    },
    goHome() {
      // if (this.checked.length) {
      //   if (this.isAdd) {
      //     this.onActive(1);
      //   } else {
      //     this.onSubmit(1);
      //   }
      // } else {
      this.linkTo("home");
      // }
    },
    goEmail() {
      // if (this.checked.length) {
      //   if (this.isAdd) {
      //     this.onActive(2);
      //   } else {
      //     this.onSubmit(2);
      //   }
      // } else {
      this.linkTo("addEmail");
      // }
    },
    onSearch() {
      if (this.selLabels.length) {
        this.selLabels = [];
      }
      this.pageInit();
    },
    clearTag() {
      this.selLabels = [];
      this.value = "";
    },
    onActive(val) {
      this.$dialog
        .alert({
          title: "Confirm",
          message: "Cannot be modified after the submission.",
          theme: "round-button",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          confirmButtonColor: "#3E886D",
          cancelButtonText: "Rethink",
          cancelButtonColor: "#D8DFE2",
        })
        .then(() => {
          let params = {
            assessees: this.checked,
            surveyModelCode: "JLRCFBM000001",
          };

          this.activeAssess(params).then((res) => {
            if (res.data.code === "0") {
              this.$toast({
                message: `<div><p style="font-size:14px">Well done！</p><p style="font-size:12px">Now you can give your feedback.</p></div>`,
                type: "html",
              });
              setTimeout(() => {
                // if (val && val == 1) {
                this.linkTo("home");
                // }
                // if (val && val == 2) {
                //   this.linkTo("addEmail");
                // }
              }, 1500);
            }
          });
        })
        .catch(() => {
          // if (val && val == 1) {
          //   this.linkTo("home");
          // }
          // if (val && val == 2) {
          //   this.linkTo("addEmail");
          // }
        });
    },
    selOne(val) {
      console.log(val, this.checked);
      if (this.checked.includes(val.id)) {
        this.checkedArr.push(val);
      } else {
        this.checkedArr.forEach((e, i) => {
          if (e.id == val.id) {
            this.checkedArr.splice(i, 1);
          }
        });
      }
      this.checkStatus();
    },
    checkStatus() {
      if (this.staticNum) {
        this.ruleLm.arr = this.staticNum.lineManager;
        this.ruleCf.arr = this.staticNum.crossFunctional;
        this.ruleSe.arr = this.staticNum.senior;
        this.rulePe.arr = this.staticNum.peer;
        this.ruleRt.arr = this.staticNum.reportsToYou;
      } else {
        this.ruleLm.arr = 0;
        this.ruleCf.arr = 0;
        this.ruleSe.arr = 0;
        this.rulePe.arr = 0;
        this.ruleRt.arr = 0;
      }
      if (this.checkedArr.length) {
        this.checkedArr.forEach((e) => {
          if (e.type.indexOf("1") != -1) {
            this.ruleLm.arr++;
          }
          if (e.type.indexOf("4") != -1) {
            this.ruleCf.arr++;
          }
          if (e.type.indexOf("2") != -1) {
            this.ruleSe.arr++;
          }
          if (e.type.indexOf("3") != -1) {
            this.rulePe.arr++;
          }
          if (e.type.indexOf("5") != -1) {
            this.ruleRt.arr++;
          }
        });
      }
      console.log(this.ruleLm);
      if (
        this.ruleLm.length < 1 ||
        (this.ruleLm.length == 1 && this.ruleLm.arr >= 1)
      ) {
        this.ruleLm.value = true;
      } else {
        this.ruleLm.value = false;
      }
      console.log(this.ruleLm.value);
      if (
        this.ruleCf.length < 1 ||
        (this.ruleCf.length == 1 && this.ruleCf.arr >= 1) ||
        (this.ruleCf.length >= 2 && this.ruleCf.arr >= 2)
      ) {
        this.ruleCf.value = true;
      } else {
        this.ruleCf.value = false;
      }
      if (
        this.ruleSe.length < 1 ||
        (this.ruleSe.length == 1 && this.ruleSe.arr >= 1) ||
        (this.ruleSe.length >= 2 && this.ruleSe.arr >= 2)
      ) {
        this.ruleSe.value = true;
      } else {
        this.ruleSe.value = false;
      }
      if (
        this.rulePe.length < 1 ||
        (this.rulePe.length == 1 && this.rulePe.arr >= 1) ||
        (this.rulePe.length >= 2 && this.rulePe.arr >= 2)
      ) {
        this.rulePe.value = true;
      } else {
        this.rulePe.value = false;
      }
      if (
        this.ruleRt.length < 1 ||
        (this.ruleRt.length == 1 && this.ruleRt.arr >= 1) ||
        this.ruleRt.arr > 0
      ) {
        this.ruleRt.value = true;
      } else {
        this.ruleRt.value = false;
      }
    },
    onSubmit(val) {
      console.log(!this.isInvited);
      this.$dialog
        .alert({
          title: "Confirm",
          message: "Cannot be modified after the submission.",
          theme: "round-button",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          confirmButtonColor: "#3E886D",
          cancelButtonText: "Rethink",
          cancelButtonColor: "#D8DFE2",
        })
        .then(() => {
          let params = {
            invitees: this.checked.map(String),
            surveyModelCode: "JLRCFBM000001",
            isFirst: !this.isInvited,
          };

          this.inviteSubmit(params).then((res) => {
            if (res.data.code === "0") {
              this.$toast({
                message: `<div><p style="font-size:14px">Well done！</p><p style="font-size:12px">Please continue to give feedback for others.</p></div>`,
                type: "html",
              });
              setTimeout(() => {
                // if (val && val == 1) {
                this.linkTo("home");
                // }
                // if (val && val == 2) {
                //   this.linkTo("addEmail");
                // }
              }, 1500);
            }
          });
        })
        .catch(() => {
          // if (val && val == 1) {
          //   this.linkTo("home");
          // }
          // if (val && val == 2) {
          //   this.linkTo("addEmail");
          // }
        });
    },
    fold() {
      this.checkStatus();
      this.isFold = !this.isFold;
    },
    linkTo(val) {
      this.$router.push(val);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variable";
@media screen and (max-width: 760px) {
  .choose {
    min-height: 100vh;
    padding-top: toMRem(56);
    border-top: 2px solid #eeeeee;
    .loading-wrap {
      width: 100%;
      height: 100%;
    }
    .add {
      // text-decoration: underline;
      margin: auto;
    }
    /deep/ .van-search__content {
      padding-left: toMRem(6);
      background-color: #fff;
      .van-field__control {
        text-indent: 1em;
      }
      .search-icon {
        margin-top: toMRem(2);
        width: toMRem(18);
        height: toMRem(18);
      }
      .clear-tag {
        position: relative;
        top: toMRem(5);
        width: toMRem(18);
        height: toMRem(18);
        margin-right: toMRem(10);
      }
      .search-btn {
        display: inline-block;
        padding: toMRem(3) toMRem(14);
        margin-right: toMRem(8);
        border-radius: toMRem(20);
        // border: 1px solid #3e886d;
        background: rgba($color: #3e886d, $alpha: 1);
        color: #fff;
      }
    }
    .no-ideas {
      line-height: toMRem(24);
      flex-wrap: wrap;
      .icons {
        width: toMRem(12);
        height: toMRem(12);
        margin-right: toMRem(6);
      }
      span {
        padding: 0 toMRem(4);
        text-decoration: underline;
      }
    }
    .tag-title {
      background: #fff;
      padding: 0 toMRem(18);
      .clear-tag {
        width: toMRem(18);
        height: toMRem(18);
      }
    }
    .user-wrap {
      padding-bottom: toMRem(100);
      .user-empty {
        margin-top: toMRem(80);
        /deep/.van-empty__image {
          width: toMRem(79);
          height: toMRem(86);
        }
        /deep/.van-empty__description {
          font-size: toMRem(14);
          color: #010101;
        }
      }
      .user-title {
        padding: toMRem(2) toMRem(16);
        background: rgba($color: #c9cece, $alpha: 0.2);
      }
      .line-manager {
        width: toMRem(309);
        padding: toMRem(10) 0 0;
        border-radius: toMRem(16);
        box-shadow: 0px 4px 9px rgba(209, 209, 209, 0.25);
        .names {
          border-bottom: 1px solid #fff;
        }
      }
      .sub-user-title {
        padding-left: toMRem(26);
      }
      /deep/ .el-submenu__title {
        height: toMRem(28);
        line-height: toMRem(28);
        padding-left: toMRem(16) !important;
        padding: 0 !important;
        .el-submenu__icon-arrow {
          top: 40%;
          font-size: toMRem(20);
        }
        /deep/.el-menu {
          padding: 0 !important;
          .el-menu-item {
            padding-left: 0 !important;
            padding: 0 !important;
            color: #000 !important;
            &.is-active {
              color: #000 !important;
            }
          }
          /deep/.el-submenu {
            padding: 0 !important;
            .el-menu-item {
              padding-left: 0 !important;
              padding: 0 !important;
              &.is-active {
                color: #000 !important;
              }
            }
          }
        }
      }
      /deep/.van-checkbox {
        padding-left: toMRem(18);
        background-color: #fff;
        .img-icon {
          height: toMRem(22);
          &.line-icon {
            position: relative;
            top: toMRem(10);
          }
        }
        .line-title {
          margin-left: toMRem(20);
        }
        .user-item {
          display: flex;
          align-items: center;
          margin-left: toMRem(20);
          .avatar {
            width: toMRem(45);
            height: toMRem(45);
            border-radius: 50%;
          }
          .names {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: toMRem(270);
            height: toMRem(64);
            margin-left: toMRem(12);
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          }
        }
      }
    }
    .subts-wrap {
      position: fixed;
      left: 0;
      bottom: toMRem(10);
      width: 100%;
      .subts {
        display: block;
        width: toMRem(167);
        height: toMRem(42);
        line-height: toMRem(30);
        margin: 0 auto;
        background: #3e886d;
        border-radius: toMRem(81);
        box-shadow: 0px 15px 14px rgba(0, 0, 0, 0.15);
        &.disable {
          background: #c9cece;
        }
      }
    }

    .bot-wrap {
      position: fixed;
      left: 0;
      bottom: toMRem(-140);
      width: toMRem(375);
      height: toMRem(292);
      padding: toMRem(22) toMRem(25);
      border-radius: toMRem(36) toMRem(36) 0 0;
      background: #ffffff;
      box-shadow: 0px -4px 9px rgba(191, 191, 191, 0.25);
      .tips {
        padding: toMRem(15) 0;
        .tips-item {
          .check {
            width: toMRem(13);
            height: toMRem(13);
            margin-right: toMRem(9);
            img {
              width: toMRem(13);
              height: toMRem(13);
            }
          }
        }
      }
      &.active {
        animation: hide 1s forwards;
      }
      &.bctive {
        animation: show 1s forwards;
      }
    }
    .buttons {
      position: fixed;
      left: 0;
      bottom: 0;
      align-items: center;
      width: toMRem(375);
      padding: toMRem(0) toMRem(25) toMRem(16);
      background-color: #fff;
      .nums {
        img {
          width: toMRem(17);
          height: toMRem(9);
          margin-left: toMRem(10);
          &.active {
            transform: rotate(180deg);
          }
        }
      }
      .subt {
        width: toMRem(167);
        height: toMRem(42);
        line-height: toMRem(30);
        background: #3e886d;
        border-radius: toMRem(81);
        box-shadow: 0px 15px 14px rgba(0, 0, 0, 0.15);
        &.disable {
          background: #c9cece;
        }
      }
    }
  }
  @include keyframes(hide) {
    from {
      bottom: 0;
    }
    to {
      bottom: toMRem(-208);
    }
  }
  @include keyframes(show) {
    from {
      bottom: toMRem(-208);
    }
    to {
      bottom: 0;
    }
  }
}
@media screen and (min-width: 761px) {
  .choose {
    min-height: 100vh;
    padding-top: toPad(80);
    border-top: 2px solid #eeeeee;
    .loading-wrap {
      width: 100%;
      height: 100%;
    }
    .add {
      // text-decoration: underline;
      margin: auto;
    }
    /deep/ .van-search__content {
      padding-left: toMRem(6);
      background-color: #fff;
      .van-field__control {
        text-indent: 1em;
      }
      .search-icon {
        margin-top: toPad(2);
        width: toPad(18);
        height: toPad(18);
      }
      .clear-tag {
        position: relative;
        top: toPad(5);
        width: toPad(18);
        height: toPad(18);
        margin-right: toPad(8);
      }
      .search-btn {
        display: inline-block;
        padding: toPad(3) toPad(14);
        margin-right: toPad(8);
        border-radius: toPad(20);
        // border: 1px solid #3e886d;
        background: rgba($color: #3e886d, $alpha: 1);
        color: #fff;
      }
    }
    .no-ideas {
      flex-wrap: wrap;
      line-height: toPad(24);
      .icons {
        width: toPad(12);
        height: toPad(12);
        margin-right: toPad(8);
      }
      span {
        padding: 0 toPad(4);
        text-decoration: underline;
      }
    }
    .tag-title {
      background: #fff;
      padding: 0 toPad(20);
      .clear-tag {
        width: toPad(18);
        height: toPad(18);
      }
    }
    .user-wrap {
      padding-bottom: toPad(100);
      .user-title {
        padding: 0 toPad(16);
        background: rgba($color: #c9cece, $alpha: 0.2);
      }
      .line-manager {
        width: toPad(309);
        padding: toPad(10) 0 0;
        border-radius: toPad(16);
        box-shadow: 0px 4px 9px rgba(209, 209, 209, 0.25);
        .names {
          border-bottom: 1px solid #fff;
        }
      }
      .sub-user-title {
        padding-left: toPad(16);
      }
      /deep/ .el-submenu__title {
        height: toMRem(28);
        line-height: toMRem(28);
        padding-left: toPad(16) !important;
        padding: 0 !important;
        .el-submenu__icon-arrow {
          top: 30%;
          font-size: toPad(20);
        }
        /deep/.el-menu {
          padding: 0 !important;
          .el-menu-item {
            padding-left: 0 !important;
            padding: 0 !important;
            color: #000 !important;
            &.is-active {
              color: #000 !important;
            }
          }
          /deep/.el-submenu {
            padding: 0 !important;
            .el-menu-item {
              padding-left: 0 !important;
              padding: 0 !important;
              &.is-active {
                color: #000 !important;
              }
            }
          }
        }
      }
      /deep/.van-checkbox {
        padding-left: toPad(18);
        background-color: #fff;
        .img-icon {
          height: toPad(22);
          &.line-icon {
            position: relative;
            top: toPad(8);
          }
        }
        .line-title {
          margin-left: toPad(20);
        }
        .user-item {
          display: flex;
          align-items: center;
          margin-left: toPad(20);
          .avatar {
            width: toPad(45);
            height: toPad(45);
            border-radius: 50%;
          }
          .names {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 88vw;
            height: toPad(64);
            margin-left: toPad(12);
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          }
        }
      }
    }
    .subts-wrap {
      position: fixed;
      left: 0;
      bottom: toPad(10);
      width: 100%;
      .subts {
        display: block;
        width: toPad(167);
        height: toPad(42);
        line-height: toPad(30);
        margin: 0 auto;
        background: #3e886d;
        border-radius: toPad(81);
        box-shadow: 0px 15px 14px rgba(0, 0, 0, 0.15);
        &.disable {
          background: #c9cece;
        }
      }
    }
    .bot-wrap {
      display: flex;
      justify-content: center;
      position: fixed;
      left: 0;
      bottom: toPad(-140);
      width: 100%;
      height: toPad(210);
      padding: toPad(22) toPad(25);
      border-radius: toPad(36) toPad(36) 0 0;
      background: #ffffff;
      box-shadow: 0px -4px 9px rgba(191, 191, 191, 0.25);
      .tips {
        padding: toPad(15) 0;
        .tips-item {
          .check {
            width: toPad(13);
            height: toPad(13);
            margin-right: toPad(9);
            img {
              width: toPad(13);
              height: toPad(13);
            }
          }
        }
      }
      &.active {
        animation: hide 1s forwards;
      }
      &.bctive {
        animation: show 1s forwards;
      }
    }
    .buttons {
      position: fixed;
      left: 0;
      bottom: 0;
      align-items: center;
      width: 100%;
      padding: toPad(0) toPad(25) toPad(16);
      background-color: #fff;
      .nums {
        img {
          width: toPad(17);
          height: toPad(9);
          margin-left: toPad(10);
          &.active {
            transform: rotate(180deg);
          }
        }
      }
      .subt {
        position: relative;
        left: toPad(84);
        width: toPad(167);
        height: toPad(42);
        line-height: toPad(30);
        margin-right: 50%;
        background: #3e886d;
        border-radius: toPad(81);
        box-shadow: 0px 15px 14px rgba(0, 0, 0, 0.15);
        &.disable {
          background: #c9cece;
        }
      }
    }
  }
  @include keyframes(hide) {
    from {
      bottom: 0;
    }
    to {
      bottom: toPad(-200);
    }
  }
  @include keyframes(show) {
    from {
      bottom: toPad(-200);
    }
    to {
      bottom: 0;
    }
  }
}
</style>
