<template>
  <section class="enterDe-line">
    <section class="enterDe-state-content">
      <div class="enterDe-state-title">
        <label>Feedback result</label>

        <div class="enterDe-state-title-middle">
          <i class="el-icon-question qs5iclick"></i>

          <div class="businessUnit">
            <h3>5 Business Units:</h3>
            <ul>
              <li>JLR China</li>
              <li>IMSS</li>
              <li>Purchasing</li>
              <li>Product Engineering</li>
              <li>CJLR</li>
            </ul>
          </div>
        </div>

        <ul class="enterDe-state-title-control">
          <li
            v-for="(item, index) in roleList"
            :key="'roleFeed' + index"
            :class="{ selected: item.selected }"
            @click="roleChange_feed(item, index)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>

      <div class="enterDe-state-square-content">
        <!--fourSquare1-->
        <div class="enterDe-state-square enterDe-state-square-fourSquare">
          <section class="enterDe-feedback">
            <div class="enterDe-feedback-num">
              <em class="bg-yellow-s">{{
                pageData.receivedData.mainDataStr
              }}</em>
              <p>
                <span class="sp-long"
                  >Average received<br />feedbacks<br />
                  <span class="sp-long-small">*From valid report</span></span
                >
              </p>
            </div>

            <div class="enterDe-feedback-table">
              <label class="enterDe-feedback-table-label"
                >Received feedback ranking</label
              >
              <p class="enterDe-feedback-table-p">
                <em>Average (Internal/External)</em>
              </p>

              <div
                class="enterDe-feedback-table-line"
                v-for="(item, index) in pageData.receivedData
                  .feedBackResultDetails"
                :key="'feedt1' + index"
                v-show="dataType === 'evp' ? index < 3 : index < 5"
              >
                <label>
                  {{ item.deptName }}
                  ({{ item.scoreNum }})
                </label>

                <em
                  >{{ item.totalStr }}
                  <span class="enterDe-feedback-table-kh"
                    >({{ item.internalNumStr }}/{{ item.externalNumStr }})</span
                  ></em
                >

                <div class="enterDe-feedback-table-process">
                  <div
                    class="enterDe-feedback-table-process-in bg-green-s"
                    :style="
                      'width: ' +
                      (item.scoreNum / pageData.receivedData.scoreNum) * 100 +
                      '%'
                    "
                  ></div>
                </div>
              </div>

              <section
                class="evpMore"
                v-if="dataType === 'evp'"
                @click="evpMore('receivedData')"
              >
                More +
              </section>
            </div>
          </section>

          <section class="enterDe-feedback">
            <div class="enterDe-feedback-num">
              <em class="bg-green-s">{{
                pageData.responseData.mainDataStr
              }}</em>
              <p>
                <span class="sp-long">Feedback<br />response rate</span>
              </p>
            </div>

            <div class="enterDe-feedback-table">
              <label class="enterDe-feedback-table-label"
                >Response rate ranking</label
              >
              <p class="enterDe-feedback-table-p">
                <em>Rate (Internal/External)</em>
              </p>

              <div
                class="enterDe-feedback-table-line"
                v-for="(item, index) in pageData.responseData
                  .feedBackResultDetails"
                :key="'feedt2' + index"
                v-show="dataType === 'evp' ? index < 3 : index < 5"
              >
                <label>{{ item.deptName }}({{ item.scoreNum }})</label>

                <em>
                  {{ item.totalStr }}
                  <span class="enterDe-feedback-table-kh"
                    >({{
                      item.internalNumStr.indexOf("NaN") != -1
                        ? item.internalNumStr.replace("NaN", 0)
                        : item.internalNumStr
                    }}/{{
                      item.externalNumStr.indexOf("NaN") != -1
                        ? item.externalNumStr.replace("NaN", 0)
                        : item.externalNumStr
                    }})</span
                  ></em
                >

                <div class="enterDe-feedback-table-process">
                  <div
                    class="enterDe-feedback-table-process-in bg-green-s"
                    :style="
                      'width: ' +
                      (item.scoreNum / pageData.responseData.scoreNum) * 100 +
                      '%'
                    "
                  ></div>
                </div>
              </div>

              <section
                class="evpMore"
                v-if="dataType === 'evp'"
                @click="evpMore('responseData')"
              >
                More +
              </section>
            </div>
          </section>
        </div>
        <!--fourSquare1-end-->

        <!--fourSquare2-->
        <div class="enterDe-state-square enterDe-state-square-fourSquare">
          <section class="enterDe-feedback">
            <div class="enterDe-feedback-num">
              <em class="bg-lightGreen-s">{{
                pageData.issuedData.mainDataStr
              }}</em>
              <p>
                <span class="sp-long"
                  >Average issued<br />invitations<br />
                  <span class="sp-long-small">*From valid report</span>
                </span>
              </p>
            </div>

            <div class="enterDe-feedback-table">
              <label class="enterDe-feedback-table-label"
                >Issued invitation ranking</label
              >
              <p class="enterDe-feedback-table-p">
                <em>Average (Internal/External)</em>
              </p>

              <div
                class="enterDe-feedback-table-line"
                v-for="(item, index) in pageData.issuedData
                  .feedBackResultDetails"
                :key="'feedt3' + index"
                v-show="dataType === 'evp' ? index < 3 : index < 5"
              >
                <label>{{ item.deptName }}({{ item.scoreNum }})</label>

                <em>
                  {{ item.totalStr }}
                  <span class="enterDe-feedback-table-kh"
                    >({{ item.internalNumStr }}/{{ item.externalNumStr }})</span
                  ></em
                >

                <div class="enterDe-feedback-table-process">
                  <div
                    class="enterDe-feedback-table-process-in bg-green-s"
                    :style="
                      'width: ' +
                      (item.scoreNum / pageData.issuedData.scoreNum) * 100 +
                      '%'
                    "
                  ></div>
                </div>
              </div>

              <section
                class="evpMore"
                v-if="dataType === 'evp'"
                @click="evpMore('issuedData')"
              >
                More +
              </section>
            </div>
          </section>

          <section class="enterDe-feedback">
            <div class="enterDe-feedback-num">
              <em class="bg-lightGreenDeep-s">{{
                pageData.totalProactivelyData.mainDataStr
              }}</em>
              <p>
                <span class="sp-long"
                  >Average proactively<br />given feedbacks<br />
                  <span class="sp-long-small">*From valid report</span>
                </span>
              </p>
            </div>

            <div class="enterDe-feedback-table">
              <label class="enterDe-feedback-table-label"
                >Proactively given feedback</label
              >
              <p class="enterDe-feedback-table-p">
                <em>Average (Internal/External)</em>
              </p>

              <div
                class="enterDe-feedback-table-line"
                v-for="(item, index) in pageData.totalProactivelyData
                  .feedBackResultDetails"
                :key="'feedt1' + index"
                v-show="dataType === 'evp' ? index < 3 : index < 5"
              >
                <label>{{ item.deptName }}({{ item.scoreNum }})</label>

                <em>
                  {{ item.totalStr }}
                  <span class="enterDe-feedback-table-kh"
                    >({{ item.internalNumStr }}/{{ item.externalNumStr }})</span
                  >
                </em>

                <div class="enterDe-feedback-table-process">
                  <div
                    class="enterDe-feedback-table-process-in bg-green-s"
                    :style="
                      'width: ' +
                      (item.scoreNum / pageData.totalProactivelyData.scoreNum) *
                        100 +
                      '%'
                    "
                  ></div>
                </div>
              </div>

              <section
                class="evpMore"
                v-if="dataType === 'evp'"
                @click="evpMore('totalProactivelyData')"
              >
                More +
              </section>
            </div>
          </section>
        </div>
        <!--fourSquare2-end-->
      </div>
    </section>

    <van-dialog
      v-model="evp_show"
      :showConfirmButton="false"
      className="evp-feed-dialog"
    >
      <section class="evp-feed-dialog-in">
        <i
          class="el-icon-close evp-feed-dialog-close"
          @click="evp_show = false"
        ></i>

        <div class="enterDe-feedback-table">
          <label class="enterDe-feedback-table-label">
            <span v-if="dialogData.type == 1">Received feedback ranking</span>
            <span v-if="dialogData.type == 2">Issued invitation ranking</span>
            <span v-if="dialogData.type == 3">Response rate ranking</span>
            <span v-if="dialogData.type == 4"
              >Total proactively given feedback ranking</span
            >
          </label>

          <p class="enterDe-feedback-table-p">
            <em v-if="dialogData.type == 1">Average (Internal/External)</em>
            <em v-if="dialogData.type == 2">Average (Internal/External)</em>
            <em v-if="dialogData.type == 3">Rate (Internal/External)</em>
            <em v-if="dialogData.type == 4"
              >Average<span>(Internal/External)</span></em
            >
          </p>

          <section class="evp-feed-dialog-scroll">
            <div
              class="enterDe-feedback-table-line"
              v-for="(item, index) in dialogData.feedBackResultDetails"
              :key="'feedtDia' + index"
            >
              <label>{{ item.deptName }}({{ item.scoreNum }})</label>

              <em>
                {{ item.totalStr }}
                <span class="enterDe-feedback-table-kh"
                  >({{
                    item.internalNumStr.indexOf("NaN") != -1
                      ? item.internalNumStr.replace("NaN", 0)
                      : item.internalNumStr
                  }}/{{
                    item.externalNumStr.indexOf("NaN") != -1
                      ? item.externalNumStr.replace("NaN", 0)
                      : item.externalNumStr
                  }})</span
                >
              </em>

              <div class="enterDe-feedback-table-process">
                <div
                  class="enterDe-feedback-table-process-in bg-green-s"
                  :style="
                    'width: ' +
                    (item.scoreNum / pageData.responseData.scoreNum) * 100 +
                    '%'
                  "
                ></div>
              </div>
            </div>
          </section>

          <div
            class="enterDe-feedback-table-process-close"
            @click="evp_show = false"
          >
            Close -
          </div>
        </div>
      </section>
    </van-dialog>
  </section>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      evp_show: false,
      dataType: "",
      roleList: [
        { name: "Business Unit", type: "bu", selected: true },
        { name: "EVP", type: "evp" },
      ],
      pageData: {
        receivedData: {},
        issuedData: {},
        responseData: {},
        totalProactivelyData: {},
      },
      dialogData: {},
    };
  },
  mounted() {
    this.pageInit();
  },
  methods: {
    roleChange_feed(data, index) {
      this.roleList.forEach((val) => {
        this.$set(val, "selected", false);
      });

      this.$set(this.roleList[index], "selected", true);
      this.dataType = data.type;
      this.pageInit(data.type);
    },
    pageInit(type) {
      let initdata = [];
      if (type === "evp") {
        initdata = this.data.feedBackEvpResult;
      } else {
        initdata = this.data.feedBackBuResult;
      }

      initdata.forEach((val) => {
        if (val.type == 1) {
          this.$set(this.pageData, "receivedData", val);
        }
        if (val.type == 2) {
          this.$set(this.pageData, "issuedData", val);
        }
        if (val.type == 3) {
          this.$set(this.pageData, "responseData", val);
        }
        if (val.type == 4) {
          this.$set(this.pageData, "totalProactivelyData", val);
        }
      });
    },
    evpMore(dataName) {
      this.dialogData = this.pageData[dataName];
      this.evp_show = true;
      setTimeout(() => {
        $(".evp-feed-dialog-scroll").scrollTop(0);
      }, 100);
    },
  },
};
</script>

<style></style>
