<template>
  <div>
    <p class="mb16">
      If you want a deep dive of your report, there are 3 kinds of analysis:
    </p>
    <div class="mb16">
      <h3 class="tit mb10">
        <span class="icon">
          <img src="@/src/assets/report/feedback/comments_1.png" alt="" />
        </span>

        <span>EVP+ analysis</span>
      </h3>
      <p>
        You can select an EVP and above to send an application email. He or She
        will contact you can set up the analysis meeting.
      </p>
    </div>
    <div class="mb16">
      <h3 class="tit mb10">
        <span class="icon" style="background: #86bab4">
          <img src="@/src/assets/report/feedback/comments_2.png" alt="" />
        </span>
        <span>Line manager analysis</span>
      </h3>
      <p>
        You can reach your line manager for an analysis via sending an email.
      </p>
    </div>
    <div class="mb16">
      <h3 class="tit mb10">
        <span class="icon" style="background: #86b1ba">
          <img src="@/src/assets/report/feedback/comments_3.png" alt="" />
        </span>
        <span>Expert analysis</span>
      </h3>
      <p>
        You can send an email to 360 Feedback supporting mailbox for<br />
        · System questions<br />
        · HR questions<br />
        And the expert will contact you later.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "talkTo",
  data() {
    return {
      // leftStyle: 0,
    };
  },
  mounted() {
    // console.log(this.props.beatsPercentage);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/variable";
@media screen and (max-width: 760px) {
  .tit {
    display: flex;
    justify-content: flex-start;
  }
  .icon {
    display: flex;
    background: #e3c16f;
    border-radius: 50%;
    margin-right: toMRem(10);
    width: toMRem(26);
    min-width: toMRem(26);
    height: toMRem(26);
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 761px) {
  .tit {
    display: flex;
    justify-content: flex-start;
  }
  .icon {
    display: flex;
    background: #e3c16f;
    border-radius: 50%;
    margin-right: toPad(10);
    width: toPad(26);
    min-width: toPad(26);
    height: toPad(26);
    justify-content: center;
    align-items: center;
  }
}
</style>
