var apiList = {
  reportAssess: "api/review/appraise-service/v1/report/assess",
  reportReceived: "api/review/appraise-service/v1/report/received",
  reportDiagram: "api/review/appraise-service/v1/report/diagram",
  reportComments: "api/review/appraise-service/v1/report/comments",
  boardIndividual: "api/review/appraise-service/v1/report/rankingList",
  boardDepartment: "api/review/appraise-service/v1/report/dep/ranking",

  homePage: "api/review/appraise-service/v1/homepage",
  userList: "api/review/appraise-service/v1/user/v2/list",
  tagNum: "api/review/appraise-service/v1/user/status",
  inviteNum: "api/review/appraise-service/v1/invite/status",
  inviteSubmit: "api/review/appraise-service/v1/invite/submit",
  inviteEmail: "api/review/appraise-service/v1/invite/bat",
  activeAssess: "api/review/appraise-service/v1/active-assess",

  questionList: "api/review/appraise-service/v1/survey/model",
  submit_question: "api/review/appraise-service/v1/survey/result",

  getEmailCode: "api/user/sys/send_emailcode",
  login: "api/user/sys/login",

  indvList: "api/review/appraise-service/v1/report/checkUserStandards",
  indQesRank: "api/review/appraise-service/v1/report/ranking",
  indExport: "api/review/appraise-service/v1/report/export",

  departPersonData: "api/review/appraise-service/v1/report/dep/userNum",
  departWeReceived: "api/review/appraise-service/v1/report/dep/received",
  departWeGive: "api/review/appraise-service/v1/report/dep/assess",
  isEnterPrise: "api/review/appraise-service/v1/report/auth/enterprise",
  queryDept: "api/review/appraise-service/v1/report/search/department",
  queryUser: "api/review/appraise-service/v1/report/search/user",
  evpList: "api/review/appraise-service/v1/user/evp",
  evpAnalys: "api/review/appraise-service/v1/report/analysis/evp",
  linemanagerAnalys:
    "api/review/appraise-service/v1/report/analysis/linemanager",
  hrAnalys: "api/review/appraise-service/v1/report/analysis/hr",
  scoreDistribute: "api/review/appraise-service/v1/report/score/distribute",

  epTop: "api/review/appraise-service/v1/report/enterprise",
  departZwt: "api/review/appraise-service/v1/report/dep/diagram",
  departRank: "api/review/appraise-service/v1/report/dep/ranking",
  departHistogram: "api/review/appraise-service/v1/report/dep/histogram",
  emailInfo: "api/review/appraise-service/v1/user/email",
  currentFeedback: "api/review/appraise-service/v1/feedback/currentFeedback",
  addFeedback: "api/review/appraise-service/v1/feedback/addFeedback",
};

var beforeSign = "";

process.env.NODE_ENV === "development"
  ? (beforeSign = "api/")
  : (beforeSign = process.env.VUE_APP_BASE_PATH);

for (var key in apiList) {
  let oldval = apiList[key];

  apiList[key] = beforeSign + "" + oldval;
}

export default apiList;
