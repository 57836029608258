<template>
  <div class="enterDe-all">
    <div v-if="pageIf">
      <template v-if="hideMenu">
        <commentTopControl
          :title="headerTitle"
          :goBack="true"
          v-if="!pdfStatus"
        >
          <div class="print-area" slot="right">
            <span class="print-btn" @click="printPage"
              ><img src="@/src/assets/save-picture.png" alt=""
            /></span>
          </div>
        </commentTopControl>
      </template>
      <div class="enterDe-content" :style="!hideMenu ? 'padding-top:0;' : ''">
        <topContent @emitData="emitDataFn" />

        <departWe />

        <departscore ref="departscore" :cmpData="outEmitData" />

        <section class="enterDe-line">
          <section class="enterDe-state-content"><departRank /></section>

          <section class="enterDe-state-content span2">
            <distribution @emitQesList="emitQesListFn" />
          </section>
        </section>
      </div>

      <reportSidebar v-if="emailVipStatus"></reportSidebar>
    </div>

    <van-overlay
      :show="subLoading"
      z-index="120"
      :custom-style="{ background: 'rgba(0,0,0,0.2)' }"
    >
      <div class="loading-wrap-common fjc">
        <van-loading size="50px" color="#3E886D" text-color="#3E886D" vertical
          >&nbsp;</van-loading
        >
      </div>
    </van-overlay>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { mapState, mapMutations, mapActions } from "vuex";
import commentTopControl from "@/src/components/comment/commentTopControl.vue";
import topContent from "@/src/components/enterDe/topContent.vue";
import departWe from "@/src/components/enterDe/departWe.vue";
import departscore from "@/src/components/enterDe/departscore.vue";

import departRank from "@/src/components/enterDe/departRank.vue";
import distribution from "@/src/components/enterDe/distribution.vue";

import reportSidebar from "@/src/components/reportSidebar";

export default {
  components: {
    reportSidebar,
    commentTopControl,
    topContent,
    departWe,
    departscore,
    departRank,
    distribution,
  },
  watch: {
    $route(val, oldVal) {
      if (val.query.dictId != oldVal.query.dictId) {
        this.pageIf = false;

        setTimeout(() => {
          this.pageIf = true;

          this.headerTitle =
            "Department" +
            (this.$route.query.Name ? "_" + this.$route.query.Name : "");
        }, 100);
      }
    },
  },
  computed: {
    ...mapState({
      emailOut: (state) => state.comment.emailOut,
      emailVipStatus: (state) => state.comment.emailVipStatus,
    }),
  },
  data() {
    return {
      hideMenu: true,
      pdfStatus: false,
      subLoading: false,
      pageIf: true,
      headerTitle: "",
      outEmitData: {},
    };
  },
  mounted() {
    if (this.$route.query.menu == "hide") {
      this.hideMenu = false;
    }
    this.headerTitle =
      "Department" +
      (this.$route.query.Name ? "_" + this.$route.query.Name : "");

    $(window).scrollTop(0);
  },
  methods: {
    emitDataFn(data) {
      this.outEmitData = data;
    },
    emitQesListFn() {
      this.$refs.departscore.questionWordList_show = true;
    },
    printPage() {
      const loading = this.$loading({
        lock: true,
        text: "Ready for printing...",
        background: "rgba(0, 0, 0, 0.7)",
        body: true,
      });

      this.pdfStatus = true;

      $('<div class="waterSign" id="waterSign"></div>')
        .css({ height: $(".enterDe-all").height() })
        .appendTo(".enterDe-all");

      for (let items = 0; items <= 33; items++) {
        $('<div class="waterSign-in">' + this.emailOut + "</div>").appendTo(
          "#waterSign"
        );
      }

      setTimeout(() => {
        html2canvas(document.querySelector(".enterDe-all")).then((canvas) => {
          let base = canvas.toDataURL("image/jpeg");

          let pdf = new jsPDF("", "pt", "a4");

          let pdfWidth = 592,
            pdfHeight = 841,
            docWidth = parseInt($(".enterDe-all").width()),
            docHeight = parseInt($(".enterDe-all").height()),
            biImg = pdfHeight / docHeight;

          let dbWidth = docWidth * biImg,
            dbHeight = pdfHeight;
          let dbWidth_other = 1;

          if (dbWidth > 592) {
            dbWidth_other = 592 / dbWidth;
            dbWidth = dbWidth * dbWidth_other;
            dbHeight = dbHeight * dbWidth_other;
          }

          pdf.addImage(
            base,
            "jpeg",
            (pdfWidth - dbWidth) / 2,
            0,
            dbWidth,
            dbHeight
          ); //592.28, 841.89

          pdf.save("JLR.pdf");

          setTimeout(() => {
            this.pdfStatus = false;

            $("#waterSign").remove();

            loading.close();
          }, 1000);
        });
      }, 2000);
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/enterDe.scss";
</style>
