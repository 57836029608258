<template>
  <div class="emails">
    <commentTopControl :title="headerTitle" :goBack="true"> </commentTopControl>
    <el-form ref="form" class="forms" :model="form" label-width="0px">
      <el-form-item
        v-for="(item, index) in form.emails"
        label=""
        :key="index"
        :prop="'emails.' + index + '.value'"
        :rules="[
          { required: true, message: 'Please type', trigger: 'blur' },
          {
            type: 'email',
            message: 'Invalid Email',
            trigger: ['blur'],
          },
        ]"
      >
        <el-input
          class="email"
          type="email"
          v-model="item.value"
          clearable
        ></el-input>
        <img
          v-show="index > 0"
          class="delete-email hover"
          @click="remove(item)"
          src="../../assets/delete.png"
          alt=""
        />
      </el-form-item>
    </el-form>
    <!-- <input
      class="email tin1"
      type="text"
      v-model="item.value"
      v-for="(item, index) in emails"
      :key="index"
    /> -->
    <span class="cg f14 fb tin1 pt10 hover" @click="add">Add to +</span>
    <div class="subt-wrap">
      <button class="subt f14 cf" @click="submit">Submit</button>
    </div>
  </div>
</template>
<script>
import commentTopControl from "@/src/components/comment/commentTopControl.vue";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "addEmail",
  components: {
    commentTopControl,
  },
  data() {
    return {
      headerTitle: "Invite by Email",
      form: {
        emails: [{ value: "" }],
      },
    };
  },
  mounted() {
    // document.title = "Invite by Email";
  },
  methods: {
    ...mapActions({
      inviteEmail: "invite/inviteEmail",
    }),
    submit() {
      let arrs = this.form.emails.map((e) => {
        return e.value;
      });
      if (new Set(arrs).size != arrs.length) {
        this.$toast("The email address has been reused, please check");
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$dialog
            .alert({
              title: "Confirm",
              message: "Cannot be modified after the submission.",
              theme: "round-button",
              showCancelButton: true,
              confirmButtonText: "Confirm",
              confirmButtonColor: "#3E886D",
              cancelButtonText: "Rethink",
              cancelButtonColor: "#D8DFE2",
            })
            .then(() => {
              let params = {
                inviteeEmails: arrs,
                surveyModelCode: "JLRCFBM000001",
              };

              this.inviteEmail(params).then((res) => {
                if (res.data.code === "0") {
                  this.$toast({
                    message: `<div><p style="font-size:14px">Well done！</p><p style="font-size:12px">New emails added.</p></div>`,
                    type: "html",
                  });
                  setTimeout(() => {
                    this.$router.go(-1);
                  }, 1500);
                }
              });
            })
            .catch(() => {
              // on cancel
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    add() {
      this.form.emails.push({ value: "" });
    },
    remove(item) {
      var index = this.form.emails.indexOf(item);
      if (index !== -1) {
        this.form.emails.splice(index, 1);
      }
    },
    linkTo(val) {
      this.$router.push(val);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variable";
@media screen and (max-width: 760px) {
  .emails {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: toMRem(80) toMRem(28) toMRem(130);
    background: #f7f7f7;
    .email {
      width: toMRem(290);
      height: toMRem(46);
      margin-top: toMRem(10);
      background: #ffffff;
      // border: 1px solid #cecece;
      // box-sizing: border-box;
      border-radius: toMRem(8);
      /deep/.el-input__inner {
        height: 100%;
      }
    }
    .delete-email {
      position: relative;
      top: toMRem(6);
      margin-left: toMRem(6);
      width: toMRem(21);
      height: toMRem(21);
    }
    .subt {
      position: fixed;
      left: toMRem(118);
      bottom: toMRem(75);
      width: toMRem(138);
      height: toMRem(42);
      line-height: toMRem(30);
      background: #3e886d;
      border-radius: toMRem(81);
    }
  }
}
@media screen and (min-width: 761px) {
  .emails {
    display: flex;
    flex-direction: column;
    // align-items: center;
    position: relative;
    width: toPad(418);
    min-height: 100vh;
    margin: 0 auto;
    padding: toPad(100) toPad(28) toPad(130);
    background: #f7f7f7;
    .forms {
      width: 100%;
      text-align: center;
      .email {
        float: left;
        width: toPad(318);
        height: toPad(46);
        margin-top: toPad(10);
        background: #ffffff;
        // border: 1px solid #cecece;
        // box-sizing: border-box;
        border-radius: toPad(8);
        /deep/.el-input__inner {
          height: 100%;
        }
      }
      .delete-email {
        position: relative;
        top: toPad(20);
        margin-left: toPad(6);
        width: toPad(21);
        height: toPad(21);
      }
    }
    .subt-wrap {
      width: 100%;
      position: fixed;
      left: 0;
      bottom: toPad(75);
      text-align: center;
    }
    .subt {
      width: toPad(158);
      height: toPad(42);
      line-height: toPad(30);
      background: #3e886d;
      border-radius: toPad(81);
    }
  }
}
</style>
