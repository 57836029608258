import axios from "axios";
import { Toast } from "vant";
import router from "../router";
import store from "../store/store";

var xslxList = ["v1/report/export"];

axios.interceptors.request.use(
  (config) => {
    const { url } = config;

    config.headers["x-user-token"] = !store.state.comment.isInivte
      ? localStorage.getItem("token")
      : "";

    let xslxStatus = xslxList.some((valX) => {
      return url.includes(valX);
    });

    if (xslxStatus) {
      config.responseType = "blob";
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (!store.state.comment.checkMail) {
      if (response.data.code === "050900" || response.data.code === "050305") {
        router.push({
          name: "login",
        });
      } else {
        if (
          !response.data.success &&
          response.data.type != "application/vnd.ms-excel"
        ) {
          Toast(
            response.data.message || response.data.msg || "Under construction"
          );
        }
      }
    }

    return response;
  },
  (error) => {
    const { response } = error;
    if (!store.state.comment.checkMail) {
      if (response.data.code === "050900" || response.data.code === "050305") {
        Toast("Login expired");
        router.push({
          name: "login",
        });
      } else {
        Toast(
          response.data.message || response.data.msg || "Under construction"
        );
      }
    }

    return Promise.reject(error);
  }
);

export default axios;
