const state = {};

const mutations = {};

const actions = {
  async epTop({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.epTop, {
      params: data,
    });

    return res;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
