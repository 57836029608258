const state = {};

const mutations = {};

const actions = {
  async indvList({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.indvList, data);

    return res;
  },
  async indQesRank({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.indQesRank, {
      params: data,
    });

    return res;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
