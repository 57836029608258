<template>
  <div>
    <section class="enterDe-line">
      <section class="enterDe-state-content">
        <div class="enterDe-state-title"><label>We received</label></div>

        <!--We received-->
        <div class="enterDe-state-square-content">
          <div class="departWe-total">
            <section class="departWe-total-in">
              <em>{{ weRc_large }}</em>

              <label>Feedback<br />received</label>
            </section>

            <section class="departWe-total-in departWe-total-in-sp">
              <em>{{ weRc_small }}</em>

              <label
                >Invitation<br />
                sent</label
              >

              <p>{{ weRc_percent }}</p>
            </section>

            <section class="departWe-total-line"></section>
          </div>

          <div class="departWe-detail">
            <section class="departWe-detail-line">
              <label>Proactively received</label>
              <em class="depart-green1">{{ weRc_proactivelyReceived }}</em>
            </section>

            <section class="departWe-detail-line">
              <label>Total received</label>
              <em class="depart-green2">{{ weRc_totalReceived }}</em>
            </section>

            <section class="departWe-detail-line">
              <label>Average received ranking</label>
              <em class="depart-green2">{{ weRc_avgNew }}</em>
            </section>

            <section class="departWe-detail-rankProcess">
              <div class="departWe-detail-rankProcess-line depart-bg-lineGreen">
                <span
                  class="departWe-rg-no"
                  :style="
                    'left: ' +
                    parseInt((weRc_rank / weRc_userTotal) * 100) +
                    '%'
                  "
                >
                  <span class="departWe-rg-word">NO.{{ weRc_rank }}</span>

                  <i class="el-icon-caret-bottom departWe-rg-icon"></i>

                  <span class="departWe-rg-line"></span>
                </span>
              </div>

              <span class="departWe-detail-rankProcess-word dd-sp1">1</span>
              <span class="departWe-detail-rankProcess-word dd-sp2">{{
                weRc_userTotal
              }}</span>

              <div class="departWe-detail-rankProcess-bottom">
                Beats
                <span class="depart-green2">{{ weRc_overPercent }}</span> of
                departments
              </div>
            </section>
          </div>
        </div>
        <!--We received end-->
      </section>

      <section class="enterDe-state-content">
        <div class="enterDe-state-title"><label>We gave</label></div>

        <!--We gave-->
        <div class="enterDe-state-square-content">
          <div class="departWe-total">
            <section class="departWe-total-in">
              <em class="depart-bg-blue-large">{{ weGi_large }}</em>

              <label>Feedback<br />given</label>
            </section>

            <section class="departWe-total-in departWe-total-in-sp">
              <em>{{ weGi_small }}</em>

              <label
                >Feedback<br />
                requested</label
              >

              <p>{{ weGi_percent }}</p>
            </section>

            <section class="departWe-total-line"></section>
          </div>

          <div class="departWe-detail">
            <section class="departWe-detail-line">
              <label>Proactively given</label>
              <em class="depart-blue1">{{ weGi_proactivelyReceived }}</em>
            </section>

            <section class="departWe-detail-line">
              <label>Total gave</label>
              <em class="depart-blue2">{{ weGi_totalReceived }}</em>
            </section>

            <section class="departWe-detail-line">
              <label>Average given ranking</label>
              <em class="depart-blue2">{{ weGi_avgNew }}</em>
            </section>

            <section class="departWe-detail-rankProcess">
              <div class="departWe-detail-rankProcess-line depart-bg-lineBlue">
                <span
                  class="departWe-rg-no"
                  :style="
                    'left: ' +
                    parseInt((weGi_rank / weGi_userTotal) * 100) +
                    '%'
                  "
                >
                  <span class="departWe-rg-word">NO.{{ weGi_rank }}</span>

                  <i class="el-icon-caret-bottom departWe-rg-icon"></i>

                  <span class="departWe-rg-line"></span>
                </span>
              </div>

              <span class="departWe-detail-rankProcess-word dd-sp1">1</span>
              <span class="departWe-detail-rankProcess-word dd-sp2">{{
                weGi_userTotal
              }}</span>

              <div class="departWe-detail-rankProcess-bottom">
                Beats
                <span class="depart-blue2">{{ weGi_overPercent }}</span> of
                departments
              </div>
            </section>
          </div>
        </div>
        <!--We gave end-->
      </section>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      dictId: this.$route.query.dictId,
      receivedRank: "",

      weRc_large: "",
      weRc_small: "",
      weRc_percent: "",
      weRc_rank: "",
      weRc_userTotal: "",
      weRc_overPercent: "",
      weRc_proactivelyReceived: "",
      weRc_totalReceived: "",
      weRc_avgNew: "",

      weGi_large: "",
      weGi_small: "",
      weGi_percent: "",
      weGi_rank: "",
      weGi_userTotal: "",
      weGi_overPercent: "",
      weGi_proactivelyReceived: "",
      weGi_totalReceived: "",
      weGi_avgNew: "",
    };
  },
  methods: {
    ...mapActions({
      departWeReceived: "depart/departWeReceived",
      departWeGive: "depart/departWeGive",
    }),
    async pageInit() {
      let params = {
        dictId: this.dictId,
      };
      let res = await this.departWeReceived(params);

      let res2 = await this.departWeGive(params);

      this.weRc_large = res.data.data.inviteAssessFromNum;
      this.weRc_small = res.data.data.inviteNum;
      this.weRc_percent = res.data.data.inviteReplyPercentage;
      this.weRc_rank = res.data.data.ranking;
      this.weRc_userTotal = res.data.data.userTotal;
      this.weRc_overPercent = res.data.data.beatsPercentage;
      this.weRc_proactivelyReceived = res.data.data.activeAssessFromNum;
      this.weRc_totalReceived = res.data.data.assessFromNum;
      this.weRc_avgNew = res.data.data.avgReceived;

      this.weGi_large = res2.data.data.inviteReplyFromNum;
      this.weGi_small = res2.data.data.inviteFromNum;
      this.weGi_percent = res2.data.data.assessReplyPercentage;
      this.weGi_rank = res2.data.data.ranking;
      this.weGi_userTotal = res2.data.data.userTotal;
      this.weGi_overPercent = res2.data.data.beatsPercentage;
      this.weGi_proactivelyReceived = res2.data.data.activeAssessNum;
      this.weGi_totalReceived = res2.data.data.assessNum;
      this.weGi_avgNew = res2.data.data.avgGiven;
    },
  },
  created() {
    this.pageInit();
  },
};
</script>

<style></style>
