<template>
  <div>
    <section class="commentTopControl" :class="[{ 'print-mode': printMode }]">
      <div class="commentTop-menu" :class="{ opacity0: isApp }">
        <div v-if="backHide">&nbsp;</div>

        <div v-else class="commentTop-menu-content">
          <slot name="left" v-if="leftSlot"></slot>
          <i class="el-icon-arrow-left" @click="goFuntion" v-else></i>
        </div>
      </div>

      <div class="commentTop-text">{{ title }}</div>

      <div class="commentTop-img">
        <slot name="right"></slot>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  props: ["title", "leftSlot", "goBack", "backHide", "printMode"],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      isApp: (state) => state.comment.isApp,
    }),
  },
  methods: {
    goFuntion() {
      if (this.goBack) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: "invitaHome" });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable";

.opacity0 {
  opacity: 0 !important;
}
.commentTopControl {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 105;
  &.print-mode {
    position: static;
  }
}
.print-area {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  .print-btn {
    display: inline-block;
    cursor: pointer;

    img {
      width: 100% !important;
      height: auto !important;
    }

    &:first-child {
      margin-left: 0 !important;
    }
    &:last-child {
      margin-right: 0 !important;
    }
  }
}
@media screen and (max-width: 760px) {
  .print-area {
    .print-btn {
      width: toMRem(26);
      margin: 0 toMRem(6);
    }
  }
  .commentTopControl {
    padding: toMRem(8) toMRem(20);
    background: #fff;

    .commentTop-menu {
      display: flex;
      min-width: toMRem(40);
      align-items: center;

      .commentTop-menu-content {
        text-align: left;

        i {
          font-size: toMRem(20);
          cursor: pointer;
        }
      }
    }

    .commentTop-text {
      text-align: center;
      font-size: toMRem(18);
      flex-shrink: 0;
      max-width: 60%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .commentTop-img {
      display: flex;
      min-width: toMRem(40);
      height: toMRem(40);
      overflow: hidden;
      align-items: center;
      justify-content: flex-end;

      img {
        display: block;
        width: toMRem(40);
        height: auto;
      }
    }
  }
}
@media screen and (min-width: 761px) {
  .print-area {
    .print-btn {
      width: toPad(26);
      margin: 0 toPad(10);
    }
  }
  .commentTopControl {
    padding: toPad(20);
    background: #fff;

    .commentTop-menu {
      display: flex;
      min-width: toPad(40);
      align-items: center;

      .commentTop-menu-content {
        text-align: left;

        i {
          font-size: toPad(20);
          cursor: pointer;
        }
      }
    }

    .commentTop-text {
      text-align: center;
      font-size: toPad(20);
      max-width: 60%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .commentTop-img {
      display: flex;
      min-width: toPad(40);
      height: toPad(40);
      overflow: hidden;
      align-items: center;
      flex-shrink: 0;

      img {
        display: block;
        width: toPad(40);
        height: auto;
      }
    }
  }
}
</style>
