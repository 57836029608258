<template>
  <section class="enterDe-line" style="z-index: 5">
    <section class="enterDe-state-content span2 escs-pr0">
      <div class="enterDe-state-title"><label>Validity statement</label></div>

      <div class="enterDe-state-square-content">
        <div class="enterDe-state-square">
          <div class="enterDe-state-square-word">Total<br />member</div>

          <em class="enterDe-state-square-crNum bg-green-s">{{
            totalMemer
          }}</em>
        </div>

        <div class="enterDe-state-square">
          <div class="enterDe-state-square-word">Valid<br />member</div>

          <em class="enterDe-state-square-crNum bg-lightGreen-s">{{
            validFeedbacks
          }}</em>
        </div>

        <div class="enterDe-state-square">
          <div class="enterDe-state-square-word">Invalid<br />member</div>

          <div
            class="enterDe-state-square-crNum bg-pink-s"
            style="cursor: pointer"
            @click="topContentTipsFn"
          >
            {{ invalidFeedbacks }}

            <section class="topContent-dialog-tips" v-if="topContentTipsShow">
              <img
                src="@/src/assets/comment/top-contentIcon.png"
                alt=""
                class="topContent-dialog-tips-icon"
              />

              <div class="topContent-dialog-tips-content">
                <section class="topContent-dialog-tips-content-title">
                  <div class="tdtct-title">Invalid member</div>

                  <i class="el-icon-close" @click="close_topContentTips"></i>
                </section>

                <section class="topContent-dialog-tips-content-list">
                  <ul>
                    <li
                      v-for="(itemp, indexp) in indUserList"
                      :key="'indUserList' + indexp"
                    >
                      <div class="tdtcl-name">{{ itemp.userNameEN }}</div>

                      <div class="topContent-dialog-tips-lk">
                        <div class="topContent-dialog-tips-lk-left">
                          <i
                            class="el-icon-close"
                            v-if="itemp.reasons && itemp.reasons.length > 0"
                          ></i>

                          <i class="el-icon-info el-ifoption" v-else></i>
                        </div>

                        <div class="topContent-dialog-tips-lk-right">
                          <div v-if="itemp.reasons && itemp.reasons.length > 0">
                            <p v-if="itemp.reasons.includes(1)">Line Manager</p>

                            <p v-if="itemp.reasons.includes(3)">
                              At least 2 colleague who is more senior (higher
                              grade)
                            </p>

                            <p v-if="itemp.reasons.includes(2)">
                              At least 2 cross-functional colleague (Within
                              enterprise)
                            </p>

                            <p v-if="itemp.reasons.includes(4)">
                              At least 2 colleague who is a peer (same grade)
                            </p>

                            <p v-if="itemp.reasons.includes(5)">
                              At least 1 member of your team who reports to you
                              (if applicable)
                            </p>

                            <p v-if="itemp.reasons.includes(6)">
                              Receive at least 8 feedbacks
                            </p>
                          </div>

                          <div v-else><p>Optional</p></div>

                          <button @click="individualPage(itemp)">
                            Individual report
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </section>
              </div>
            </section>
          </div>
        </div>

        <div class="enterDe-state-square" v-if="$route.name == 'department'">
          <div class="enterDe-state-square-word">New comers</div>

          <em
            class="enterDe-state-square-crNum bg-yellow-s"
            style="cursor: pointer"
            @click="participateEmployeeTipsFn"
            >{{ participateEmployee }}</em
          >
        </div>
      </div>
    </section>

    <!--<section class="enterDe-state-content" v-if="$route.name == 'department'">
      <div class="enterDe-state-title"><label>Optional</label></div>

      <div class="enterDe-state-square-content">
        <div class="enterDe-state-square enterDe-state-square-dl">
          <div class="enterDe-state-square-word">New comers</div>

          <em
            class="enterDe-state-square-crNum bg-yellow-s"
            style="cursor: pointer"
            @click="participateEmployeeTipsFn"
            >{{ participateEmployee }}</em
          >
        </div>
      </div>
    </section>-->

    <van-dialog
      v-model="opList_show"
      :showConfirmButton="false"
      className="evp-feed-dialog"
    >
      <section class="evp-feed-dialog-in">
        <i
          class="el-icon-close evp-feed-dialog-close"
          @click="opList_show = false"
        ></i>

        <div class="enterDe-feedback-table">
          <label class="enterDe-feedback-table-label">Optional</label>

          <section class="opList-content">
            <dl
              v-for="(item, index) in optionalList"
              :key="'optionalList' + index"
            >
              <dt @click="showOplist(index)">
                <em>{{ item.depName }}</em>
                <i class="el-icon-plus" v-if="!item.selected"></i>
                <i class="el-icon-minus" v-if="item.selected"></i>
              </dt>

              <dd
                v-for="(item2, index2) in item.inList"
                :key="'optionalListIn' + index2"
                v-show="item.selected"
              >
                {{ item2.userName }}

                <button @click="individualPageOp(item2)">
                  Individual report
                </button>
              </dd>
            </dl>
          </section>
        </div>
      </section>
    </van-dialog>

    <van-overlay
      :show="subLoading"
      z-index="120"
      :custom-style="{ background: 'rgba(0,0,0,0.2)' }"
    >
      <div class="loading-wrap-common fjc">
        <van-loading size="50px" color="#3E886D" text-color="#3E886D" vertical
          >&nbsp;</van-loading
        >
      </div>
    </van-overlay>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  props: ["data", "data2"],
  data() {
    return {
      subLoading: false,
      dictId: this.$route.query.dictId ? this.$route.query.dictId : "",
      topContentTipsShow: false,
      totalMemer: "",
      validFeedbacks: "",
      invalidFeedbacks: "",
      participateEmployee: "",
      indUserList: [],
      optionalList: [],
      opList_show: false,
    };
  },
  methods: {
    ...mapActions({
      departPersonData: "depart/departPersonData",
      indvList: "indv/indvList",
    }),
    close_topContentTips(e) {
      e.stopPropagation();

      this.topContentTipsShow = false;
    },
    topContentTipsFn() {
      if (this.indUserList.length > 0) {
        this.topContentTipsShow = true;
      }
    },
    participateEmployeeTipsFn() {
      if (this.optionalList.length > 0) {
        this.opList_show = true;
      }
    },
    async pageInit() {
      if (this.$route.name === "department") {
        this.subLoading = true;

        let params = {
          dictId: this.dictId,
        };
        let res = await this.departPersonData(params);

        this.totalMemer = res.data.data.total || 0;
        this.validFeedbacks = res.data.data.normalNum || 0;
        this.invalidFeedbacks = res.data.data.invalidNum || 0;
        this.participateEmployee = res.data.data.newNum || 0;

        let emitData = {
          validFeedbacks: this.validFeedbacks,
        };
        this.$emit("emitData", emitData);

        if (
          res &&
          res.data &&
          res.data.data &&
          res.data.data.invalidUserIds &&
          res.data.data.invalidUserIds.length > 0
        ) {
          let params2 = res.data.data.invalidUserIds;
          let res2 = await this.indvList(params2);

          this.indUserList = res2.data.data;
        }

        if (
          res &&
          res.data &&
          res.data.data &&
          res.data.data.newUserIds &&
          res.data.data.newUserIds.length > 0
        ) {
          let paramsOP = res.data.data.newUserIds;
          let resOP = await this.indvList(paramsOP);

          let optagList = [];
          resOP.data.data.forEach((val) => {
            if (!optagList.includes(val.depNameCN)) {
              optagList.push(val.depNameCN);
              this.optionalList.push({
                depName: val.depNameCN,
                inList: [],
                selected: false,
              });
            }
          });

          resOP.data.data.forEach((val) => {
            this.optionalList.forEach((val2) => {
              if (val.depNameCN == val2.depName) {
                let paramsVaLs = val;
                paramsVaLs.userName = val.userNameEN;
                val2.inList.push(paramsVaLs);
              }
            });
          });

          this.$set(this.optionalList[0], "selected", true);
        }

        this.subLoading = false;
      } else {
        this.totalMemer = this.data.totalMemberNum;
        this.validFeedbacks = this.data.validFeedbacksNum;
        this.invalidFeedbacks = this.data.invalidFeedbacksNum;
        this.participateEmployee = this.data.optionalEmpNum;

        this.indUserList = this.data2;

        /*let optagList = [];

        this.data3.forEach((val) => {
          if (!optagList.includes(val.depName)) {
            optagList.push(val.depName);
            this.optionalList.push({
              depName: val.depName,
              inList: [],
              selected: false,
            });
          }
        });

        this.data3.forEach((val) => {
          this.optionalList.forEach((val2) => {
            if (val.depName == val2.depName) {
              val2.inList.push(val);
            }
          });
        });

        this.$set(this.optionalList[0], "selected", true);*/
      }
    },
    individualPage(itemp) {
      this.$router.push({
        name: "individual",
        query: { userId: itemp.userId, Name: itemp.userNameEN },
      });
    },
    individualPageOp(data) {
      this.$router.push({
        name: "individual",
        query: { userId: data.userId, Name: data.userNameEN },
      });
    },
    showOplist(index) {
      this.$set(
        this.optionalList[index],
        "selected",
        !this.optionalList[index].selected
      );
    },
  },
  created() {
    this.pageInit();
  },
};
</script>

<style></style>
