<template>
  <div class="individual-wrap" :style="!hideMenu ? 'padding-top:0;' : ''">
    <div v-if="pageIf">
      <commentTopControl
        v-if="hideMenu"
        :title="headerTitle"
        :print-mode="printMode"
      >
        <div class="print-area" slot="right">
          <span class="print-btn" @click="printPage"
            ><img src="@/src/assets/save-picture.png" alt=""
          /></span>
          <span v-if="!userId" class="print-btn" @click="exportReport"
            ><img src="@/src/assets/comment/download.jpg" alt=""
          /></span>
        </div>
      </commentTopControl>

      <ifd :userIds.sync="userId"></ifd>

      <!-- I received -->
      <div class="row receivedAndGave">
        <div class="chart-wrap mb16">
          <p class="f16 fb mb10">I received</p>
          <div class="round-wrap fjsa">
            <div class="round-one round-item">
              <span class="round-text f14"
                >Which<br />
                I received</span
              >
              <span class="round-num tac f36 bggreen-1">
                {{ received.inviteAssessFromNum || 0 }}
              </span>
            </div>
            <div class="split-line"></div>
            <div class="round-two round-item">
              <span class="round-num tac f24 bgyellow-1">{{
                received.inviteNum
              }}</span>
              <span class="round-text f14"
                >Which<br />
                I invited<br />
                <span class="cg1">{{
                  received.inviteReplyPercentage
                }}</span></span
              >
            </div>
          </div>
          <div class="number-wrap">
            <p class="number-item mb8 f14">
              Proactively received
              <span class="f32 cg">{{ received.activeAssessFromNum }}</span>
            </p>
            <p class="number-item mb8 f14">
              Total received
              <span class="f32 cg1">{{ received.assessFromNum }}</span>
            </p>
            <template v-if="isInvalid">
              <p class="number-item mb8 f14">Total received ranking</p>
              <compareBar
                :ranking="parseInt(received.ranking)"
                :userTotal="received.userTotal"
                :bgColor="'green'"
                :leftStyle="received.leftStyle"
              ></compareBar>
              <p class="number-item f16">
                Beats
                <span class="f18 cg">{{ received.beatsPercentage }}</span> of
                employees
              </p>
            </template>
          </div>
        </div>

        <!-- I gave -->
        <div class="chart-wrap mb16">
          <p class="f16 fb mb10">I gave</p>
          <div class="round-wrap fjsa">
            <div class="round-one round-item">
              <span class="round-text mb16 f14"> I gave<br /></span>
              <span class="round-num tac f36 bgblue-1">
                {{ gave.inviteReplyFromNum }}
              </span>
            </div>
            <div class="split-line"></div>
            <div class="round-two round-item">
              <span class="round-num tac f24 bgyellow-1">
                {{ gave.inviteFromNum }}
              </span>
              <span class="round-text f14"
                >Requested me <br />
                to give<br />
                <span class="cg1">{{ gave.assessReplyPercentage }}</span></span
              >
            </div>
          </div>
          <div class="number-wrap">
            <p class="number-item mb8 f14">
              I proactively gave
              <span class="f32 cg">{{ gave.activeAssessNum }}</span>
            </p>
            <p class="number-item mb8 f14">
              Total gave <span class="f32 cg1">{{ gave.assessNum }}</span>
            </p>
            <template v-if="isInvalid">
              <p class="number-item mb8 f14">Total gave ranking</p>
              <compareBar
                :ranking="parseInt(gave.ranking)"
                :userTotal="gave.userTotal"
                :bgColor="'blue'"
                :leftStyle="gave.leftStyle"
              ></compareBar>
              <p class="number-item f16">
                Beats <span class="f18 cg">{{ gave.beatsPercentage }}</span> of
                employees
              </p>
            </template>
          </div>
        </div>
      </div>
      <!-- score -->
      <div class="row">
        <div class="col-8">
          <div class="chart-wrap mb16">
            <p class="f16 fb mb10">Score</p>
            <div v-if="!printMode">
              <div class="select-wrap tac">
                <customSelect
                  v-model="diagramValue"
                  @change="getValue"
                  :option="options"
                ></customSelect>
              </div>
              <div class="score-title fjsb">
                <div class="title-item fcjsa">
                  <p class="f12">{{ chartData[0].name }}</p>
                  <p class="f22 cg2">
                    {{ chartData[0].avgSumScore }}
                    <span class="f14">({{ chartData[0].userTotal }})</span>
                  </p>
                </div>
                <div class="score-line"></div>
                <div class="title-item fcjsa">
                  <p class="f12">{{ chartData[1].name }}</p>
                  <p class="f22 cy">
                    {{ chartData[1].avgSumScore }}
                    <span class="f14">({{ chartData[1].userTotal }})</span>
                  </p>
                </div>
              </div>
              <div class="chart-wraps">
                <i
                  class="el-icon-question questionWordList-icon"
                  @click="questionWordList_show = true"
                ></i>
                <div
                  class="score-chart-wrap"
                  :class="[`radar-${diagramValue}`]"
                />
              </div>
            </div>
            <div v-if="printMode">
              <div v-for="(op, index) in options" :key="index">
                <div class="score-header">{{ op.label }}</div>
                <div class="score-title fjsb" v-if="printRadar[index]">
                  <div class="title-item fcjsa">
                    <p class="f12">{{ printRadar[index][0].name }}</p>
                    <p class="f22 cg2">
                      {{ printRadar[index][0].avgSumScore }}
                      <span class="f14"
                        >({{ printRadar[index][0].userTotal }})</span
                      >
                    </p>
                  </div>
                  <div class="score-line"></div>
                  <div class="title-item fcjsa">
                    <p class="f12">{{ printRadar[index][1].name }}</p>
                    <p class="f22 cy">
                      {{ printRadar[index][1].avgSumScore }}
                      <span class="f14"
                        >({{ printRadar[index][1].userTotal }})</span
                      >
                    </p>
                  </div>
                </div>
                <div class="chart-wraps">
                  <i
                    class="el-icon-question questionWordList-icon"
                    @click="questionWordList_show = true"
                  ></i>
                  <div
                    class="score-chart-wrap"
                    :class="[`radar-${op.value}`]"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb16 mob-visible">
            <div class="chart-wrap one">
              <distributionPc></distributionPc>
            </div>
          </div>
        </div>
        <div class="col-16">
          <!-- rank -->
          <template v-if="isInvalid">
            <div class="chart-wrap mb16">
              <p class="f16 fb mb10">Ranking</p>
              <div class="row">
                <div class="col-12">
                  <div class="rank-title fjsb">
                    <div class="fcjsa">
                      <div class="f14 fac">
                        My fair
                        <customPopover top left ref="popover">
                          <div slot="title" class="fair-img hover">
                            <img
                              src="../../../assets/hot-line.png"
                              width="20"
                              height="20"
                            />
                          </div>
                          <div slot="content" class="fair-content">
                            <p>
                              Fair = This average score is excluded 1 lowest and
                              1 highest score from total score
                            </p>
                          </div>
                        </customPopover>
                        score
                      </div>
                      <!-- <span class="f12">{{ rank.depNameCN }}</span> -->
                    </div>
                    <span class="f26 cg3">{{ rank.totalAvgScore }}</span>
                  </div>
                  <div class="rank-title fjsb">
                    <div class="rank-title-item fcjsb">
                      <div class="fjsa">
                        <div class="fcjsa top-text-left">
                          <span class="f10">{{ rank.depNameCN }}</span>
                          <span class="f10">
                            department fair average scores</span
                          >
                        </div>
                        <span class="f20 cg3 top-text-right">{{
                          rank.depAvg
                        }}</span>
                      </div>
                      <div class="fjsb">
                        <span class="f10 bot-text-left">Department rank</span>
                        <span class="f16 fb tar bot-text-right">
                          {{ rank.depRank }}
                        </span>
                      </div>
                    </div>
                    <div class="rank-title-line"></div>
                    <div class="rank-title-item fcjsb">
                      <div class="fjsa">
                        <span class="f10 top-text-left"
                          >Enterprise fair average score</span
                        >
                        <span class="f22 cy top-text-right">{{
                          rank.enterpriseAvg
                        }}</span>
                      </div>
                      <div class="fjsb">
                        <span class="f10 bot-text-left">Enterprise rank</span>
                        <span class="f16 fb tar bot-text-right">
                          {{ rank.enterpriseRank }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="rank-beats mb8">
                    <div class="rank-beats-tit">
                      <div class="rank-select-wrap">
                        <el-radio-group
                          class="rank-select"
                          v-model="radio1"
                          @change="changeRank"
                        >
                          <el-radio-button label="Enterprise"></el-radio-button>
                          <el-radio-button
                            label="Business Unit"
                          ></el-radio-button>
                        </el-radio-group>
                      </div>
                      <customPopover>
                        <div slot="title" class="item hover">
                          <img
                            src="../../../assets/hot-line.png"
                            width="20"
                            height="20"
                          />
                        </div>
                        <div slot="content" class="businessUnit">
                          <h3>5 Business Units:</h3>
                          <ul>
                            <li>JLR China</li>
                            <li>IMSS</li>
                            <li>Purchasing</li>
                            <li>Product Engineering</li>
                            <li>CJLR</li>
                          </ul>
                        </div>
                      </customPopover>
                    </div>
                    <div class="deats-wrap">
                      <compareBar
                        :ranking="parseInt(rank.compareBarRank)"
                        :userTotal="rank.compareBarTotal"
                        :bgColor="'yellow'"
                        :leftStyle="rank.leftStyle"
                      ></compareBar>
                      <p class="f12">
                        Beats
                        <span class="cg3">
                          <strong>{{ rank.percentage }}</strong>
                        </span>
                        of employees
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <qrq />
                </div>
              </div>
            </div>
          </template>
          <div class="mb16 pc-visible">
            <div class="chart-wrap one">
              <distribution></distribution>
            </div>
          </div>
          <div class="commonts-wrap">
            <div class="chart-wrap one">
              <p class="f16 fb mb10">Comments</p>
              <div
                class="item-wrap"
                v-for="(item, index) in comments"
                :key="item.id"
              >
                <div class="item">
                  <div>
                    <h2 class="mb10">{{ item.questionNum }}</h2>
                    <p>{{ item.questionStemEN }}</p>
                  </div>
                  <div class="right" :class="{ bgGreen: index == 0 }">
                    {{ item.commentsNum }}
                  </div>
                </div>
                <div class="content">
                  <div
                    class="answer-wrap"
                    :class="[{ 'print-mode': printMode }]"
                  >
                    <p
                      class="answer"
                      v-for="(content, content_index) in item.commentsContents"
                      :key="content_index"
                    >
                      <i>{{ content_index + 1 }}. </i>
                      {{ content }}
                    </p>
                  </div>
                  <img
                    src="../../../assets/arrow-down.png"
                    class="scroll-icon"
                  />
                  <!-- <div
                    v-if="item.commentsContents.length > 3"
                    class="viewMore hover"
                    @click="handleViewMore(index)"
                  >
                    {{ item.viewMore ? "Close-" : "More+" }}
                  </div> -->
                </div>
              </div>
            </div>
            <div class="second-wrap">
              <div class="tit">
                <span>I want to talk to</span>
                <div
                  slot="title"
                  class="item hover"
                  @click="isVisibleInfo = true"
                >
                  <img
                    src="../../../assets/hot-line.png"
                    width="20"
                    height="20"
                  />
                </div>
              </div>
              <div class="second" style="flex-direction: column">
                <!-- <customPopover top ref="popover" style="width: 100%"> -->
                <div slot="title" style="width: 100%">
                  <p
                    class="item hover"
                    style="width: 100%"
                    @click="showEvpInfo = true"
                  >
                    <span class="icon">
                      <img
                        src="../../../assets/report/feedback/comments_1.png"
                        alt=""
                      />
                    </span>
                    <span class="content hover" style="width: 80%"
                      >EVP+ analysis</span
                    >
                  </p>
                </div>
                <!-- <div slot="content">
                    <p class="mb16">
                      Send an email to request a meeting with EVP or above for
                      analysis.
                    </p>
                  </div>
                  <div slot="close" style="text-align: center">
                    <span class="button">Send application</span>
                  </div> -->
                <!-- </customPopover> -->
                <customPopover top center ref="popover" style="width: 100%">
                  <div slot="title" style="width: 100%">
                    <p class="item hover" style="width: 100%">
                      <span class="icon bg_86bab4">
                        <img
                          src="../../../assets/report/feedback/comments_2.png"
                          alt=""
                        />
                      </span>
                      <span class="content" style="width: 80%"
                        >Line manager analysis</span
                      >
                    </p>
                  </div>
                  <div slot="content">
                    <p class="mb16">
                      Send Email to your line manager for an analysis.
                    </p>
                  </div>

                  <div slot="close" style="text-align: center">
                    <span class="button hover" @click="onLmAnalys"
                      >Send application</span
                    >
                  </div>
                </customPopover>
                <customPopover top right ref="popover" style="width: 100%">
                  <div slot="title" style="width: 100%">
                    <p class="item hover" style="width: 100%">
                      <span class="icon bg_86b1ba">
                        <img
                          src="../../../assets/report/feedback/comments_3.png"
                          alt=""
                        />
                      </span>
                      <span class="content hover" style="width: 80%"
                        >Expert analysis</span
                      >
                    </p>
                  </div>
                  <div slot="content" class="businessUnit">
                    <p class="mb16">
                      Send Email to 360 Feedback supporting mailbox for
                      technical/HR analysis.
                    </p>
                  </div>

                  <div slot="close" class="fjsb">
                    <span class="button hover" @click="onhrAnalys(1)"
                      >Tech help</span
                    >
                    <span class="button hover" @click="onhrAnalys(2)"
                      >HR help</span
                    >
                  </div>
                </customPopover>
              </div>
            </div>
          </div>
        </div>
      </div>
      <customModal :visible="isVisibleInfo" @onClose="isVisibleInfo = false">
        <talkTo />
      </customModal>
      <!-- <customModal :visible="showEvpInfo" @onClose="showEvpInfo = false">
        <evpInfo />
      </customModal> -->
      <van-dialog
        v-model="showEvpInfo"
        :showConfirmButton="false"
        className="evp-feed-dialog"
      >
        <section class="evp-feed-dialog-in">
          <i
            class="el-icon-close evp-feed-dialog-close"
            @click="showEvpInfo = false"
          ></i>

          <div class="enterDe-feedback-table">
            <div class="questionDialog-list-content">
              <h3 class="tit mb10">
                <span class="icon">
                  <img
                    src="@/src/assets/report/feedback/comments_1.png"
                    alt=""
                  />
                </span>

                <span>EVP+ analysis</span>
              </h3>
              <p>
                Apply for EVP + analysis with an email and the EVP + will
                contact you later.
              </p>
              <p
                class="sub-user-title fjsb f14"
                v-for="(item, i) in evpList"
                :key="i"
              >
                <span>· {{ item.nameEn }}</span>
                <span
                  class="link-btn tac f10 hover"
                  @click="onEvpAnalys(item.userId)"
                  >Apply now</span
                >
              </p>
            </div>
          </div>
        </section>
      </van-dialog>
      <!-- questionList -->
      <van-dialog
        v-model="questionWordList_show"
        :showConfirmButton="false"
        className="evp-feed-dialog"
      >
        <section class="evp-feed-dialog-in">
          <i
            class="el-icon-close evp-feed-dialog-close"
            @click="questionWordList_show = false"
          ></i>

          <div class="enterDe-feedback-table">
            <label class="enterDe-feedback-table-label">Question list</label>

            <div class="questionDialog-list-content">
              <section
                class="questionDialog-list"
                v-for="(item, index) in questionWordList"
                :key="'questionWordList' + index"
              >
                <div class="questionDialog-list-left">Q{{ index + 1 }}</div>

                <div class="questionDialog-list-right">
                  <p>{{ item.stemEn }}</p>

                  <p>{{ item.stem }}</p>
                </div>
              </section>
            </div>
          </div>
        </section>
      </van-dialog>
      <reportSidebar v-if="emailVipStatus"></reportSidebar>
    </div>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
import * as echarts from "echarts";
import reportSidebar from "@/src/components/reportSidebar";
import commentTopControl from "@/src/components/comment/commentTopControl.vue";
import compareBar from "./components/compareBar";
import ifd from "@/src/components/indv/invalidFeedback.vue";
import customPopover from "./components/customPopover";
import qrq from "@/src/components/indv/questionRank.vue";
import customSelect from "./components/customSelect";
import customModal from "../board/components/customModal";
import talkTo from "./components/talkTo";
// import evpInfo from "./components/evpInfo";
import distribution from "./components/distribution";
import distributionPc from "./components/distributionPc";

import { mapState, mapMutations, mapActions } from "vuex";

import html2canvas from "html2canvas";
export default {
  name: "addEmail",
  components: {
    reportSidebar,
    commentTopControl,
    compareBar,
    ifd,
    customPopover,
    qrq,
    customSelect,
    customModal,
    talkTo,
    // evpInfo,
    distribution,
    distributionPc,
  },
  data() {
    return {
      hideMenu: true,
      questionWordList_show: false,
      questionWordList: [],
      showEvpInfo: false,
      pageIf: true,
      userId: null,
      isVisibleInfo: false,
      received: {},
      gave: {},
      rank: {},
      chartData: [
        {
          name: "",
          value: [],
          avgSumScore: 0,
          userTotal: 0,
        },
        {
          name: "",
          value: [],
          avgSumScore: 0,
          userTotal: 0,
        },
      ],
      comments: [],
      headerTitle: "My Feedback",
      emptyArr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      options: [
        {
          value: 0,
          label: "Self score & My average score",
          labelArr: ["Self score", "My average score"],
          postData: [1, 2],
        },
        {
          value: 1,
          label: "Seniors score & Others score(besides seniors)",
          labelArr: ["Seniors score", "Others score(besides seniors)"],
          postData: [3, 8],
        },
        {
          value: 2,
          label: "My average score & EVP department average",
          labelArr: ["My average score", "EVP department average"],
          postData: [2, 6],
        },
        {
          value: 3,
          label:
            "From EVP department average & From EVP external department average",
          labelArr: [
            "From EVP department average",
            "From EVP external department average",
          ],
          postData: [14, 9],
        },
        {
          value: 4,
          label: "Invitation & Initiative",
          labelArr: ["Invitation", "Initiative"],
          postData: [4, 5],
        },
      ],
      diagramValue: 0,
      scoreChart: null,
      radio1: "Enterprise",
      evpList: [],
      printMode: false,
      printRadar: [],
    };
  },
  watch: {
    $route(val, oldVal) {
      if (val.query.userId != oldVal.query.userId) {
        this.pageIf = false;
        const userId = this.$route.query.userId || "";
        this.userId = userId;
        this.pageInitReceived(userId);
        this.pageInitAssess(userId);
        this.pageInitDiagram(userId);
        this.pageInitComments(userId);
        this.pageInitRank(userId);
        setTimeout(() => {
          this.pageIf = true;
          this.headerTitle = this.$route.query.Name
            ? this.$route.query.Name
            : "My Feedback";
          // console.log(this.userId, this.$store.state.individual.isInvalid);
        }, 100);
      }
    },
  },
  // created() {

  // },
  computed: {
    ...mapState({
      isInvalid: (state) => state.individual.isInvalid,
      emailOut: (state) => state.comment.emailOut,
      emailVipStatus: (state) => state.comment.emailVipStatus,
    }),
  },
  mounted() {
    if (this.$route.query.menu == "hide") {
      this.hideMenu = false;
    }
    this.headerTitle = this.$route.query.Name
      ? this.$route.query.Name
      : "My Feedback";
    let top = document.documentElement.scrollTop || document.body.scrollTop;
    // 实现滚动效果
    const timeTop = setInterval(() => {
      document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
      if (top <= 0) {
        clearInterval(timeTop);
      }
    }, 10);
    // setTimeout(() => {
    //   console.log(this.$store.state.individual.isInvalid);
    // }, 300);
    // this.isInvalid = this.$store.state.individual.isInvalid;
    const userId = this.$route.query.userId || "";
    this.userId = userId;
    this.pageInitReceived(userId);
    this.pageInitAssess(userId);
    this.pageInitDiagram(userId);
    this.pageInitComments(userId);
    this.pageInitRank(userId);
    this.getEvpLists();
  },
  methods: {
    ...mapActions({
      getReportReceived: "individual/getReportReceived",
      getReportAssess: "individual/getReportAssess",
      getReportDiagram: "individual/getReportDiagram",
      getReportComments: "individual/getReportComments",
      getReportRank: "individual/indQesRank",
      indExport: "individual/indExport",
      linemanagerAnalys: "individual/linemanagerAnalys",
      hrAnalys: "individual/hrAnalys",
      getEvpList: "individual/getEvpList",
      evpAnalys: "individual/evpAnalys",
    }),
    async getEvpLists() {
      let res = await this.getEvpList();
      console.log(res);
      this.evpList = res.data.data;
    },
    onEvpAnalys(val) {
      let params = {
        evpUserId: val,
        surveyModelCode: "JLRCFBM000001",
      };
      this.evpAnalys(params).then((res) => {
        if (res.data.code === "0") {
          this.$toast({
            message: `<div><p style="font-size:14px">Email sent successfully.</p></div>`,
            type: "html",
          });
        }
      });
    },
    onLmAnalys() {
      let params = {
        surveyModelCode: "JLRCFBM000001",
      };
      this.linemanagerAnalys(params).then((res) => {
        if (res.data.code === "0") {
          this.$toast({
            message: `<div><p style="font-size:14px">Email sent successfully.</p></div>`,
            type: "html",
          });
        }
      });
    },
    onhrAnalys(val) {
      let params = {
        type: val,
        surveyModelCode: "JLRCFBM000001",
      };
      this.hrAnalys(params).then((res) => {
        if (res.data.code === "0") {
          this.$toast({
            message: `<div><p style="font-size:14px">Email sent successfully.</p></div>`,
            type: "html",
          });
        }
      });
    },
    async exportReport() {
      let params = {
        surveyModelCode: "JLRCFBM000001",
      };
      let res = await this.indExport(params);
      console.log(res);
      const name = "report.xls";
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;

      // the filename you want
      a.download = name;
      document.body.appendChild(a);
      a.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 1000);

      // const blob = new Blob([res.data]);
      // const url = window.URL.createObjectURL(blob);
      // const aLink = document.createElement("a");
      // aLink.style.display = "none";
      // aLink.href = url;
      // aLink.setAttribute("download", name);
      // document.body.appendChild(aLink);
      // aLink.click();
      // document.body.removeChild(aLink); // 下载完成移除元素
      // window.URL.revokeObjectURL(url); // 释放掉blob对象
    },
    printPage() {
      this.printMode = true;

      const loading = this.$loading({
        lock: true,
        text: "Ready for printing...",
        background: "rgba(0, 0, 0, 0.7)",
        body: true,
      });

      this.pageInitPrint(this.userId);

      setTimeout(() => {
        const content = document.querySelector(".individual-wrap");
        $('<div class="waterSign" id="waterSign"></div>')
          .css({ height: content.scrollHeight })
          .appendTo(".individual-wrap");

        for (let items = 0; items <= 33; items++) {
          $('<div class="waterSign-in">' + this.emailOut + "</div>").appendTo(
            "#waterSign"
          );
        }

        setTimeout(() => {
          html2canvas(content).then((canvas) => {
            let base = canvas.toDataURL("image/jpeg");

            let pdf = new jsPDF("", "pt", "a4");

            let pdfWidth = 592,
              pdfHeight = 841,
              docWidth = parseInt($(".individual-wrap").outerWidth()),
              docHeight = parseInt($(".individual-wrap").outerHeight()),
              biImg = pdfHeight / docHeight;

            let dbWidth = docWidth * biImg,
              dbHeight = pdfHeight;
            let dbWidth_other = 1;

            if (dbWidth > 592) {
              dbWidth_other = 592 / dbWidth;
              dbWidth = dbWidth * dbWidth_other;
              dbHeight = dbHeight * dbWidth_other;
            }

            pdf.addImage(
              base,
              "jpeg",
              (pdfWidth - dbWidth) / 2,
              0,
              dbWidth,
              dbHeight
            ); //592.28, 841.89

            pdf.save("JLR.pdf");

            this.printMode = false;

            setTimeout(() => {
              $("#waterSign").remove();

              loading.close();
            }, 1000);
          });
        }, 1000);
      }, 3000);
    },
    getValue() {
      console.log(this.diagramValue);
      this.pageInitDiagram(this.userId);
    },
    async pageInitReceived(userId) {
      let res = await this.getReportReceived(userId);
      this.received = res.data.data;
      this.received = {
        ...this.received,
        leftStyle: 100 - parseInt(res.data.data.beatsPercentage) + "%",
      };
    },
    async pageInitAssess(userId) {
      let res = await this.getReportAssess(userId);
      this.gave = res.data.data;
      this.gave = {
        ...this.gave,
        leftStyle: 100 - parseInt(res.data.data.beatsPercentage) + "%",
      };
    },
    async pageInitRank(userId) {
      console.log(userId);
      let res = await this.getReportRank(userId);
      this.rank = res.data.data;
      this.rank = {
        ...this.rank,
        compareBarRank: res.data.data.enterpriseRank,
        percentage: res.data.data.beatsEnterprisePercentage,
        compareBarTotal: res.data.data.enterpriseUserTotal,
        leftStyle:
          100 - parseInt(res.data.data.beatsEnterprisePercentage) + "%",
      };
    },
    changeRank() {
      if (this.radio1 == "Business Unit") {
        this.rank = {
          ...this.rank,
          compareBarRank: this.rank["buRank"],
          percentage: this.rank["beatsBuPercentage"],
          compareBarTotal: this.rank["buUserTotal"],
          leftStyle: 100 - parseInt(this.rank["beatsBuPercentage"]) + "%",
        };
      } else {
        this.rank = {
          ...this.rank,
          compareBarRank: this.rank["enterpriseRank"],
          percentage: this.rank["beatsEnterprisePercentage"],
          compareBarTotal: this.rank["enterpriseUserTotal"],
          leftStyle:
            100 - parseInt(this.rank["beatsEnterprisePercentage"]) + "%",
        };
      }
    },
    async pageInitDiagram(userId) {
      let index = this.diagramValue;
      let postData = this.options[index].postData;
      let res = await this.getReportDiagram({ arr: postData, userId });
      let arr = res.data.data;

      let chartData = [];
      if (arr[0].rptScoreDTOList) {
        chartData[0] = {
          name: this.options[index].labelArr[0],
          value:
            (arr[0].rptScoreDTOList &&
              arr[0].rptScoreDTOList.map((item) => item.scoreAvg)) ||
            0,
          avgSumScore: arr[0].avgSumScore || 0,
          userTotal: arr[0].userTotal || 0,
        };
      } else {
        chartData[0] = {
          name: this.options[index].labelArr[0],
          value: this.emptyArr,
          avgSumScore: 0,
          userTotal: 0,
        };
      }
      if (arr[1].rptScoreDTOList) {
        chartData[1] = {
          name: this.options[index].labelArr[1],
          value:
            (arr[1].rptScoreDTOList &&
              arr[1].rptScoreDTOList.map((item) => item.scoreAvg)) ||
            0,
          avgSumScore: arr[1].avgSumScore || 0,
          userTotal: arr[1].userTotal || 0,
        };
      } else {
        chartData[1] = {
          name: this.options[index].labelArr[1],
          value: this.emptyArr,
          avgSumScore: 0,
          userTotal: 0,
        };
      }
      console.log(chartData);
      this.chartData = chartData;
      this.drawScoreChart();
      this.questionWordList = arr[0].questionModelDTOS;
    },
    async pageInitPrint(userId) {
      const result = [];
      const questions = [];
      for (let op of this.options) {
        let postData = op.postData;
        let res = await this.getReportDiagram({ arr: postData, userId });
        let arr = res.data.data;

        let chartData = [];
        if (arr[0].rptScoreDTOList) {
          chartData[0] = {
            name: op.labelArr[0],
            value:
              (arr[0].rptScoreDTOList &&
                arr[0].rptScoreDTOList.map((item) => item.scoreAvg)) ||
              0,
            avgSumScore: arr[0].avgSumScore || 0,
            userTotal: arr[0].userTotal || 0,
          };
        } else {
          chartData[0] = {
            name: op.labelArr[0],
            value: this.emptyArr,
            avgSumScore: 0,
            userTotal: 0,
          };
        }
        if (arr[1].rptScoreDTOList) {
          chartData[1] = {
            name: op.labelArr[1],
            value:
              (arr[1].rptScoreDTOList &&
                arr[1].rptScoreDTOList.map((item) => item.scoreAvg)) ||
              0,
            avgSumScore: arr[1].avgSumScore || 0,
            userTotal: arr[1].userTotal || 0,
          };
        } else {
          chartData[1] = {
            name: op.labelArr[1],
            value: this.emptyArr,
            avgSumScore: 0,
            userTotal: 0,
          };
        }
        console.log(chartData);
        result.push(chartData);
        this.chartData = chartData;
        this.drawScoreChart(op.value);
        questions.push(...arr[0].questionModelDTOS);
      }
      this.printRadar = result;
      this.questionWordList = questions;
    },
    async pageInitComments(userId) {
      let res = await this.getReportComments(userId);
      this.comments = res.data.data;
      // this.comments = res.data.data.map((item) => {
      //   let commentsContentsDefault = [];
      // if (item.commentsContents.length && item.commentsContents.length > 3) {
      //   commentsContentsDefault = item.commentsContents.slice(0, 3);
      // } else {
      // commentsContentsDefault = [...item.commentsContents];
      // }
      //   return {
      //     ...item,
      //     viewMore: false,
      //     commentsContentsDefault,
      //   };
      // });
      console.log(this.comments);
    },
    handleViewMore(index) {
      let flag = this.comments[index].viewMore;
      let commentsContentsDefault = [];
      if (flag) {
        commentsContentsDefault = this.comments[index].commentsContents.slice(
          0,
          3
        );
      } else {
        commentsContentsDefault = [...this.comments[index].commentsContents];
      }
      this.$set(this.comments, index, {
        ...this.comments[index],
        commentsContentsDefault,
        viewMore: !flag,
      });
    },
    drawScoreChart(index) {
      let option = {
        title: {
          text: "",
        },
        color: ["#58AD9E", "#E9C379"],
        legend: {
          icon: "circle",
          bottom: 0,
          itemWidth: 10,
          itemHeight: 10,
          itemStyle: {
            borderCap: "round",
            borderRadius: 10,
          },
        },
        tooltip: {
          show: true,
        },
        radar: [
          {
            indicator: [
              { name: "Q1", max: 10 },
              { name: "Q2", max: 10 },
              { name: "Q3", max: 10 },
              { name: "Q4", max: 10 },
              { name: "Q5", max: 10 },
              { name: "Q6", max: 10 },
              { name: "Q7", max: 10 },
              { name: "Q8", max: 10 },
              { name: "Q9", max: 10 },
              { name: "Q10", max: 10 },
            ],
            center: ["50%", "50%"],
            radius: "100rem",
            startAngle: 90,
            splitNumber: 4,
            shape: "circle",
            axisName: {
              color: "#050505",
            },
            splitArea: {
              areaStyle: {
                color: ["#fff"],
              },
            },
            axisLine: {
              lineStyle: {
                color: "#e0e0e0",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#e0e0e0",
              },
            },
          },
        ],
        series: [
          {
            type: "radar",
            emphasis: {
              lineStyle: {
                width: 4,
              },
            },
            data: [
              {
                value: this.chartData[0].value,
                name: this.chartData[0].name,
                // symbol: "rect",
                symbolSize: 1,
                areaStyle: {
                  color: "rgba(150, 201, 170, 0.74)",
                },
              },
              {
                value: this.chartData[1].value,
                name: this.chartData[1].name,
                // symbol: "rect",
                symbolSize: 1,
                areaStyle: {
                  color: "rgba(232, 193, 117, 0.7)",
                },
              },
            ],
          },
        ],
      };
      // if (!this.scoreChart) {
      this.scoreChart = echarts.init(
        this.$el.querySelector(`.radar-${index ?? this.diagramValue}`)
      );
      this.scoreChart.setOption(option);
      window.addEventListener("resize", () => {
        this.scoreChart.setOption(option, true);
        this.scoreChart.resize();
      });
      // } else {
      //   this.scoreChart.setOption(option, true);
      // }
    },
    linkTo(val) {
      this.$router.push(val);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/variable";
@media screen and (max-width: 760px) {
  .individual-wrap {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: toMRem(80) toMRem(18) toMRem(100);
    background: #f7f7f7;
    .chart-wrap {
      position: relative;
      width: toMRem(339);
      //   height: toMRem(46);
      padding: toMRem(16) toMRem(18);
      background: #ffffff;
      box-shadow: 0px 4px 9px rgba(209, 209, 209, 0.25);
      border-radius: toMRem(16);
      .round-wrap {
        align-items: center;
        height: toMRem(136);
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        .round-one {
          position: relative;
          bottom: toMRem(20);
          .round-text {
            margin-right: toMRem(14);
            display: inline-block;
            text-align: right;
          }
          .round-num {
            display: inline-block;
            width: toMRem(58);
            height: toMRem(58);
            line-height: toMRem(58);
            border-radius: 50%;
            color: #ffffff;
          }
        }
        .round-two {
          display: flex;
          position: relative;
          top: toMRem(20);
          vertical-align: top;
          .round-text {
            display: inline-block;
            text-align: left;
          }
          .round-num {
            display: inline-block;
            width: toMRem(42);
            height: toMRem(42);
            line-height: toMRem(42);
            margin-right: toMRem(14);
            border-radius: 50%;
            color: #ffffff;
          }
        }
        .split-line {
          width: 1px;
          height: toMRem(60);
          mix-blend-mode: multiply;
          background: rgba(0, 0, 0, 0.2);
          transform: rotate(30deg);
        }
      }
      .number-wrap {
        .number-item {
          display: flex;
          align-items: center;
          span {
            margin-left: toMRem(10);
          }
        }
      }
      .select-wrap {
        height: toMRem(60);
        .sel {
          width: 100%;
          height: 100%;
          color: #000;
        }
      }
      .score-header {
        margin-bottom: toMRem(10);
      }
      .score-title {
        width: 100%;
        .title-item {
          width: 50%;
          align-items: center;
        }
        .score-line {
          width: 1px;
          height: toMRem(34);
          background: rgba(0, 0, 0, 0.2);
        }
      }
      .chart-wraps {
        position: relative;
        .questionWordList-icon {
          z-index: 1;
          position: absolute;
          right: toMRem(10);
          top: toMRem(20);
          color: #439e69;
          font-size: toMRem(18);
          cursor: pointer;
        }
        .score-chart-wrap {
          position: relative;
          width: 100%;
          height: toMRem(320);
          box-sizing: content-box;
          margin: 0 auto;
          padding: 0 0 toRem(20) 0;
        }
      }
      .rank-title {
        padding: toMRem(12) toMRem(4);
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
        .rank-title-item {
          width: 48%;
          height: toMRem(90);
          // span {
          //   width: 50%;
          // }
          .top-text-left {
            width: 65%;
          }
          .top-text-right {
            width: 35%;
          }
          .bot-text-left {
            width: 75%;
          }
          .bot-text-right {
            width: 25%;
          }
        }
        .rank-title-line {
          width: 1px;
          height: toMRem(84);
          background: rgba(0, 0, 0, 0.2);
        }
        .fair-img {
          img {
            position: relative;
            bottom: toMRem(6);
            display: inline-block;
            width: toMRem(16);
            height: toMRem(16);
            margin: 0 toMRem(10);
          }
        }
        .fair-content {
          width: toMRem(220);
          left: 150%;
          height: auto;
        }
      }
      .rank-select-wrap {
        margin-right: toMRem(10);
        /deep/ .el-radio-button__inner {
          width: toMRem(96);
          padding: 8px 6px !important;
          font-size: toMRem(12);
        }
      }
      .rank-beats {
        padding-top: toMRem(16);
        .rank-beats-tit {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .businessUnit {
        h3 {
          font-size: toMRem(14);
        }
        ul {
          font-size: toMRem(12);
          padding-left: 16px;
          padding-top: 8px;
          li {
            list-style-type: disc;
            line-height: 2;
            font-weight: 100;
          }
        }
      }
    }
    .mob-visible {
      display: none !important;
    }
    .commonts-wrap {
      display: flex;
      flex-direction: column;
      .second,
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .one {
        margin-bottom: toMRem(16);
        .right {
          min-width: toMRem(48);
          height: toMRem(48);
          border-radius: 50%;
          line-height: toMRem(48);
          text-align: center;
          color: #ffffff;
          font-size: toMRem(30);
          background: #62b496;
        }
        .item-wrap:last-child {
          padding-top: toMRem(16);
          margin-top: toMRem(16);
          .right {
            background: #86b1ba;
          }

          .bgGreen {
            background: #62b496;
          }
        }
        .content {
          position: relative;
          .answer-wrap {
            max-height: toMRem(200);
            overflow-y: scroll;
            &.print-mode {
              max-height: unset;
              overflow-y: visible;
            }
            p.answer {
              font-size: toMRem(12);
              color: #010101;
              line-height: 1.6;
              border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
              padding: 20px;
              position: relative;
              i {
                position: absolute;
                left: 0px;
                display: block;
              }
            }
          }
          .scroll-icon {
            position: absolute;
            right: toMRem(-10);
            width: toMRem(20);
            bottom: 0;
            background: #fff;
          }
          .viewMore {
            color: #3e886d;
            margin: toMRem(10) 0;
          }
        }
      }
      .second-wrap {
        background: #fff;
        padding: 16px;
        border-radius: toMRem(16);
        box-shadow: 0px 4px 9px rgba(209, 209, 209, 0.25);
        .tit {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #fff;
        }
      }
      .second {
        .item {
          background: #ffffff;
          width: toMRem(104);
          height: toMRem(60);
          font-size: toMRem(12);
          justify-content: center;
          .icon {
            min-width: toMRem(24);
            height: toMRem(24);
            background: #e3c16f;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: toMRem(5);
          }

          .bg_86b1ba {
            background: #86b1ba;
          }
          .bg_86bab4 {
            background: #86bab4;
          }
          .content {
            width: toMRem(50);
          }
        }
        .button {
          width: auto;
          height: toMRem(30);
          line-height: toMRem(30);
          font-size: toMRem(12);
          text-align: center;
          background: #ffffff;
          border: 1px solid #3e886d;
          box-sizing: border-box;
          border-radius: toMRem(80);
          color: #3e886d;
          display: inline-block;
          padding: 0 toMRem(10);
        }
      }
    }
    .evp-feed-dialog {
      width: toMRem(330);

      .evp-feed-dialog-close {
        position: absolute;
        right: toMRem(10);
        top: toMRem(18);
        cursor: pointer;
        font-size: toMRem(20);
        z-index: 1;
      }
      .evp-feed-dialog-in {
        position: relative;
        width: 100%;
        padding: toMRem(20);
      }
      .evp-feed-dialog-scroll {
        width: 100%;
        padding: 0 toMRem(10) 0 0;
        height: toMRem(350);
        overflow-y: auto;
      }
      .enterDe-feedback-table {
        position: relative;
        width: 100%;
        flex: none;
        padding: 0;
      }
    }
    .questionDialog-list-content {
      width: 100%;
      height: toMRem(410);
      overflow-x: hidden;
      overflow-y: auto;
      margin: toMRem(10) 0;
      .tit {
        display: flex;
        justify-content: flex-start;
      }
      .icon {
        display: flex;
        background: #e3c16f;
        border-radius: 50%;
        margin-right: toMRem(10);
        width: toMRem(26);
        min-width: toMRem(26);
        height: toMRem(26);
        justify-content: center;
        align-items: center;
      }
      .sub-user-title {
        align-items: center;
        height: toMRem(40);
        line-height: toMRem(40);
        padding-right: toMRem(6);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
      .link-btn {
        display: inline-block;
        width: toMRem(88);
        height: toMRem(26);
        line-height: toMRem(26);
        background: #fff;
        border-radius: toMRem(26);
        color: #3e886d;
        border: 1px solid #3e886d;
      }
      .questionDialog-list {
        display: flex;

        .questionDialog-list-left {
          width: toMRem(40);
          padding: toMRem(10) toMRem(15) 0 0;
          flex-shrink: 0;
          font-size: toMRem(16);
          color: #3e886d;
        }
        .questionDialog-list-right {
          flex-basis: 100%;

          p {
            font-size: toMRem(14);
            line-height: 1.4;
            padding: toMRem(10) toMRem(10) 0 0;

            &:last-child {
              font-size: toMRem(12);
            }
          }
        }
      }
    }
  }
  .el-select-dropdown__item {
    max-width: toMRem(320);
  }
}
@media screen and (min-width: 761px) {
  .individual-wrap {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: toPad(100) toPad(18) toPad(100);
    background: #f7f7f7;
    .chart-wrap {
      position: relative;
      width: 100%;
      //   height: toPad(46);
      padding: toPad(16) toPad(18);
      background: #ffffff;
      box-shadow: 0px 4px 9px rgba(209, 209, 209, 0.25);
      border-radius: toPad(16);
      .round-wrap {
        align-items: center;
        height: toPad(136);
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        .round-one {
          position: relative;
          bottom: toPad(20);
          .round-text {
            margin-right: toPad(14);
            display: inline-block;
            text-align: right;
          }
          .round-num {
            display: inline-block;
            width: toPad(58);
            height: toPad(58);
            line-height: toPad(58);
            border-radius: 50%;
            color: #ffffff;
          }
        }
        .round-two {
          display: flex;
          position: relative;
          top: toPad(20);
          vertical-align: top;
          .round-text {
            display: inline-block;
            text-align: left;
            // width: 52%;
          }
          .round-num {
            display: inline-block;
            width: toPad(42);
            height: toPad(42);
            line-height: toPad(42);
            margin-right: toPad(14);
            border-radius: 50%;
            color: #ffffff;
          }
        }
        .split-line {
          width: toPad(1);
          height: toPad(60);
          mix-blend-mode: multiply;
          background: rgba(0, 0, 0, 0.2);
          transform: rotate(30deg);
        }
      }
      .number-wrap {
        .number-item {
          display: flex;
          align-items: center;
          span {
            margin-left: toPad(10);
          }
        }
      }
      .select-wrap {
        height: toPad(60);
        .sel {
          width: 100%;
          height: 100%;
          color: #000;
        }
      }
      .score-header {
        margin-bottom: toPad(10);
      }
      .score-title {
        width: 100%;
        .title-item {
          width: 50%;
          align-items: center;
        }
        .score-line {
          width: 1px;
          height: toPad(34);
          background: rgba(0, 0, 0, 0.2);
        }
      }
      .chart-wraps {
        position: relative;
        .questionWordList-icon {
          z-index: 1;
          position: absolute;
          right: toPad(10);
          top: toPad(20);
          color: #439e69;
          font-size: toPad(18);
          cursor: pointer;
        }
        .score-chart-wrap {
          position: relative;
          width: 100%;
          height: toPad(320);
          box-sizing: content-box;
          margin: 0 auto;
          padding: 0 0 toRem(20) 0;
        }
      }
      .rank-title {
        padding: toPad(10) toPad(2);
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
        .rank-title-item {
          width: 48%;
          height: toPad(90);
          // span {
          //   width: 50%;
          // }
          .top-text-left {
            width: 65%;
          }
          .top-text-right {
            width: 35%;
          }
          .bot-text-left {
            width: 75%;
          }
          .bot-text-right {
            width: 25%;
          }
        }
        .rank-title-line {
          width: 1px;
          height: toPad(84);
          background: rgba(0, 0, 0, 0.2);
        }
        .fair-img {
          img {
            position: relative;
            bottom: toPad(6);
            display: inline-block;
            width: toPad(16);
            height: toPad(16);
            margin: 0 toPad(10);
          }
        }
        .fair-content {
          // width: toPad(240);
          left: 150%;
          height: auto;
        }
      }
      .rank-select-wrap {
        margin-right: toMRem(10);
        /deep/ .el-radio-button__inner {
          width: toPad(86);
          padding: 8px 6px !important;
          font-size: toPad(12);
        }
      }
      .rank-beats {
        padding-top: toPad(16);
        .rank-beats-tit {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .businessUnit {
        h3 {
          font-size: toPad(14);
        }
        ul {
          font-size: toPad(12);
          padding-left: 16px;
          padding-top: 8px;
          li {
            list-style-type: disc;
            line-height: 2;
            font-weight: 100;
          }
        }
      }
    }
    .pc-visible {
      display: none !important;
    }
    .row {
      display: flex;
      justify-content: space-between;
      .col-8 {
        width: calc(33% - 8px);
        .chart-wrap {
          width: 100%;
        }
      }
      .col-16 {
        width: calc(67% - 8px);
        .chart-wrap {
          width: 100%;
        }
      }
      .col-12 {
        width: calc(50% - 8px);
      }
    }
    .receivedAndGave {
      .chart-wrap {
        width: calc(50% - 8px);
      }
    }
    .commonts-wrap {
      display: flex;
      justify-content: space-between;
      .second,
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .one {
        max-width: calc(55% - 8px);
        .right {
          min-width: toPad(48);
          height: toPad(48);
          border-radius: 50%;
          background: #86b1ba;
          line-height: toPad(48);
          text-align: center;
          color: #ffffff;
          font-size: toPad(30);
        }
        .item:last-child {
          padding-top: toPad(16);
          margin-top: toPad(16);
          border-top: 1px solid rgba(0, 0, 0, 0.2);
          .right {
            background: #86b1ba;
          }
        }
        .bgGreen {
          background: #62b496;
        }
        .content {
          position: relative;
          .answer-wrap {
            max-height: toPad(200);
            overflow-y: scroll;
            &.print-mode {
              max-height: unset;
              overflow-y: visible;
            }
            p.answer {
              font-size: toPad(12);
              color: #010101;
              line-height: 1.6;
              border-bottom: 1px #ddd solid;
              position: relative;
              padding: 20px 30px;
              i {
                position: absolute;
                left: 0px;
                display: block;
              }
            }
          }
          .scroll-icon {
            position: absolute;
            right: toPad(-10);
            width: toPad(20);
            bottom: 0;
            background: #fff;
          }
          .viewMore {
            color: #3e886d;
            margin: toPad(10) 0;
          }
        }
      }
      .second-wrap {
        min-width: calc(45% - 8px);
        background: #fff;
        padding: 16px;
        border-radius: toPad(16);
        box-shadow: 0px 4px 9px rgba(209, 209, 209, 0.25);
        .tit {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #fff;
        }
      }
      .second {
        flex-direction: column;
        align-items: flex-start;
        .item {
          background: #ffffff;
          width: 100%;
          height: toPad(54);
          font-size: toPad(12);
          justify-content: flex-start;
          .icon {
            width: toPad(24);
            height: toPad(24);
            background: #e3c16f;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: toPad(12);
            margin-left: toPad(16);
          }
          .bg_86b1ba {
            background: #86b1ba;
          }
          .bg_86bab4 {
            background: #86bab4;
          }
        }
        .button {
          width: toPad(156);
          height: toPad(30);
          line-height: toPad(30);
          font-size: toPad(12);
          text-align: center;
          background: #ffffff;
          border: 1px solid #3e886d;
          box-sizing: border-box;
          border-radius: toPad(80);
          color: #3e886d;
          display: inline-block;
          padding: 0 toPad(10);
        }
      }
    }
    .evp-feed-dialog {
      width: toPad(330);

      .evp-feed-dialog-close {
        position: absolute;
        right: toPad(10);
        top: toPad(18);
        cursor: pointer;
        font-size: toPad(20);
        z-index: 1;
      }
      .evp-feed-dialog-in {
        position: relative;
        width: 100%;
        padding: toPad(20);
      }
      .evp-feed-dialog-scroll {
        width: 100%;
        padding: 0 toPad(10) 0 0;
        height: toPad(350);
        overflow-y: auto;
      }
      .enterDe-feedback-table {
        position: relative;
        width: 100%;
        flex: none;
        padding: 0;
      }
    }
    .questionDialog-list-content {
      width: 100%;
      height: toPad(410);
      overflow-x: hidden;
      overflow-y: auto;
      margin: toPad(10) 0;
      .tit {
        display: flex;
        justify-content: flex-start;
      }
      .icon {
        display: flex;
        background: #e3c16f;
        border-radius: 50%;
        margin-right: toPad(10);
        width: toPad(26);
        min-width: toPad(26);
        height: toPad(26);
        justify-content: center;
        align-items: center;
      }
      .sub-user-title {
        align-items: center;
        height: toPad(40);
        line-height: toPad(40);
        padding-right: toPad(6);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
      .link-btn {
        display: inline-block;
        width: toPad(88);
        height: toPad(26);
        line-height: toPad(26);
        background: #fff;
        border-radius: toPad(26);
        color: #3e886d;
        border: 1px solid #3e886d;
      }
      .questionDialog-list {
        display: flex;

        .questionDialog-list-left {
          width: toPad(40);
          padding: toPad(10) toPad(15) 0 0;
          flex-shrink: 0;
          font-size: toPad(16);
          color: #3e886d;
        }
        .questionDialog-list-right {
          flex-basis: 100%;

          p {
            font-size: toPad(14);
            line-height: 1.4;
            padding: toPad(10) toPad(10) 0 0;

            &:last-child {
              font-size: toPad(12);
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.el-input__inner {
  color: #000;
  border: 0;
}
.el-select .el-input .el-select__caret {
  color: #000;
}
.el-select-dropdown__item.selected {
  color: #000;
  font-weight: normal;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #fff;
}
.el-select-dropdown__item {
  color: #000;
}
input::-webkit-input-placeholder {
  text-overflow: inherit;
  line-height: initial;
  white-space: pre;
  overflow-wrap: wrap;
  -webkit-user-modify: read-only !important;
  overflow: visible;
}
i.el-select__caret {
  /*很关键：将默认的select选择框样式清除*/
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-left: 24px;
  /*自定义图片*/
  background: url("../../../assets/select-arrow.png") no-repeat scroll right
    center transparent;
  /*自定义图片的大小*/
  background-size: 19px 14px;
}
/*将小箭头的样式去去掉*/
.el-icon-arrow-up:before {
  content: "";
}
.el-radio-button:first-child .el-radio-button__inner {
  border-radius: 24px 0 0 24px !important;
}
.el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0 24px 24px 0;
}
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: rgba(171, 211, 202, 0.62) !important;
  border-color: rgba(171, 211, 202, 0.62) !important;
  color: #060606;
}
</style>
