const actions = {
  async getBoardIndividual({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.boardIndividual);
    return res;
  },
  async getBoardDepartment({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.boardDepartment +
        "/" +
        data.rankType +
        "/?type=" +
        data.type
    );
    return res;
  },
};

export default {
  namespaced: true,
  actions,
};
