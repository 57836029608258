<template>
  <div>
    <div class="enterDe-state-title">
      <label>Ranking</label>
    </div>

    <section class="departWe-detail-line pt0-page">
      <label>Ranking of average score</label>
      <em class="depart-yellow1">{{ avgScore }}</em>
    </section>

    <section class="departWe-detail-rankProcess">
      <div class="departWe-detail-rankProcess-line depart-bg-lineYellow">
        <span
          class="departWe-rg-no"
          :style="'left: ' + parseInt((receivedRank / userTotal) * 100) + '%'"
        >
          <span class="departWe-rg-word">NO.{{ receivedRank }}</span>

          <i class="el-icon-caret-bottom departWe-rg-icon"></i>

          <span class="departWe-rg-line"></span>
        </span>
      </div>

      <span class="departWe-detail-rankProcess-word dd-sp1">1</span>
      <span class="departWe-detail-rankProcess-word dd-sp2">{{
        userTotal
      }}</span>

      <div class="departWe-detail-rankProcess-bottom"></div>
    </section>

    <section class="departRank-qesRank" id="departRank_qesRank"></section>

    <ul class="topBottom-colorLabel">
      <li>
        <em>&nbsp;</em>
        <p>Top 3</p>
      </li>
      <li>
        <em>&nbsp;</em>
        <p>Bottom 3</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import * as echarts from "echarts";
export default {
  data() {
    return {
      dictId: this.$route.query.dictId,
      receivedRank: "",
      avgScore: "",
      userTotal: "",
    };
  },
  methods: {
    ...mapActions({
      departRank: "depart/departRank",
    }),
  },
  async mounted() {
    let params = {
      dictId: this.dictId,
    };

    let res = await this.departRank(params);

    this.receivedRank = res.data.data.ranking;
    this.avgScore = res.data.data.avgScore;
    this.userTotal = res.data.data.userTotal;

    let opData = [],
      opDataName = [];

    if (res.data.data.highAvgQuestionList) {
      res.data.data.highAvgQuestionList.forEach((val) => {
        let params = {
          enterpriseAvgScore: val.enterpriseAvgScore.toFixed(2),
          beatsPercentage: val.beatsPercentage,
          valueEn: val.questionStemEN,
          valueZn: val.questionStem,
          value: val.currentDepAvgScore.toFixed(2),
          label: { position: "top" },
          itemStyle: {
            color: "#69B38F",
            borderRadius: [0, 22, 22, 0],
          },
        };

        opData.push(params);
        opDataName.push(val.questionNum);
      });
    }

    let opDataFan = [],
      opDataFanName = [];

    if (res.data.data.lowAvgQuestionList) {
      res.data.data.lowAvgQuestionList.forEach((val) => {
        let params = {
          enterpriseAvgScore: val.enterpriseAvgScore.toFixed(2),
          beatsPercentage: val.beatsPercentage,
          valueEn: val.questionStemEN,
          valueZn: val.questionStem,
          value: -val.currentDepAvgScore.toFixed(2),
          label: { position: "top" },
          itemStyle: {
            color: "#EFC96E",
            borderRadius: [22, 0, 0, 22],
          },
        };

        opDataFan.unshift(params);
        opDataFanName.unshift(val.questionNum);
      });
    }

    opData = opData.concat(opDataFan);
    opData.reverse();

    opDataName = opDataName.concat(opDataFanName);
    opDataName.reverse();

    let option = {
      tooltip: {
        show: true,
        confine: true,
        triggerOn: "click",
        formatter: (val) => {
          return val.data.valueEn + "<br />" + val.data.valueZn;
        },
      },
      title: {
        text: "Question ranking",
        textStyle: {
          fontSize: "15",
          fontWeight: "normal",
          color: "#000",
        },
      },
      grid: {
        top: 40,
        bottom: 0,
      },

      xAxis: {
        type: "value",
        axisLabel: { show: false },
        position: "top",
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: "category",
        axisLine: { show: true },
        axisLabel: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
        data: opDataName,
      },
      series: [
        {
          type: "bar",
          barWidth: 16,
          label: {
            show: true,
            formatter: (val) => {
              return (
                val.name +
                " " +
                //(val.value >= 0 ? val.value : -val.value) +
                val.value +
                "(Ave:" +
                val.data.enterpriseAvgScore +
                ")"
              );
            },
            fontSize: 10,
            lineHeight: 10,
          },
          data: opData,
        },
      ],
    };

    let chartDom = document.getElementById("departRank_qesRank");

    let myChart = echarts.init(chartDom);

    myChart.setOption(option);

    $(window).resize(function () {
      myChart.resize();
    });
  },
};
</script>

<style></style>
