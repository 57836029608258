const state = {
  benefitHas: true,
};

const mutations = {
  set_carTypeList(state, data) {
    state.carTypeList = data;
  },
};

const actions = {
  async questionList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.questionLis);
    return res;
  },
  async homePage({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.homePage, {
      params: data,
    });
    return res;
  },
  async currentFeedback({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.currentFeedback, data);
    return res;
  },
  async addFeedback({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.addFeedback, data);
    return res;
  },
  async userList({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.userList, data);
    return res;
  },
  async tagNum({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.tagNum, data);
    return res;
  },
  async inviteNum({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(
      rootState.api.inviteNum + "/" + data.code
    );
    return res;
  },
  async inviteSubmit({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.inviteSubmit, data);
    return res;
  },
  async inviteEmail({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.inviteEmail, data);
    return res;
  },
  async activeAssess({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.activeAssess, data);
    return res;
  },
  async isEnterPrise({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.isEnterPrise, {
      params: data,
    });
    return res;
  },
  async queryDept({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.queryDept, {
      params: data,
    });
    return res;
  },
  async queryUser({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.queryUser, {
      params: data,
    });
    return res;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
