const state = {};

const mutations = {};

const actions = {
  async departPersonData({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.departPersonData, {
      params: data,
    });

    return res;
  },
  async departWeReceived({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.departWeReceived, {
      params: data,
    });

    return res;
  },
  async departWeGive({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.departWeGive, {
      params: data,
    });

    return res;
  },
  async departZwt({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(
      rootState.api.departZwt + "?dictId=" + data.dictId,
      data.types
    );

    return res;
  },
  async departRank({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.departRank, {
      params: data,
    });

    return res;
  },
  async departHistogram({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.departHistogram, {
      params: data,
    });

    return res;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
