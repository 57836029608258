<template>
  <section class="enterDe-line">
    <section class="enterDe-state-content">
      <div class="enterDe-state-title">
        <label>Score by Creator Code</label>

        <div class="enterDe-state-title-middle enterDe-state-title-middle-sp2">
          <i class="el-icon-question qs5iclick"></i>

          <div class="businessUnit">
            <h3>5 Business Units:</h3>
            <ul>
              <li>JLR China</li>
              <li>IMSS</li>
              <li>Purchasing</li>
              <li>Product Engineering</li>
              <li>CJLR</li>
            </ul>
          </div>
        </div>

        <ul class="enterDe-state-title-control enterDe-state-title-control-sp2">
          <li
            v-for="(item, index) in roleList"
            :key="'roleFeed' + index"
            :class="{ selected: item.selected }"
            @click="roleChange_feed(item, index)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>

      <div class="enterDe-state-square-content">
        <!--Enterprise average-->
        <div
          class="enterDe-state-square enterDe-state-square-fourSquare enterDe-state-square-growS noBorder"
        >
          <section class="scoreQes-title">Enterprise average</section>

          <section
            id="enterprise_average2"
            class="scoreQes-chart-content one-legend scoreQes-chart-content-short"
          ></section>
        </div>
        <!--Enterprise average end-->

        <!--Department internal feedbacks VS Department external feedbacks-->
        <div
          class="enterDe-state-square enterDe-state-square-fourSquare enterDe-state-square-growM noBorder enterDe-state-square-sp"
        >
          <section class="scoreQes-title">
            Department internal feedbacks VS<br />Department external feedbacks
          </section>

          <section
            id="department_ie_feedbacks2"
            class="scoreQes-chart-content scoreQes-chart-content-short"
          ></section>
        </div>
        <!--Department internal feedbacks VS Department external feedbacks end-->

        <!--Invitation VS Proactiveness-->
        <div
          class="enterDe-state-square enterDe-state-square-fourSquare enterDe-state-square-growM noBorder"
        >
          <section class="scoreQes-title">Invitation VS Proactiveness</section>

          <section
            id="invitation_proactiveness2"
            class="scoreQes-chart-content scoreQes-chart-content-short"
          ></section>
        </div>
        <!--Invitation VS Proactiveness end-->
      </div>
    </section>
  </section>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: ["data"],
  data() {
    return {
      pageType: "",
      roleList: [
        { name: "Business Unit", type: "bu", selected: true },
        { name: "EVP", type: "evp" },
      ],

      questionBu: [],
      questionEvp: [],
      questionEnt: [],

      questionEntData: [],
      questionEntTag: [],

      internalFeedbackData: [],
      internalFeedbackTotal: {},

      externalFeedback: [],
      externalFeedTotal: {},

      invitationAverage: [],
      invitationAverageTotal: {},

      proactiveness: [],
      proactivenessTotal: {},
    };
  },
  methods: {
    roleChange_feed(data, index) {
      this.roleList.forEach((val) => {
        this.$set(val, "selected", false);
      });

      this.$set(this.roleList[index], "selected", true);

      this.pageType = data.type;
      this.pageInit(this.pageType);
    },
    pageInit(type) {
      this.internalFeedbackData = [];
      this.externalFeedback = [];
      this.invitationAverage = [];
      this.proactiveness = [];

      this.internalFeedbackTotal = {};
      this.externalFeedTotal = {};
      this.invitationAverageTotal = {};
      this.proactivenessTotal = {};

      this.internalFeedbackDataTag = [];
      this.externalFeedbackTag = [];
      this.invitationAverageTag = [];
      this.proactivenessTag = [];

      if (type === "evp") {
        this.questionEvp.forEach((val) => {
          if (val.type == 2) {
            this.externalFeedback.push(val.scoreAvg);
            this.externalFeedbackTag.push(val.tagName);

            this.externalFeedTotal = {
              scoreAvg: val.scoreAvg,
            };
          }
          if (val.type == 3) {
            this.internalFeedbackData.push(val.scoreAvg);
            this.internalFeedbackDataTag.push(val.tagName);

            this.internalFeedbackTotal = {
              scoreAvg: val.scoreAvg,
            };
          }
          if (val.type == 4) {
            this.invitationAverage.push(val.scoreAvg);
            this.invitationAverageTag.push(val.tagName);

            this.invitationAverageTotal = {
              scoreAvg: val.scoreAvg,
            };
          }
          if (val.type == 5) {
            this.proactiveness.push(val.scoreAvg);
            this.proactivenessTag.push(val.tagName);

            this.proactivenessTotal = {
              scoreAvg: val.scoreAvg,
            };
          }
        });
      } else {
        this.questionBu.forEach((val) => {
          if (val.type == 2) {
            this.externalFeedback.push(val.scoreAvg);
            this.externalFeedbackTag.push(val.tagName);

            this.externalFeedTotal = {
              scoreAvg: val.scoreAvg,
            };
          }
          if (val.type == 3) {
            this.internalFeedbackData.push(val.scoreAvg);
            this.internalFeedbackDataTag.push(val.tagName);

            this.internalFeedbackTotal = {
              scoreAvg: val.scoreAvg,
            };
          }
          if (val.type == 4) {
            this.invitationAverage.push(val.scoreAvg);
            this.invitationAverageTag.push(val.tagName);

            this.invitationAverageTotal = {
              scoreAvg: val.scoreAvg,
            };
          }
          if (val.type == 5) {
            this.proactiveness.push(val.scoreAvg);
            this.proactivenessTag.push(val.tagName);

            this.proactivenessTotal = {
              scoreAvg: val.scoreAvg,
            };
          }
        });
      }

      // chart

      let color1 = { color: ["#75B7B3"] },
        color2 = { color: ["#75B7B3", "#E0D148", "#E7B962"] },
        color3 = { color: ["#75B7B3", "#efb2ae", "#E7B962"] };

      let optionCommon = {
        title: {
          text: "",
        },

        tooltip: {
          show: true,
          triggerOn: "click",
          confine: true,
        },
        radar: [
          {
            indicator: this.questionEntTag,
            center: ["50%", 150],
            radius: "100rem",
            startAngle: 90,
            splitNumber: 4,
            shape: "circle",
            axisName: {
              color: "#050505",
            },
            splitArea: {
              areaStyle: {
                color: ["#fff"],
              },
            },
            axisLine: {
              lineStyle: {
                color: "#e0e0e0",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#e0e0e0",
              },
            },
          },
        ],

        legend: {
          type: "scroll",
          bottom: 0,
          itemWidth: 10,
          itemHeight: 10,
          // selectedMode: false,

          itemStyle: {
            borderCap: "round",
            borderRadius: 10,
          },
        },
      };

      let option = Object.assign(
        color1,
        JSON.parse(JSON.stringify(optionCommon)),
        {
          series: [
            {
              type: "radar",
              emphasis: {
                lineStyle: {
                  width: 4,
                },
              },
              data: [
                {
                  value: this.questionEntData,
                  name: "Enterprise average",
                  symbolSize: 1,
                  areaStyle: {
                    color: "#b9e2da",
                  },
                },
              ],
            },
          ],
        }
      );

      let option2 = Object.assign(
        color2,
        JSON.parse(JSON.stringify(optionCommon)),
        {
          series: [
            {
              type: "radar",
              emphasis: {
                lineStyle: {
                  width: 4,
                },
              },
              data: [
                {
                  value: this.questionEntData,
                  name: "Enterprise \n average",
                  symbolSize: 1,
                  areaStyle: {
                    color: "#b9e2da",
                  },
                },
                {
                  value: this.internalFeedbackData,
                  name: "Internal feedback \n average",
                  symbolSize: 1,
                  areaStyle: {
                    color: "#f2ea9e",
                  },
                },
                {
                  value: this.externalFeedback,
                  name: "External feedback \n average",
                  symbolSize: 1,
                  areaStyle: {
                    color: "#f5daa0",
                  },
                },
              ],
            },
          ],
        }
      );

      let option3 = Object.assign(
        color3,
        JSON.parse(JSON.stringify(optionCommon)),
        {
          series: [
            {
              type: "radar",
              emphasis: {
                lineStyle: {
                  width: 4,
                },
              },
              data: [
                {
                  value: this.questionEntData,
                  name: "Enterprise \n average",
                  symbolSize: 1,
                  areaStyle: {
                    color: "#b9e2da",
                  },
                },
                {
                  value: this.invitationAverage,
                  name: "Invitation \n average",
                  symbolSize: 1,
                  areaStyle: {
                    color: "#F6E1DD",
                  },
                },
                {
                  value: this.proactiveness,
                  name: "Proactiveness \n average",
                  symbolSize: 1,
                  areaStyle: {
                    color: "#f5daa0",
                  },
                },
              ],
            },
          ],
        }
      );

      let chartDom = document.getElementById("enterprise_average2");
      echarts.init(chartDom).dispose();
      let myChart = echarts.init(chartDom);

      let chartDom2 = document.getElementById("department_ie_feedbacks2");
      echarts.init(chartDom2).dispose();
      let myChart2 = echarts.init(chartDom2);

      let chartDom3 = document.getElementById("invitation_proactiveness2");
      echarts.init(chartDom3).dispose();
      let myChart3 = echarts.init(chartDom3);

      myChart.setOption(option);

      myChart2.setOption(option2);

      myChart3.setOption(option3);
      // chart end

      this.$forceUpdate();

      $(window).resize(function () {
        myChart.resize();
        myChart2.resize();
        myChart3.resize();
      });
    },
  },
  created() {
    this.questionBu = this.data.questionBu;
    this.questionEvp = this.data.questionEvp;
    this.questionEnt = this.data.questionEnt;

    this.questionEnt.forEach((val) => {
      this.questionEntTag.push({ text: val.tagName, max: 10 });
      this.questionEntData.push(val.scoreAvg);
    });
  },
  mounted() {
    this.pageInit();
  },
};
</script>

<style></style>
