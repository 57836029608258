<template>
  <section class="departWe-detail-rankProcess">
    <div
      class="departWe-detail-rankProcess-line"
      :class="{
        'depart-bg-lineGreen': bgColor === 'green',
        'depart-bg-lineBlue': bgColor === 'blue',
        'depart-bg-lineYellow': bgColor === 'yellow',
      }"
    >
      <span class="departWe-rg-no" :style="{ left: leftStyle }">
        <span class="departWe-rg-word">NO.{{ ranking }}</span>

        <i class="el-icon-caret-bottom departWe-rg-icon"></i>

        <span class="departWe-rg-line"></span>
      </span>
    </div>

    <span class="departWe-detail-rankProcess-word dd-sp1">1</span>
    <span class="departWe-detail-rankProcess-word dd-sp2">{{ userTotal }}</span>
  </section>
</template>
<script>
export default {
  name: "customModal",
  props: {
    ranking: Number,
    userTotal: Number,
    bgColor: String,
    leftStyle: String,
  },
  data() {
    return {
      // leftStyle: 0,
    };
  },
  mounted() {
    // console.log(this.props.beatsPercentage);
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "../../../../styles/variable";
@import "@/src/styles/enterDe.scss";
.departWe-detail-rankProcess {
  margin-bottom: 24px;
}
</style>
