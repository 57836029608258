<template>
  <div id="app">
    <router-view v-if="page_platform" />

    <van-dialog
      v-model="vanDiaVisible"
      :showConfirmButton="false"
      className="evp-feed-dialog-home"
    >
      <section class="evp-feed-dialog-home-in">
        <label class="evp-feed-dialog-home-label">No previous record.</label>

        <div class="evp-feed-dialog-home-content">
          You may not have participated in previous 360 Feedback, please wait
          for next phase.
        </div>

        <div class="evp-feed-dialog-home-buttom">
          <span @click="backToApp" class="home-buttom-button">Return</span>
        </div>
      </section>
    </van-dialog>

    <van-dialog
      v-model="vanDiaVisible2"
      :showConfirmButton="false"
      className="evp-feed-dialog-home"
    >
      <section class="evp-feed-dialog-home-in">
        <label class="evp-feed-dialog-home-label"
          >Incomplete personal information.</label
        >

        <div class="evp-feed-dialog-home-content">
          Please contact administrator to fulfill your information.
        </div>

        <div class="evp-feed-dialog-home-buttom">
          <span @click="backToApp" class="home-buttom-button">Return</span>
        </div>
      </section>
    </van-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { Toast } from "vant";

export default {
  data() {
    return {
      vanDiaVisible: false,
      vanDiaVisible2: false,
    };
  },
  async created() {
    let userAgent = navigator.userAgent;

    if (userAgent.indexOf("employeeApp") != -1) {
      this.$store.commit("comment/set_isApp", 1);
    } else {
      this.$store.commit("comment/set_isApp", "");
    }

    function getUrlParams(name) {
      let result = "";

      if (window.location.href.indexOf("?") == -1) {
        return false;
      } else {
        let paramAll = window.location.href.split("?")[1];

        let paramsArray = paramAll.split("&");

        let sst = paramsArray.some((val) => {
          if (val.split("=")[0] === name) {
            result = val.split("=")[1] || "";
            return true;
          }
        });

        return result;
      }
    }

    let checkMailResult = getUrlParams("checkMail");

    if (checkMailResult) {
      if (checkMailResult == 1) {
        this.$store.commit("comment/setCheckMail", 1);
        this.vanDiaVisible2 = false;
        this.vanDiaVisible = true;
      } else if (checkMailResult == 2) {
        this.$store.commit("comment/setCheckMail", 2);
        this.vanDiaVisible = false;
        this.vanDiaVisible2 = true;
      } else {
        this.$store.commit("comment/setCheckMail", "");
        this.vanDiaVisible = false;
        this.vanDiaVisible2 = false;
      }
    } else {
      this.$store.commit("comment/setCheckMail", "");
      this.vanDiaVisible = false;
      this.vanDiaVisible2 = false;
    }

    await this.emailInfo();
  },
  computed: {
    ...mapState({
      page_platform: (state) => state.login.page_platform,
    }),
  },

  methods: {
    ...mapActions({
      emailInfo: "comment/emailInfo",
    }),
    backToApp() {
      window.flutter_inappwebview.callHandler("employee_close_webview");
    },
  },
  mounted() {
    let platSign = "",
      _this = this;

    function platPd() {
      let windowWidth = parseInt($(window).width());

      if (windowWidth <= 760) {
        platSign = "phone";
      } else if (windowWidth > 760 && windowWidth <= 1300) {
        platSign = "pad";
      } else {
        platSign = "pc";
      }

      _this.$store.commit("login/set_page_width", windowWidth);
      _this.$store.commit("login/set_page_platform", platSign);
    }

    platPd();

    $(window).resize(function () {
      platPd();
    });
  },
};
</script>
<style lang="scss">
@import "./styles/variable";
#app {
  font-family: "Montserrat", "PingFang", "Microsoft YaHei", Arial, sans-serif;
  height: 100%;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  // background-color: bgColor();
  font-weight: normal;
}

.evp-feed-dialog-home {
  width: toPad(330);

  .evp-feed-dialog-home-in {
    position: relative;
    width: 100%;
    padding: toPad(20);
  }
  .evp-feed-dialog-home-label {
    display: block;
    width: 100%;
    font-size: toPad(16);
    text-align: center;
  }
  .evp-feed-dialog-home-content {
    width: 100%;
    margin-top: toPad(10);
    font-size: toPad(14);
  }
  .evp-feed-dialog-home-buttom {
    width: 100%;
    margin-top: toPad(20);
    font-size: toPad(14);
    text-align: right;

    .home-buttom-button {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
