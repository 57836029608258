<template>
  <div class="comment-content">
    <commentTopControl :title="headerTitle" :backHide="backHide">
      <img slot="right" :src="headImg" />
    </commentTopControl>

    <div class="comment-content-in">
      <section class="comment-title-process">
        <span v-if="processActive <= processListLength * 0.17"
          >Let's Start...</span
        >

        <span
          v-if="
            processActive > processListLength * 0.17 &&
            processActive <= processListLength * 0.42
          "
          >One More...</span
        >

        <span
          v-if="
            processActive > processListLength * 0.42 &&
            processActive <= processListLength * 0.5
          "
          >Half Way...</span
        >

        <span
          v-if="
            processActive > processListLength * 0.5 &&
            processActive <= processListLength * 0.75
          "
          >One More...</span
        >

        <span
          v-if="
            processActive > processListLength * 0.75 &&
            processActive <= processListLength * 0.92
          "
          >Almost Done...</span
        >

        <span v-if="processActive == processListLength"
          >And we are Done...</span
        >
      </section>

      <section class="comment-process-point">
        <ul>
          <li
            v-for="(item, index) in processList"
            :key="'processPoint' + index"
            :class="{ complete: item.complete, active: item.active }"
          ></li>
        </ul>

        <p>
          <span class="active">{{ processActive }}</span>
          / {{ processTotal }}
        </p>
      </section>

      <section class="slideSelect-comment-process">
        <div class="fill-line"></div>
        <swiper @slideChangeTransitionEnd="onSlideChange1" ref="mySwiper">
          <swiper-slide
            v-for="(item, index) in processList"
            :key="'processList' + index"
            :qesIndex="index"
            class="question-item-slide"
          >
            <div class="enWord">
              {{ item.enWord }}
              <span
                class="question-tips-content"
                :id="'question_tips' + index"
                v-if="item.tip"
              >
                <span
                  class="question-tips"
                  @click="qesTipsCLick('toggle', index)"
                ></span>
                <span class="question-tips-word" v-if="item.tipStatus">
                  <span>{{ item.tip }}</span>
                  <span
                    class="question-tips-icon"
                    @click="qesTipsCLick('close', index)"
                  >
                    <i class="el-icon-close"></i>
                  </span>
                </span>
              </span>
            </div>

            <p class="zhWord">{{ item.zhWord }}</p>

            <!--comment-star-->
            <section
              class="slideSelect-comment-star"
              v-if="
                starCompShow &&
                item.type === 'select' &&
                index + 1 <= processActive + 1 &&
                index + 1 >= processActive - 1
              "
              :index="index"
              :class="{ add1: starAdd1, add2: starAdd2 }"
            >
              <section class="slideSelect-comment-star-in">
                <!--star-mobile-->
                <div v-if="page_width < 1000">
                  <swiper
                    :options="swiperOptions_star"
                    :ref="'mySwiperIn' + index"
                    @slideChangeTransitionStart="onSlideChange_star_start"
                  >
                    <swiper-slide
                      v-for="(item2, index2) in item.scoreList"
                      :key="'processStar' + index2"
                      :class="{ selectSlideDom: item2.selected }"
                    >
                      <div
                        class="swiperOptions_star-item"
                        @click="starNewClick(index, item2)"
                        :class="{
                          level1: item2.code == 1 || item2.code == 2,
                          level2:
                            item2.code == 3 ||
                            item2.code == 4 ||
                            item2.code == 5,
                          level3:
                            item2.code == 6 ||
                            item2.code == 7 ||
                            item2.code == 8,
                          level4: item2.code == 9 || item2.code == 10,
                        }"
                      >
                        <span class="swiperOptions_star-itemWord">{{
                          item2.remark
                        }}</span>

                        <span class="swiperOptions_star-face"></span>
                      </div>
                    </swiper-slide>
                  </swiper>
                </div>
                <!--star-mobile-end-->

                <section v-if="page_width >= 1000" class="comment-slide-np">
                  <section class="comment-slide-np-list">
                    <div
                      v-for="(item2, index2) in item.scoreList"
                      :key="'processStar' + index2"
                      :class="{ selectSlideDom: item2.selected }"
                      class="comment-slide-np-in"
                    >
                      <div
                        class="swiperOptions_star-item"
                        @click="starNewClick(index, item2)"
                        :class="{
                          level1: item2.code == 1 || item2.code == 2,
                          level2:
                            item2.code == 3 ||
                            item2.code == 4 ||
                            item2.code == 5,
                          level3:
                            item2.code == 6 ||
                            item2.code == 7 ||
                            item2.code == 8,
                          level4: item2.code == 9 || item2.code == 10,
                        }"
                      >
                        <span class="swiperOptions_star-itemWord">{{
                          item2.remark
                        }}</span>

                        <span class="swiperOptions_star-face"></span>
                      </div>
                    </div>
                  </section>
                </section>
              </section>
            </section>

            <section
              class="slideSelect-comment-textarea"
              v-if="item.type === 'text'"
            >
              <textarea
                v-model="item.value"
                maxlength="12000"
                placeholder="Write something..."
                @blur="textareaBlur(item, index)"
              ></textarea>
            </section>
            <!--comment-star-end-->

            <section
              class="comment-button-line"
              v-if="index == processListLength - 1"
            >
              <button
                class="comment-button comment-button-primary"
                @click="question_submit"
              >
                Submit
              </button>
            </section>
          </swiper-slide>
        </swiper>

        <div
          v-if="page_platform == 'pc' || page_platform == 'pad'"
          class="comment-slide-np-buttonLine"
        >
          <span
            class="comment-slide-np-btn"
            @click="pcSLide_prev"
            :class="{ disabled: processActive == 1 }"
            >Previous</span
          >
          <span
            class="comment-slide-np-btn primary"
            @click="pcSLide_next"
            :class="{ disabled: processActive == processList.length }"
            >Next</span
          >
        </div>
      </section>

      <!--question-seeAll-content-->
      <section
        class="question-seeAll-content"
        :class="{ show: questionSeeAll }"
        v-if="questionSeeAll"
      >
        <div
          class="question-seeAll-content-op"
          @click="questionSeeAllFn_close"
        ></div>

        <div class="question-seeAll-content-in">
          <div class="question-seeAll-content-title">
            Click to view the question
          </div>

          <ul>
            <li
              v-for="(item, index) in processList"
              :key="'processPoint_seeAll' + index"
              :class="{ active: item.active, answered: item.complete }"
              @click="moveToQes(index)"
            >
              {{ index + 1 }}
            </li>
          </ul>

          <div class="question-seeAll-explain">
            <div class="question-seeAll-explain-line">
              <em class="answered"></em>
              <p>Questions answered</p>
            </div>

            <div class="question-seeAll-explain-line">
              <em></em>
              <p>Unanswered</p>
            </div>
          </div>

          <div
            class="question-seeAll-back"
            @click="questionSeeAllFn_close"
          ></div>
        </div>
      </section>
      <!--question-seeAll-content-end-->

      <div
        class="question-seeAll"
        v-if="!questionSeeAll"
        @click="questionSeeAllFn"
      ></div>

      <img :src="completeImg" alt="" style="display: none" />
      <van-dialog
        v-model="complete_show"
        :showConfirmButton="false"
        className="comment-complete-dialog"
      >
        <div class="comment-complete-dialog-in">
          <em>Completed !</em>
          <p>
            You have completed all the questions and submitted them
            successfully.
          </p>

          <img :src="completeImg" alt="" />

          <p class="sp" v-if="$route.query.inviteCode">
            * Please close the browser
          </p>

          <section class="comment-complete-dialog-buttonLine">
            <span
              v-if="!$route.query.inviteCode"
              class="comment-complete-dialog-btn"
              @click="$router.push({ name: 'invitaHome' })"
              >Home</span
            >

            <!--<span
              v-if="$route.query.inviteCode"
              class="comment-complete-dialog-btn"
              @click="closePage"
              >Close</span
            >-->
          </section>
        </div>
      </van-dialog>

      <!--comment-firstVisit-dialog-->
      <section class="comment-firstVisit-dialog" v-if="firstVisit">
        <div class="comment-firstVisit-dialog-op"></div>

        <div class="comment-firstVisit-dialog-content">
          <img src="@/src/assets/comment/comment-first.png" alt="" />
          <p>Slide to the next<br />question</p>

          <div class="comment-firstVisit-dialog-buttonLine">
            <span
              class="comment-firstVisit-dialog-btn"
              @click="firstVisit = false"
              >I already know the tips.</span
            >
          </div>
        </div>
      </section>
      <!--comment-firstVisit-dialog-end-->
    </div>

    <van-overlay
      :show="subLoading"
      z-index="120"
      :custom-style="{ background: 'rgba(0,0,0,0.2)' }"
    >
      <div class="loading-wrap-common fjc">
        <van-loading size="50px" color="#3E886D" text-color="#3E886D" vertical
          >&nbsp;</van-loading
        >
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import commentTopControl from "@/src/components/comment/commentTopControl.vue";
import completeImg from "@/src/assets/comment/question_complete.png";

export default {
  components: { commentTopControl },
  computed: {
    ...mapState({
      page_platform: (state) => state.login.page_platform,
      page_width: (state) => state.login.page_width,
    }),
  },
  data() {
    return {
      subLoading: false,
      backHide: false,
      completeImg: completeImg,
      headImg: require("@/src/assets/default.png"),
      headerTitle: "Self-assessment",
      firstVisit: false,
      complete_show: false,
      questionSeeAll: false,
      swiperOptions_star: {
        direction: "vertical",
        nested: true,
        slidesPerView: 3,
      },
      processList: [],
      processListLength: 0,
      processActive: 1,
      processTotal: "",
      surveyModelCode: "JLRCFBM000001",
      starAdd1: false,
      starAdd2: false,
      starCompShow: false,
      maxTop: 0,
    };
  },
  created() {
    if (this.$route.query.name) {
      this.headerTitle =
        this.$route.query.name != "undefined" &&
        this.$route.query.name != "null"
          ? this.$route.query.name
          : "";
    }

    this.$route.query.inviteCode
      ? (this.backHide = true)
      : (this.backHide = false);

    this.pageInit();
  },
  mounted() {},
  methods: {
    ...mapActions({
      questionList: "comment/questionList",
      submit_question: "comment/submit_question",
    }),
    calcStarHeight() {
      this.starAdd2 = false;
      this.starAdd1 = false;

      let _this = this,
        windowHeight = parseInt($(window).height());

      this.maxTop = 0;

      $(".zhWord").each(function () {
        let topLs = parseInt($(this).offset().top),
          thisHeight = parseInt($(this).outerHeight()),
          allTop = topLs + thisHeight;

        if (allTop > _this.maxTop) {
          _this.maxTop = allTop;
        }
      });

      if (_this.maxTop >= windowHeight * 0.49) {
        this.swiperOptions_star.slidesPerView = 3;
      } else if (
        _this.maxTop > windowHeight * 0.43 &&
        _this.maxTop <= windowHeight * 0.49
      ) {
        this.starAdd1 = true;
        this.swiperOptions_star.slidesPerView = 4;
      } else {
        this.starAdd2 = true;
        this.swiperOptions_star.slidesPerView = 5;
      }

      setTimeout(() => {
        this.starCompShow = true;
      }, 100);
    },
    starNewClick(indexOut, dataIn) {
      this.processList[indexOut].scoreList.forEach((val) => {
        if (dataIn.code === val.code) {
          this.$set(val, "selected", true);
        } else {
          this.$set(val, "selected", false);
        }
      });

      this.$set(this.processList[indexOut], "complete", true);
      this.$set(this.processList[indexOut], "value", dataIn.code);
      this.$set(
        this.processList[indexOut],
        "select_questionOptionModelId",
        dataIn.id
      );
    },
    async pageInit() {
      let params = {
        code: this.surveyModelCode,
        params: {
          assessee: this.$route.query.userId
            ? this.$route.query.userId
            : undefined,
          inviteCode: this.$route.query.inviteCode
            ? this.$route.query.inviteCode
            : undefined,
        },
      };

      let res = await this.questionList(params);

      res.data.data.questions.forEach((val) => {
        let itemLs = {
          zhWord: val.stem || "",
          enWord: val.stemEn || "",
          active: false,
          type: val.type == 1 ? "select" : "text",
          value: "",
          scoreList: val.questionOptions,
          id: val.id,
          tip: val.tip,
        };

        this.processList.push(itemLs);
      });

      this.processListLength = this.processList.length;

      this.$set(this.processList[0], "active", true);

      this.processTotal = this.processList.length;

      if (res.data.data.isSurveyResultDone == 1) {
        if (this.$route.query.inviteCode) {
          sessionStorage.currentUrlName = "";
          this.complete_show = true;
        } else {
          this.$router.push({ name: "invitaHome" });
        }
      } else {
        this.firstVisit = true;
      }

      setTimeout(() => {
        this.calcStarHeight();
      }, 200);
    },
    nowScoreSelect(index) {
      let thisIndex = 0;

      setTimeout(() => {
        if (this.processList[index].type === "select") {
          this.processList[index].scoreList.some((val, e) => {
            if (val.selected) {
              thisIndex = e;
            }
          });

          if (this.page_width < 1000) {
            this.$refs["mySwiperIn" + index][0].swiper.slideTo(
              thisIndex,
              0,
              false
            );
          }
        }
      }, 100);
    },
    onSlideChange_star_start() {},
    textareaBlur(data, index) {
      if (data.value) {
        this.$set(this.processList[index], "complete", true);
      } else {
        this.$set(this.processList[index], "complete", false);
      }
    },
    onSlideChange1() {
      let qesIndex = parseInt(
        $(".question-item-slide.swiper-slide-active").attr("qesIndex")
      );

      this.processActive = parseInt(qesIndex) + 1;

      this.processList.forEach((val, e) => {
        if (e == qesIndex) {
          this.$set(val, "active", true);
        } else {
          this.$set(val, "active", false);
        }
      });

      this.nowScoreSelect(qesIndex);
    },
    pcSLide_prev() {
      if (this.processActive == 1) {
        return false;
      }
      this.processActive -= 1;
      this.$refs.mySwiper.swiper.slideTo(this.processActive - 1, 1000, false);

      this.processList.forEach((val, e) => {
        if (e == this.processActive - 1) {
          this.$set(val, "active", true);
        } else {
          this.$set(val, "active", false);
        }
      });
    },
    pcSLide_next() {
      if (this.processActive == this.processList.length) {
        return false;
      }
      this.processActive += 1;
      this.$refs.mySwiper.swiper.slideTo(this.processActive - 1, 1000, false);

      this.processList.forEach((val, e) => {
        if (e == this.processActive - 1) {
          this.$set(val, "active", true);
        } else {
          this.$set(val, "active", false);
        }
      });
    },
    questionSeeAllFn() {
      this.questionSeeAll = true;
    },
    questionSeeAllFn_close() {
      this.questionSeeAll = false;
    },
    moveToQes(index) {
      this.questionSeeAll = false;
      this.$refs.mySwiper.swiper.slideTo(index, 0, false);

      this.processActive = index + 1;

      this.processList.forEach((val, e) => {
        if (e == index) {
          this.$set(val, "active", true);
        } else {
          this.$set(val, "active", false);
        }
      });

      this.nowScoreSelect(index);
    },
    question_submit() {
      let sub_status = this.processList.some((val) => {
        return !val.complete && val.type === "select";
      });

      if (sub_status) {
        this.$toast("Please complete the required questions");
      } else {
        this.$dialog
          .alert({
            title: "Confirm",
            message: "Cannot be modified after the submission.",
            theme: "round-button",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            confirmButtonColor: "#3E886D",
            cancelButtonText: "Rethink",
            cancelButtonColor: "#D8DFE2",
          })
          .then(async () => {
            this.subLoading = true;
            let answeredParams = [];

            this.processList.forEach((val) => {
              let subItem = {
                content: val.value,
                questionModelId: val.id,
                questionOptionModelId: val.select_questionOptionModelId,
              };

              answeredParams.push(subItem);
            });

            let answeredParams_all = {
              answers: answeredParams,
              assessee: this.$route.query.userId
                ? this.$route.query.userId
                : undefined,
              inviteCode: this.$route.query.inviteCode
                ? this.$route.query.inviteCode
                : undefined,
              surveyModelCode: this.surveyModelCode,
            };

            let res = await this.submit_question(answeredParams_all);

            if (res.data.code == 0) {
              this.complete_show = true;
            }

            this.subLoading = false;
          })
          .catch(() => {});
      }
    },
    qesTipsCLick(type, index) {
      let contentWidth = parseInt($(".enWord").width()),
        pdLeft = parseInt(
          $(".slideSelect-comment-process>.swiper-container").offset().left
        ),
        tipsPo =
          parseInt(
            $("#question_tips" + index)
              .find(".question-tips")
              .offset().left
          ) - pdLeft;

      if (type === "close") {
        this.$set(this.processList[index], "tipStatus", false);
      } else {
        this.$set(
          this.processList[index],
          "tipStatus",
          !this.processList[index].tipStatus
        );
      }

      /* fnStyle */
      if (this.processList[index].tipStatus) {
        if (tipsPo > contentWidth * 0.5) {
          setTimeout(() => {
            $("#question_tips" + index)
              .find(".question-tips-word")
              .removeAttr("style")
              .css({ right: 0 });

            if (
              parseInt(
                $("#question_tips" + index)
                  .find(".question-tips-word")
                  .offset().left
              ) < 0
            ) {
              $("#question_tips" + index)
                .find(".question-tips-word")
                .css({ right: "auto", left: 3 - tipsPo });
            }

            $("#question_tips" + index)
              .find(".question-tips-word")
              .css({ opacity: 1 });
          }, 1);
        } else {
          setTimeout(() => {
            $("#question_tips" + index)
              .find(".question-tips-word")
              .removeAttr("style")
              .css("left", 0);

            let distance =
                parseInt(
                  $("#question_tips" + index)
                    .find(".question-tips-word")
                    .offset().left
                ) -
                pdLeft +
                parseInt(
                  $("#question_tips" + index)
                    .find(".question-tips-word")
                    .outerWidth()
                ),
              distance2 =
                parseInt(
                  $("#question_tips" + index)
                    .find(".question-tips-word")
                    .offset().left
                ) -
                pdLeft +
                parseInt(
                  $("#question_tips" + index)
                    .find(".question-tips-word")
                    .outerWidth()
                ) -
                contentWidth;

            if (distance > contentWidth) {
              $("#question_tips" + index)
                .find(".question-tips-word")
                .css({ left: -distance2 - 3 });
            }

            $("#question_tips" + index)
              .find(".question-tips-word")
              .css({ opacity: 1 });
          }, 1);
        }
      }

      /* fnStyle end */
    },
    closePage() {
      if (
        window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
        "micromessenger"
      ) {
        /*eslint-disable*/
        WeixinJSBridge.call("closeWindow");
        /*eslint-enable*/
      } else if (
        window.navigator.userAgent.indexOf("Firefox") != -1 ||
        window.navigator.userAgent.indexOf("Chrome") != -1
      ) {
        window.location.href = "about:blank";
        window.close();
      } else {
        window.opener = null;
        window.open("", "_self");
        window.close();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/comment.scss";
</style>
