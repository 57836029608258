const el = {};
const img = {
  load(src) {
    const _img = document.createElement("img");
    _img.src = src;
    return new Promise((resolve, reject) => {
      _img.addEventListener("load", () => {
        resolve(_img);
      });
      _img.addEventListener("error", () => {
        reject(_img);
      });
    });
  },
};
const utils = {
  getToken() {
    return window.localStorage.getItem("token");
  },
  // 设置cookies
  setCookie(name, value) {
    var Days = 30;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    document.cookie =
      name + "=" + escape(value) + ";expires=" + exp.toGMTString() + ";path=/";
  },
  //读取cookies
  getCookie(name) {
    var arr,
      reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
    else return null;
  },
  getRootSize() {
    return Number(document.documentElement.style.fontSize.replace("px", ""));
  },
  toRem(px) {
    return (px * 25) / 1920;
  },
  tranPx(px) {
    return utils.toRem(px) * utils.getRootSize();
  },
  el,
  img,
};
export default utils;
