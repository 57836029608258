<template>
  <div>
    <div class="enterDe-state-title">
      <label>Score distribution</label>
    </div>

    <div class="distribution-content">
      <section class="distribution-empAvgScore-title">Received score</section>

      <section
        class="distribution-empAvgScore"
        id="distribution_empAvgScore"
      ></section>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import * as echarts from "echarts";
export default {
  data() {
    return {
      userId: this.$route.query.userId,
    };
  },
  methods: {
    ...mapActions({
      scoreDistribute: "individual/scoreDistribute",
    }),
  },
  async mounted() {
    let params = null;
    if (this.userId) {
      params = this.userId;
    }
    let res = await this.scoreDistribute(params);
    let tagDataList = [],
      personDataList = [];

    let maxValueLs = 0;

    res.data.data.forEach((val, e) => {
      //   let dataIn = {
      //     type: "bar",
      //     data: [0, 0, 0, 0, 0, 0],
      //     label: {
      //       show: true,
      //       formatter: (val) => {
      //         return val.value == 0 ? "" : val.value;
      //       },
      //       fontSize: 10,
      //       lineHeight: 12,
      //     },
      //   };
      let dataIn = {
        name: val.sectionName,
        value: val.userTotal,
      };
      if (parseInt(val.userTotal) >= maxValueLs) {
        maxValueLs = parseInt(val.userTotal);
      }

      tagDataList.push({
        value: val.sectionName,
      });
      personDataList.push(dataIn);
    });

    let option = {
      grid: {
        top: 40,
        bottom: 40,
        left: 40,
      },
      visualMap: {
        itemWidth: 1,
        itemHeight: 0,
        min: 0,
        max: maxValueLs,
        left: -15,
        bottom: 0,
        calculable: false,
        text: ["high", "low"],
        inRange: {
          color: ["#B4BFCA", "#93C1CB", "#91B6D8"],
        },
        textStyle: {
          color: "#fff",
        },
      },
      xAxis: [
        {
          axisLabel: {
            rotate: 40,
          },
          type: "category",
          data: tagDataList,
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "Persons",
          nameLocation: "end",
          nameTextStyle: { padding: [0, 0, 10, 10] },
        },
      ],
      series: {
        type: "bar",
        barWidth: "40%",
        data: personDataList,
        label: {
          show: true,
          formatter: (val) => {
            return val.value == 0 ? "" : val.value;
          },
          fontSize: 10,
          lineHeight: 12,
          position: "top",
        },
      },
    };

    let chartDom = document.getElementById("distribution_empAvgScore");
    this.myChart = echarts.init(chartDom);
    this.myChart.setOption(option);

    window.addEventListener("resize", () => {
      this.myChart.setOption(option, true);
      this.myChart.resize();
    });
  },
};
</script>

<style lang="scss">
@import "@/src/styles/enterDe.scss";
</style>
