import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import utils from "./common/utils";
import "./styles/common.scss";
import "./styles/common_pad.scss";
import Vant from "vant";
import { Lazyload } from "vant";
import "vant/lib/index.css";
import "swiper/dist/css/swiper.css";
// import vConsole from "./vconsole";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueAwesomeSwiper from "vue-awesome-swiper";

import axios from "axios";
Vue.prototype.$axios = axios;

Vue.use(VueAwesomeSwiper /* { 全局组件的默认选项 } */);

// Vue.prototype.vConsole = vConsole;

Vue.use(Vant);
Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.config.productionTip = false;
Vue.prototype.utils = utils;
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
