<template>
  <div class="c-feedback-survey">
    <div class="survey-title">Survey</div>
    <div class="survey-questions">
      <div class="survey-question">
        <div class="survey-question__title">
          Did my boss give me the feedback?
        </div>
        <div class="survey-question__selection">
          <div
            class="survey-question__selection-op"
            :class="[{ selected: surveyAnswer1 === 1 }]"
            @click="setSurveyAnswer1(1)"
          >
            Yes
          </div>
          <div
            class="survey-question__selection-op"
            :class="[{ selected: surveyAnswer1 === 2 }]"
            @click="setSurveyAnswer1(2)"
          >
            No
          </div>
        </div>
      </div>
      <div class="survey-question">
        <div class="survey-question__title">
          Did the result help me to grow?
        </div>
        <div class="survey-question__selection">
          <div
            class="survey-question__selection-op"
            :class="[{ selected: surveyAnswer2 === 1 }]"
            @click="setSurveyAnswer2(1)"
          >
            Yes
          </div>
          <div
            class="survey-question__selection-op"
            :class="[{ selected: surveyAnswer2 === 2 }]"
            @click="setSurveyAnswer2(2)"
          >
            No
          </div>
        </div>
      </div>
      <div class="survey-question">
        <div class="survey-question__title">Comments</div>
        <textarea
          class="survey-question__textarea"
          rows="5"
          v-model="surveyAnswer3"
          placeholder="Write something"
          :readonly="!submitable"
        />
      </div>
    </div>
    <div class="survey-actions" v-if="submitable">
      <button class="survey-submit-btn" @click="submitSurvey">Submit</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "FeedbackSurvey",
  data() {
    return {
      submitted: false,
      surveyId: null,
      surveyAnswer1: 1,
      surveyAnswer2: 1,
      surveyAnswer3: null,
    };
  },
  computed: {
    available() {
      return new Date() <= new Date("2022-04-15 23:59:59");
    },
    submitable() {
      return this.available && !this.submitted;
    },
  },
  async mounted() {
    const res = await this.currentFeedback();
    let data = res.data?.data;
    if (data && data.id) {
      this.submitted = true;
      this.surveyId = res.data.id;
      this.surveyAnswer1 = data.lineManagerGave;
      this.surveyAnswer2 = data.growHelp;
      this.surveyAnswer3 = data.comments;
    }
    console.log("feedback res", data);
  },
  methods: {
    ...mapActions({
      currentFeedback: "invite/currentFeedback",
      addFeedback: "invite/addFeedback",
    }),
    setSurveyAnswer1(answer) {
      if (this.submitable) {
        this.surveyAnswer1 = answer;
      }
    },
    setSurveyAnswer2(answer) {
      if (this.submitable) {
        this.surveyAnswer2 = answer;
      }
    },
    async submitSurvey() {
      const res = await this.addFeedback({
        lineManagerGave: this.surveyAnswer1,
        growHelp: this.surveyAnswer2,
        comments: this.surveyAnswer3,
      });
      if (res.data.code == "0") {
        this.submitted = true;
        this.$toast({
          message: "Survey submitted successfully!",
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../styles/variable";
.c-feedback-survey {
  box-shadow: 0 4px 9px rgba(209, 209, 209, 0.25);
  border-radius: toMRem(16);
  width: 100%;
  padding: toMRem(16) toMRem(18);
  background: #ffffff;
  .survey-title {
    font-size: toMRem(16);
    font-weight: bold;
    margin-bottom: toMRem(10);
  }

  .survey-questions {
    .survey-question {
      $c: &;
      &__title {
        margin-bottom: toMRem(15);
        color: #222;
      }
      &__selection {
        padding-bottom: toMRem(10);
        display: flex;
        &-op {
          height: toMRem(30);
          line-height: toMRem(28);
          border-radius: toMRem(20);
          padding: 0 toMRem(30);
          border: 1px solid #cdd2d2;
          color: #cdd2d2;
          cursor: pointer;
          &.selected {
            border-color: #3e886d;
            color: #3e886d;
          }
          + .survey-question__selection-op {
            margin-left: toMRem(40);
          }
        }
      }
      &__textarea {
        width: 100%;
        border-radius: toMRem(10);
        border: 1px solid #cdd2d2;
        padding: toMRem(10) toMRem(15);
      }
      + .survey-question {
        margin-top: toMRem(10);
      }
      &.has-error {
        #{$c}__title {
          color: #ff0400;
        }
      }
    }
  }
  .survey-actions {
    padding: toMRem(16);
    text-align: center;
    .survey-submit-btn {
      width: toMRem(167);
      height: toMRem(40);
      border-radius: toMRem(20);
      background: #3e886d;
      text-align: center;
      color: #fff;
    }
  }
}
@media screen and (min-width: 761px) {
  .c-feedback-survey {
    box-shadow: 0 4px 9px rgba(209, 209, 209, 0.25);
    border-radius: toPad(16);
    width: 100%;
    padding: toPad(16) toPad(18);
    background: #ffffff;
    .survey-title {
      font-size: toPad(16);
      font-weight: bold;
      margin-bottom: toPad(10);
    }

    .survey-questions {
      .survey-question {
        $c: &;
        &__title {
          margin-bottom: toPad(15);
          color: #222;
        }
        &__selection {
          padding-bottom: toPad(10);
          display: flex;
          &-op {
            height: toPad(30);
            line-height: toPad(28);
            border-radius: toPad(20);
            padding: 0 toPad(30);
            border: 1px solid #cdd2d2;
            color: #cdd2d2;
            cursor: pointer;
            &.selected {
              border-color: #3e886d;
              color: #3e886d;
            }
            + .survey-question__selection-op {
              margin-left: toPad(40);
            }
          }
        }
        &__textarea {
          width: 100%;
          border-radius: toPad(10);
          border: 1px solid #cdd2d2;
          padding: toPad(10) toPad(15);
        }
        + .survey-question {
          margin-top: toPad(10);
        }
        &.has-error {
          #{$c}__title {
            color: #ff0400;
          }
        }
      }
    }
    .survey-actions {
      padding: toPad(16);
      text-align: center;
      .survey-submit-btn {
        width: toPad(167);
        height: toPad(40);
        border-radius: toPad(20);
        background: #3e886d;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
</style>
