<template>
  <div>
    <div
      class="modal-box-warpper"
      v-if="visible"
      @click="handleModal"
      @touchmove.prevent
    ></div>
    <div class="content">
      <div
        v-if="visible"
        class="modal-box"
        @click.stop
        :class="{ top: top, center: center, right: right, left: left }"
      >
        <slot name="content"></slot>
        <div @click="handleModal">
          <slot name="close"></slot>
        </div>
      </div>
      <div @click="handleModal"><slot name="title"></slot></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "customPopover",
  props: {
    top: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  mounted() {},
  methods: {
    handleModal() {
      this.visible = !this.visible;
      this.$emit("onClose", this.visible);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../../styles/variable";
@media screen and (max-width: 760px) {
  .modal-box-warpper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: toMRem(56);
    background: rgba(255, 255, 255, 0);
    z-index: 1;
    left: 0;
  }
  .content {
    position: relative;
    .modal-box {
      position: absolute;
      top: toMRem(40);
      right: toMRem(-20);
      width: toMRem(238);
      padding: toMRem(16);
      background: #ffffff;
      box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      z-index: 2;
      height: toMRem(202);
      // overflow-x: auto;
    }
    .top {
      top: auto;
      bottom: toMRem(60);
      left: 0;
      height: auto;
      font-size: toMRem(12);
      line-height: 1.5;
    }
    .center {
      left: 50%;
      transform: translateX(-50%);
    }
    .right {
      left: auto;
      right: 0;
    }
    .left {
      left: 0 !important;
      right: auto;
      // transform: translateX(-50%);
    }
  }
}
@media screen and (min-width: 760px) {
  .modal-box-warpper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: toPad(56);
    background: rgba(255, 255, 255, 0);
    left: 0;
  }
  .content {
    position: relative;
    .modal-box {
      position: absolute;
      top: toPad(40);
      right: toPad(-20);
      width: toPad(238);
      padding: toPad(20);
      background: #ffffff;
      box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      z-index: 2;
      height: toPad(210);
      // overflow-y: auto;
    }
    .top {
      bottom: toPad(40);
      left: 0;
    }
    .center {
      left: 50%;
      transform: translateX(-50%);
    }
    .right {
      left: auto;
      right: 0;
    }
    .left {
      left: 0 !important;
      right: auto;
      // transform: translateX(-50%);
    }
  }
}
</style>
