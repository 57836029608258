<template>
  <section class="enterDe-line">
    <section class="enterDe-state-content">
      <div class="enterDe-state-title">
        <label>Score</label>
      </div>

      <div class="enterDe-state-square-content">
        <!--Enterprise average-->
        <div
          class="enterDe-state-square enterDe-state-square-fourSquare noBorder"
        >
          <section class="scoreQes-title">
            Department average VS<br />Enterprise average
          </section>

          <section class="scoreQes-overView">
            <div class="scoreQes-overView-square">
              <p>Department average</p>
              <em class="color-green-s-depart"
                >{{ Department_average.avgSumScore
                }}<span>({{ cmpData.validFeedbacks }})</span></em
              >
            </div>

            <div class="scoreQes-overView-square">
              <p>Enterprise average</p>
              <em class="color-yellow-s"
                >{{ Enterprise_average.avgSumScore
                }}<span>({{ Enterprise_average.userTotal }})</span></em
              >
            </div>
          </section>

          <i
            class="el-icon-question questionWordList-icon"
            @click="questionWordList_show = true"
          ></i>

          <section
            id="enterprise_average_depart"
            class="scoreQes-chart-content"
          ></section>
        </div>
        <!--Enterprise average end-->

        <!--Department internal feedbacks VS Department external feedbacks-->
        <div
          class="enterDe-state-square enterDe-state-square-fourSquare noBorder"
        >
          <section class="scoreQes-title">
            Department internal feedbacks VS<br />Department external feedbacks
          </section>

          <section class="scoreQes-overView">
            <div class="scoreQes-overView-square">
              <p>Internal feedback average</p>
              <em class="color-blue-s-depart"
                >{{ Internal_feedback_average.avgSumScore
                }}<span>({{ Internal_feedback_average.userTotal }})</span></em
              >
            </div>

            <div class="scoreQes-overView-square">
              <p>External feedback average</p>
              <em class="color-yellowDeep-s"
                >{{ External_feedback_average.avgSumScore
                }}<span>({{ External_feedback_average.userTotal }})</span></em
              >
            </div>
          </section>

          <i
            class="el-icon-question questionWordList-icon"
            @click="questionWordList_show = true"
          ></i>

          <section
            id="department_ie_feedbacks_depart"
            class="scoreQes-chart-content"
          ></section>
        </div>
        <!--Department internal feedbacks VS Department external feedbacks end-->

        <!--Invitation VS Proactiveness-->
        <div
          class="enterDe-state-square enterDe-state-square-fourSquare noBorder"
        >
          <section class="scoreQes-title">Invitation VS Proactiveness</section>

          <section class="scoreQes-overView">
            <div class="scoreQes-overView-square">
              <p>Invitation average</p>
              <em class="color-green-s-depart"
                >{{ Invitation_average.avgSumScore
                }}<span>({{ Invitation_average.userTotal }})</span></em
              >
            </div>

            <div class="scoreQes-overView-square">
              <p>Proactiveness average</p>
              <em class="color-greenLight-s-depart"
                >{{ Proactiveness_average.avgSumScore
                }}<span>({{ Proactiveness_average.userTotal }})</span></em
              >
            </div>
          </section>

          <i
            class="el-icon-question questionWordList-icon"
            @click="questionWordList_show = true"
          ></i>

          <section
            id="invitation_proactiveness_depart"
            class="scoreQes-chart-content"
          ></section>
        </div>
        <!--Invitation VS Proactiveness end-->
      </div>
    </section>

    <van-dialog
      v-model="questionWordList_show"
      :showConfirmButton="false"
      className="evp-feed-dialog"
    >
      <section class="evp-feed-dialog-in">
        <i
          class="el-icon-close evp-feed-dialog-close"
          @click="questionWordList_show = false"
        ></i>

        <div class="enterDe-feedback-table">
          <label class="enterDe-feedback-table-label">Question list</label>

          <div class="questionDialog-list-content">
            <section
              class="questionDialog-list"
              v-for="(item, index) in questionWordList"
              :key="'questionWordList' + index"
            >
              <div class="questionDialog-list-left">Q{{ index + 1 }}</div>

              <div class="questionDialog-list-right">
                <p>{{ item.stemEn }}</p>

                <p>{{ item.stem }}</p>
              </div>
            </section>
          </div>
        </div>
      </section>
    </van-dialog>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import * as echarts from "echarts";
export default {
  props: ["cmpData"],
  data() {
    return {
      questionWordList_show: false,
      questionWordList: [],

      dictId: this.$route.query.dictId,

      Department_average: {},
      Department_average_data: [],

      Internal_feedback_average: {},
      Internal_feedback_average_data: [],

      Enterprise_average: {},
      Enterprise_average_data: [],

      External_feedback_average: {},
      External_feedback_averagee_data: [],

      Invitation_average: {},
      Invitation_average_data: [],

      Proactiveness_average: {},
      Proactiveness_averagee_data: [],
    };
  },
  methods: {
    ...mapActions({
      departZwt: "depart/departZwt",
    }),

    pageInit() {},
  },
  async created() {},
  async mounted() {
    let params = {
      dictId: this.dictId,
      types: [8, 9, 10, 11, 12, 13],
    };

    let res = await this.departZwt(params),
      qesEachNum = 0;

    res.data.data.forEach((val) => {
      if (
        val.questionModelDTOS &&
        val.questionModelDTOS.length > 0 &&
        qesEachNum == 0
      ) {
        this.questionWordList = val.questionModelDTOS;
        qesEachNum = 1;
      }
      if (val.type == 8) {
        this.Department_average = val;

        val.rptScoreDTOList.forEach((val2) => {
          this.Department_average_data.push(parseFloat(val2.scoreAvg));
        });
      }
      if (val.type == 9) {
        this.Enterprise_average = val;

        val.rptScoreDTOList.forEach((val2) => {
          this.Enterprise_average_data.push(parseFloat(val2.scoreAvg));
        });
      }
      if (val.type == 10) {
        this.Internal_feedback_average = val;

        val.rptScoreDTOList.forEach((val2) => {
          this.Internal_feedback_average_data.push(parseFloat(val2.scoreAvg));
        });
      }
      if (val.type == 11) {
        this.External_feedback_average = val;

        val.rptScoreDTOList.forEach((val2) => {
          this.External_feedback_averagee_data.push(parseFloat(val2.scoreAvg));
        });
      }
      if (val.type == 12) {
        this.Invitation_average = val;

        val.rptScoreDTOList.forEach((val2) => {
          this.Invitation_average_data.push(parseFloat(val2.scoreAvg));
        });
      }
      if (val.type == 13) {
        this.Proactiveness_average = val;

        val.rptScoreDTOList.forEach((val2) => {
          this.Proactiveness_averagee_data.push(parseFloat(val2.scoreAvg));
        });
      }
    });

    let color1 = { color: ["#57A795", "#E7B962"] },
      color2 = { color: ["#87BDB1", "#efb2ae", "#E7B962"] },
      color3 = { color: ["#57A795", "#efb2ae", "#E7B962"] };

    let optionCommon = {
      tooltip: {
        show: true,
        triggerOn: "click",
        confine: true,
      },
      title: {
        text: "",
      },

      legend: {
        type: "scroll",
        bottom: 0,
        itemWidth: 10,
        itemHeight: 10,

        itemStyle: {
          borderCap: "round",
          borderRadius: 10,
        },
        selected: {
          "Enterprise \n average": false,
        },
      },
      radar: [
        {
          indicator: [
            { text: "Q1", max: 10 },
            { text: "Q2", max: 10 },
            { text: "Q3", max: 10 },
            { text: "Q4", max: 10 },
            { text: "Q5", max: 10 },
            { text: "Q6", max: 10 },
            { text: "Q7", max: 10 },
            { text: "Q8", max: 10 },
            { text: "Q9", max: 10 },
            { text: "Q10", max: 10 },
          ],
          center: ["50%", 150],
          radius: "100rem",
          startAngle: 90,
          splitNumber: 4,
          shape: "circle",
          axisName: {
            color: "#050505",
          },
          splitArea: {
            areaStyle: {
              color: ["#fff"],
            },
          },
          axisLine: {
            lineStyle: {
              color: "#e0e0e0",
            },
          },
          splitLine: {
            lineStyle: {
              color: "#e0e0e0",
            },
          },
        },
      ],
    };

    let option = Object.assign(
      color1,
      JSON.parse(JSON.stringify(optionCommon)),
      {
        series: [
          {
            type: "radar",
            emphasis: {
              lineStyle: {
                width: 4,
              },
            },
            data: [
              {
                value: this.Department_average_data,
                name: "Department average",
                // symbol: "rect",
                symbolSize: 1,
                areaStyle: {
                  color: "#57A795",
                  opacity: 0.3,
                },
              },
              {
                value: this.Enterprise_average_data,
                name: "Enterprise average",
                symbolSize: 1,
                areaStyle: {
                  color: "#EABF8A",
                  opacity: 0.5,
                },
              },
            ],
          },
        ],
      }
    );

    let option2 = Object.assign(
      color2,
      JSON.parse(JSON.stringify(optionCommon)),
      {
        series: [
          {
            type: "radar",
            emphasis: {
              lineStyle: {
                width: 4,
              },
            },
            data: [
              {
                value: this.Internal_feedback_average_data,
                name: "Internal feedback \n average",
                symbolSize: 1,
                areaStyle: {
                  color: "#87BDB1",
                  opacity: 0.3,
                },
              },
              {
                value: this.External_feedback_averagee_data,
                name: "External feedback \n average",
                symbolSize: 1,
                areaStyle: {
                  color: "#efb2ae",
                  opacity: 0.3,
                },
              },
              {
                value: this.Enterprise_average_data,
                name: "Enterprise \n average",
                symbolSize: 1,
                areaStyle: {
                  color: "#E9C56E",
                  opacity: 0.3,
                },
              },
            ],
          },
        ],
      }
    );

    let option3 = Object.assign(
      color3,
      JSON.parse(JSON.stringify(optionCommon)),
      {
        series: [
          {
            type: "radar",
            emphasis: {
              lineStyle: {
                width: 4,
              },
            },
            data: [
              {
                value: this.Invitation_average_data,
                name: "Invitation \n average",
                symbolSize: 1,
                areaStyle: {
                  color: "#57A795",
                  opacity: 0.3,
                },
              },
              {
                value: this.Proactiveness_averagee_data,
                name: "Proactiveness \n average",
                symbolSize: 1,
                areaStyle: {
                  color: "#efb2ae",
                  opacity: 0.3,
                },
              },
              {
                value: this.Enterprise_average_data,
                name: "Enterprise \n average",
                symbolSize: 1,
                areaStyle: {
                  color: "#E9C56E",
                  opacity: 0.3,
                },
              },
            ],
          },
        ],
      }
    );

    let chartDom = document.getElementById("enterprise_average_depart");
    let myChart = echarts.init(chartDom);

    let chartDom2 = document.getElementById("department_ie_feedbacks_depart");
    let myChart2 = echarts.init(chartDom2);

    let chartDom3 = document.getElementById("invitation_proactiveness_depart");
    let myChart3 = echarts.init(chartDom3);

    myChart.setOption(option);

    myChart2.setOption(option2);

    myChart3.setOption(option3);

    this.$forceUpdate();

    $(window).resize(function () {
      myChart.resize();
      myChart2.resize();
      myChart3.resize();
    });
  },
};
</script>
