import { render, staticRenderFns } from "./customPopover.vue?vue&type=template&id=5ece9fd4&scoped=true&"
import script from "./customPopover.vue?vue&type=script&lang=js&"
export * from "./customPopover.vue?vue&type=script&lang=js&"
import style0 from "./customPopover.vue?vue&type=style&index=0&id=5ece9fd4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ece9fd4",
  null
  
)

export default component.exports