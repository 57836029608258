<template>
  <div class="enterDe-all">
    <template v-if="hideMenu">
      <commentTopControl :title="headerTitle" :goBack="true" v-if="!pdfStatus">
        <div class="print-area" slot="right">
          <span class="print-btn" @click="printPage"
            ><img src="@/src/assets/save-picture.png" alt=""
          /></span>
        </div>
      </commentTopControl>
    </template>

    <div class="enterDe-content" :style="!hideMenu ? 'padding-top:0;' : ''">
      <topContent
        v-if="
          pageTop.totalMemberNum != '' && pageTop.totalMemberNum != undefined
        "
        :data="pageTop"
        :data2="invalidFeedbacks"
      />

      <feedBackResult
        v-if="feedBack.feedBackBuResult.length > 0"
        :data="feedBack"
      />

      <scoreByQes
        v-if="
          question.questionBu.length > 0 && qesScoreData.rankQsBu.length > 0
        "
        :data="question"
        :data2="qesScoreData"
        :data3="pageTop"
      />

      <scoreByCreator
        v-if="questionCc.questionBu.length > 0"
        :data="questionCc"
      />

      <section class="enterDe-line">
        <section class="enterDe-state-content span4">
          <rankScore
            ref="rankScoreRef"
            v-if="
              employeeAverage.rankBu.length > 0 &&
              qesScoreData.rankQsBu.length > 0 &&
              question.questionBu.length > 0
            "
            :data1="employeeAverage"
            :data2="qesScoreData"
            :data3="question"
            :pdfStatus="pdfStatus"
          />
        </section>

        <section class="enterDe-state-content span5">
          <employeeScore
            v-if="
              employeeAverage.rankBu.length > 0 &&
              question.questionEnt.scoreAvg != '' &&
              question.questionEnt.scoreAvg != undefined &&
              question.questionEnt.scoreAvg != null
            "
            :data="employeeAverage"
            :data2="question.questionEnt"
          />
        </section>
      </section>
    </div>

    <reportSidebar v-if="emailVipStatus"></reportSidebar>

    <van-overlay
      :show="subLoading"
      z-index="120"
      :custom-style="{ background: 'rgba(0,0,0,0.2)' }"
    >
      <div class="loading-wrap-common fjc">
        <van-loading size="50px" color="#3E886D" text-color="#3E886D" vertical
          >&nbsp;</van-loading
        >
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { mapState, mapMutations, mapActions } from "vuex";
import commentTopControl from "@/src/components/comment/commentTopControl.vue";
import topContent from "@/src/components/enterDe/topContent.vue";
import feedBackResult from "@/src/components/enterDe/feedBackResult.vue";
import scoreByQes from "@/src/components/enterDe/scoreByQes.vue";
import scoreByCreator from "@/src/components/enterDe/scoreByCreator.vue";
import rankScore from "@/src/components/enterDe/rankScore.vue";
import employeeScore from "@/src/components/enterDe/employeeScore.vue";
import reportSidebar from "@/src/components/reportSidebar";

export default {
  components: {
    reportSidebar,
    commentTopControl,
    topContent,
    feedBackResult,
    scoreByQes,
    scoreByCreator,
    rankScore,
    employeeScore,
  },
  computed: {
    ...mapState({
      emailOut: (state) => state.comment.emailOut,
      emailVipStatus: (state) => state.comment.emailVipStatus,
    }),
  },
  data() {
    return {
      hideMenu: true,
      pdfStatus: false,
      subLoading: true,
      waterSign: "",
      headerTitle: "Enterprise",
      feedBack: {
        feedBackBuResult: [],
        feedBackEvpResult: [],
      },

      question: {
        questionBu: [],
        questionEnt: [],
        questionEvp: [],
      },

      questionCc: {
        questionBu: [],
        questionEnt: [],
        questionEvp: [],
      },

      pageTop: {
        totalMemberNum: "",
        validFeedbacksNum: "",
        invalidFeedbacksNum: "",
        optionalEmpNum: "",
      },

      qesScoreData: {
        rankQsBu: [],
        rankQsEvp: [],
      },

      employeeAverage: {
        rankBu: [],
        rankEvp: [],
      },

      invalidFeedbacks: [],
      optionalList: [],
    };
  },
  methods: {
    ...mapActions({
      epTop: "enterPrice/epTop",
    }),
    printPage() {
      this.pdfStatus = true;

      const loading = this.$loading({
        lock: true,
        text: "Ready for printing...",
        background: "rgba(0, 0, 0, 0.7)",
        body: true,
      });

      setTimeout(() => {
        this.$refs.rankScoreRef.pdfInit();

        setTimeout(() => {
          $('<div class="waterSign" id="waterSign"></div>')
            .css({ height: $(".enterDe-all").height() })
            .appendTo(".enterDe-all");

          for (let items = 0; items <= 33; items++) {
            $('<div class="waterSign-in">' + this.emailOut + "</div>").appendTo(
              "#waterSign"
            );
          }
          setTimeout(() => {
            html2canvas(document.querySelector(".enterDe-all")).then(
              (canvas) => {
                let base = canvas.toDataURL("image/jpeg");

                let pdf = new jsPDF("", "pt", "a4");

                let pdfWidth = 592,
                  pdfHeight = 841,
                  docWidth = parseInt($(".enterDe-all").width()),
                  docHeight = parseInt($(".enterDe-all").height()),
                  biImg = pdfHeight / docHeight;

                let dbWidth = docWidth * biImg,
                  dbHeight = pdfHeight;
                let dbWidth_other = 1;

                if (dbWidth > 592) {
                  dbWidth_other = 592 / dbWidth;
                  dbWidth = dbWidth * dbWidth_other;
                  dbHeight = dbHeight * dbWidth_other;
                }

                pdf.addImage(
                  base,
                  "jpeg",
                  (pdfWidth - dbWidth) / 2,
                  0,
                  dbWidth,
                  dbHeight
                ); //592.28, 841.89

                pdf.save("JLR.pdf");

                setTimeout(() => {
                  this.pdfStatus = false;

                  $("#waterSign").remove();

                  loading.close();
                }, 1000);
              }
            );
          }, 2000);
        }, 2000);
      }, 2000);
    },
  },
  async created() {
    let params = {
      surveyModelCode: "JLRCFBM000001",
    };

    let res = await this.epTop(params);

    this.feedBack.feedBackBuResult = res.data.data.feedBackBuResult;
    this.feedBack.feedBackEvpResult = res.data.data.feedBackEvpResult;

    this.question.questionBu = res.data.data.questionBu;
    this.question.questionEnt = res.data.data.questionEnt;
    this.question.questionEvp = res.data.data.questionEvp;

    this.questionCc.questionBu = res.data.data.questionTagBu;
    this.questionCc.questionEnt = res.data.data.questionTagEnt;
    this.questionCc.questionEvp = res.data.data.questionTagEvp;

    this.pageTop.totalMemberNum = res.data.data.totalMemberNum;
    this.pageTop.validFeedbacksNum = res.data.data.validFeedbacksNum;
    this.pageTop.invalidFeedbacksNum = res.data.data.invalidFeedbacksNum;
    this.pageTop.optionalEmpNum = res.data.data.optionalEmpNum;

    this.employeeAverage.rankBu = res.data.data.rankBu;
    this.employeeAverage.rankEvp = res.data.data.rankEvp;

    this.qesScoreData.rankQsBu = res.data.data.rankQsBu;
    this.qesScoreData.rankQsEvp = res.data.data.rankQsEvp;

    this.invalidFeedbacks = res.data.data.invalidFeedbacks;

    this.optionalList = res.data.data.optionalEmps;

    this.subLoading = false;
  },
  mounted() {
    if (this.$route.query.menu == "hide") {
      this.hideMenu = false;
    }
    $("body").on("click", ".qs5iclick", function (e) {
      e.stopPropagation();
      $(this).siblings(".businessUnit").show();
    });

    $(document).click(function () {
      $(".businessUnit").hide();
    });
  },
};
</script>

<style lang="scss">
@import "@/src/styles/enterDe.scss";
</style>
