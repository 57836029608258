import vue from "vue";
import vueX from "vuex";
import api from "./api";
import axios from "./axios";

vue.use(vueX);

let rPagemodules = require.context("./pages", false, /\.js$/);

function getModules(r) {
  let result = {};
  r.forEach((val) => {
    let key = val.slice(2).slice(0, -3);
    result[key] = rPagemodules(val).default;
  });

  return result;
}

export default new vueX.Store({
  state: {
    api,
    axios,
  },
  modules: {
    ...getModules(rPagemodules.keys()),
  },
});
