<template>
  <div class="board-components" :style="!hideMenu ? 'padding-top:0;' : ''">
    <commentTopControl v-if="hideMenu" :title="headerTitle">
      <template slot="right">
        <div
          class="hot-line f12 cg hover"
          @click="isVisibleInfo = !isVisibleInfo"
        >
          <img src="../../../assets/hot-line.png" alt="" />
        </div>
      </template>
    </commentTopControl>
    <ul class="rank-nav" style="display: none">
      <li class="f16" :class="{ active: navIndex === 2 }" @click="changeBu(2)">
        EVP
      </li>
      <li class="f16" :class="{ active: navIndex === 3 }" @click="changeBu(3)">
        VP
      </li>
    </ul>
    <section
      class="rank-wrap"
      :class="{ 'rank-wrap-bg': item.open }"
      v-for="(item, index) in listData"
      :key="index"
    >
      <h2
        class="f16"
        :class="{ 'title-open': item.open, 'title-close': !item.open }"
      >
        <div class="left">
          <img :src="require(`../../../assets/report/board/${item.img}`)" />
          <span>{{ item.title }}</span>
          <img
            src="@/src/assets/hot-line.png"
            alt=""
            class="detqsicon"
            @click="isVisibleInfo = !isVisibleInfo"
          />
        </div>
        <img
          v-if="item.open"
          class="click-btn"
          src="../../../assets/report/board/close.png"
          @click="viewDetail(item.open, index)"
        />
        <img
          v-else
          class="click-btn"
          src="../../../assets/report/board/open.png"
          @click="viewDetail(item.open, index)"
        />
      </h2>
      <div v-if="item.open">
        <div class="one-to-three-wrap" v-if="item.single">
          <div class="bg">
            <img src="../../../assets/report/board/rank_bg.png" alt="" />
          </div>
          <div class="one-to-three mb16">
            <div
              v-for="member in item.data1to3"
              :key="member.rank"
              class="member"
            >
              <span
                class="member-img"
                :class="{ 'member-img-rank1': member.rank == 1 }"
              >
                <img
                  :src="
                    require(`../../../assets/report/board/trophy${member.rank}.png`)
                  "
                />
              </span>
              <span class="f14 member-rank-tit">{{
                member.listArr[0]["userNameCN"]
              }}</span>
              <span
                v-if="index == 1"
                class="f14 member-rank-num"
                :class="{ mb16: member.rank == 1 }"
                >{{ parseFloat(member.score * 100) }}%</span
              >
              <span
                v-else
                class="f14 member-rank-num"
                :class="{ mb16: member.rank == 1 }"
                >{{
                  item.type == 2
                    ? parseFloat(member.score).toFixed(2)
                    : member.score
                }}</span
              >
              <span class="f14 member-rank-num" v-if="index == 0"
                >Invitations</span
              >
              <span class="f14 member-rank-num" v-if="index == 3 || index == 4"
                >Feedbacks</span
              >
              <span class="f14 member-rank-num" v-if="index == 5">Words</span>
            </div>
          </div>
        </div>
        <div class="one-to-three-wrap mb16" v-else style="flex-wrap: wrap">
          <div
            v-for="member in item.data1to3"
            :key="member.rank"
            class="member multiple"
            :class="{ 'one-to-three-border': member.rank >= 4 }"
          >
            <div class="left">
              <span class="member-img" v-if="member.rank < 4">
                <img
                  :src="
                    require(`../../../assets/report/board/trophy${member.rank}.png`)
                  "
                />
              </span>
              <span v-else>
                {{ member.rank }}
              </span>
              <p>
                <span
                  class="member-name"
                  v-for="(name, index) in member.listArr"
                  :key="index"
                  >{{ name["userNameCN"] }}</span
                >
              </p>
            </div>
            <p class="member-score-wrap">
              <span class="member-score f30" v-if="index == 1"
                >{{ parseFloat(member.score * 100) }}%</span
              >
              <span class="member-score f30" v-else>{{
                item.type == 2
                  ? parseFloat(member.score).toFixed(2)
                  : member.score
              }}</span>
              <span class="member-feedbacks f10" v-if="index == 0"
                >Invitations</span
              >
              <span class="member-feedbacks f10" v-if="index == 3 || index == 4"
                >Feedbacks</span
              >
              <span class="member-feedbacks f10" v-if="index == 5">Words</span>
            </p>
          </div>
        </div>
      </div>
    </section>
    <customModal :visible="isVisibleInfo" @onClose="isVisibleInfo = false">
      <div class="mb16" v-for="item in modalData" :key="item.key">
        <span class="f16 fb">{{ item.title }}</span> -
        <span v-html="item.description"></span>
      </div>
    </customModal>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import commentTopControl from "@/src/components/comment/commentTopControl.vue";
import customModal from "./components/customModal";
export default {
  name: "deptartment-board",
  components: {
    commentTopControl,
    customModal,
  },
  data() {
    return {
      hideMenu: true,
      headerTitle: "Department Board",
      navIndex: 2,
      listData: [
        {
          open: true,
          title: "Most Trusted Board",
          data1to3: [],
          data4to5: [],
          type: 1,
          img: "number1.png",
        },
        {
          open: false,
          title: "Most Responsible Board",
          data1to3: [],
          data4to5: [],
          type: 7,
          img: "numberdept_2.png",
        },
        {
          open: false,
          title: "Down to Earth Board",
          data1to3: [],
          data4to5: [],
          type: 2,
          img: "numberdept_3.png",
        },
        {
          open: false,
          title: "Most Enthusiastic Board",
          data1to3: [],
          data4to5: [],
          type: 4,
          img: "number4.png",
        },
        {
          open: false,
          title: "Popularity Star Board",
          data1to3: [],
          data4to5: [],
          type: 5,
          img: "number5.png",
        },
        {
          open: false,
          title: "Best Contribution Board",
          data1to3: [],
          data4to5: [],
          type: 6,
          img: "number6.png",
        },
      ],
      isVisibleInfo: false,
      modalData: [
        {
          key: 1,
          title: "Most Trusted Board",
          description: "Departments with the most invitations per capita",
        },
        {
          key: 2,
          title: "Most Responsible Board",
          description: "Departments with the highest response rates",
        },
        {
          key: 3,
          title: "Down to Earth Board",
          description:
            "The department with the closest average score between internal employees mutual evaluations and external evaluations",
        },
        {
          key: 4,
          title: "Most Enthusiastic Board",
          description:
            "Departments with the most proactive evaluations of others per capita",
        },
        {
          key: 5,
          title: "Popularity Star Board",
          description:
            "Department with the most un-invited feedbacks per capita",
        },
        {
          key: 6,
          title: "Best Contribution Board",
          description:
            "Department with most comments written <span style='font-size: 16px; font-weight: bold;'>per capita</span>",
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.menu == "hide") {
      this.hideMenu = false;
    }
    $(window).scrollTop(0);
    for (let i = 0; i < this.listData.length; i++) {
      this.pageInit(this.listData[i].type, 2);
    }
  },
  methods: {
    ...mapActions({
      getBoardDepartment: "board/getBoardDepartment",
    }),
    changeBu(navIndex) {
      this.navIndex = navIndex;
      for (let i = 0; i < this.listData.length; i++) {
        this.pageInit(this.listData[i].type, navIndex);
      }
    },
    async pageInit(rankType, type) {
      const postData = { rankType, type };
      let res = await this.getBoardDepartment(postData);
      console.log(res.data.data);
      let dataArr = null;
      if (res.data.data.type != 2) {
        dataArr = this.sortfn(
          res.data.data.rptRankingListDTOS.sort(function (a, b) {
            return b.score - a.score;
          })
        );
      } else {
        dataArr = this.sortfn(
          res.data.data.rptRankingListDTOS.sort(function (a, b) {
            return a.score - b.score;
          })
        );
      }
      let resultChoose = {
        ...res.data.data,
        rptRankingList: dataArr,
      };
      console.log(resultChoose);
      let i = this.listData.findIndex(
        (item) => res.data.data.type == item.type
      );
      this.listData[i].data1to3 = resultChoose.rptRankingList.slice(0, 3);
      this.listData[i].data4to5 = resultChoose.rptRankingList.slice(
        3,
        resultChoose.rptRankingList.length
      );
      let single = this.listData[i].data1to3.every((item) => {
        return item.listArr.length === 1;
      });
      this.listData[i]["single"] = single;
      if (single) {
        let rank0 = this.listData[i]["data1to3"][0];
        let rank1 = this.listData[i]["data1to3"][1];
        this.listData[i]["data1to3"][0] = rank1;
        this.listData[i]["data1to3"][1] = rank0;
      }
    },
    sortfn(demo) {
      let resultArr = [];
      for (let i = 0; i < demo.length; i++) {
        const demo_item = demo[i];

        let index = resultArr.findIndex((item) => {
          return item["score"] == demo_item["score"];
        });
        if (index != -1) {
          resultArr[index]["listArr"].push(demo_item);
          if (index > 0) {
            let rank = 0;
            for (let j = 0; j < index; j++) {
              rank += resultArr[j].listArr.length;
            }
            resultArr[index]["rank"] = rank;
          }
        } else {
          let obj = {
            score: demo_item["score"],
            listArr: [demo_item],
            rank: resultArr.length + 1,
          };
          resultArr.push(obj);
        }
      }
      resultArr = resultArr.map((item, index) => {
        let rank = 1;
        for (let j = 0; j < index; j++) {
          rank += resultArr[j].listArr.length;
        }
        return {
          ...item,
          rank,
        };
      });
      return resultArr;
    },
    viewDetail(open, index) {
      this.listData[index].open = !open;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/variable";
@media screen and (max-width: 760px) {
  .detqsicon {
    width: toMRem(16) !important;
    cursor: pointer;
    margin-left: toMRem(10);
  }
  .rank-nav {
    display: flex;
    justify-content: center;
    li {
      width: 40px;
      text-align: center;
      padding-bottom: 8px;
      margin: 0 30px 15px 30px;
    }
    li.active {
      border-bottom: 2px #ecc15b solid;
    }
  }
  .hot-line {
    margin: auto;
    img {
      width: toMRem(16);
      height: toMRem(16);
    }
  }
  .board-components {
    padding-top: toMRem(76);
    padding-bottom: toMRem(50);
  }

  .rank-wrap {
    width: toMRem(338);
    margin: 0 auto toMRem(20);
    background: #fff;
    background-size: cover;
    box-shadow: 0px 4px 9px rgba(209, 209, 209, 0.25);
    border-radius: toMRem(16);
    padding: toMRem(20) 0;
    position: relative;
    .title-open,
    .title-close {
      display: flex;
      align-items: center;
      padding: 0 toMRem(20);
      .left {
        img {
          width: toMRem(40);
        }
      }
    }
    .title-open {
      justify-content: center;
      margin-bottom: toMRem(10);
      .left {
        position: relative;
        img {
          position: absolute;
          left: toMRem(-50);
          top: 50%;
          transform: translateY(-50%);
        }
        .detqsicon {
          position: relative;
          left: auto;
          top: auto;
          transform: none;
        }
      }
      .click-btn {
        position: absolute;
        right: toMRem(20);
        top: toMRem(30);
      }
    }

    .title-close {
      justify-content: space-between;
      .left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          display: inline-block;
          width: toMRem(140);
          margin-left: toMRem(16);
        }
      }
    }
    .one-to-three-wrap {
      position: relative;
      height: toMRem(200);
      overflow: hidden;
      .bg {
        width: toMRem(290);
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
        img {
          width: 100%;
        }
      }
    }
    .one-to-three {
      display: flex;
      justify-content: space-evenly;
      position: absolute;
      bottom: toMRem(10);
      left: 50%;
      transform: translateX(-50%);
      width: toMRem(280);
      .member {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        &-img {
          img {
            width: toMRem(54);
          }
        }
        &-rank-tit {
          text-align: center;
          width: toMRem(110);
          height: toMRem(46);
          overflow: hidden;
          font-size: toMRem(12);
        }
      }
    }

    .one-to-three {
      .member {
        &-img-rank1 {
          position: relative;
          img {
            width: toMRem(78);
          }
        }
      }
    }
    .multiple {
      min-width: 100%;
      flex-direction: row;
      justify-content: space-between;
      display: flex;
      padding: toMRem(8) toMRem(16);
      .left {
        display: flex;
        align-items: center;
        p {
          display: flex;
          flex-direction: column;
          margin-left: toMRem(20);
          .member-name {
            text-align: left;
            line-height: 1.2;
            margin-bottom: toMRem(4);
          }
        }
      }
      .member-score-wrap {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }
      .member-img {
        img {
          width: toMRem(56);
        }
      }
    }
  }
  .rank-wrap-bg {
    background-size: cover;
    background-position: center;
    padding-bottom: 0;
    position: relative;
    &::before {
      content: "";
      width: 100%;
      background: url("../../../assets/report/board/bg.png") no-repeat;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
}
@media screen and (min-width: 761px) {
  .detqsicon {
    width: toPad(16) !important;
    cursor: pointer;
    margin-left: toPad(10);
  }
  .rank-nav {
    display: flex;
    justify-content: center;
    li {
      width: toPad(40);
      text-align: center;
      padding-bottom: toPad(8);
      margin: 0 toPad(30) toPad(15) toPad(30);
      cursor: pointer;
    }
    li.active {
      border-bottom: toPad(2) #ecc15b solid;
    }
  }
  .hot-line {
    margin: auto;
    img {
      width: toPad(16);
      height: toPad(16);
    }
  }
  .board-components {
    padding-top: toPad(100);
    padding-bottom: toPad(50);
  }

  .rank-wrap {
    width: 96vw;
    margin: 0 auto toPad(20);
    background: #fff;
    background-size: cover;
    box-shadow: 0px 4px 9px rgba(209, 209, 209, 0.25);
    border-radius: toPad(16);
    padding: toPad(20) 0;
    position: relative;
    .title-open,
    .title-close {
      display: flex;
      align-items: center;
      padding: 0 toPad(20);
      .left {
        img {
          width: toPad(40);
        }
      }
    }
    .title-open {
      justify-content: center;
      margin-bottom: toPad(10);
      .left {
        position: relative;
        img {
          position: absolute;
          left: toPad(-50);
          top: 50%;
          transform: translateY(-50%);
        }
        .detqsicon {
          position: relative;
          left: auto;
          top: auto;
          transform: none;
        }
      }
      .click-btn {
        position: absolute;
        right: toPad(20);
        top: toPad(30);
      }
    }

    .title-close {
      justify-content: space-between;
      .left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          display: inline-block;
          width: toPad(140);
          margin-left: toPad(16);
        }
      }
    }
    .one-to-three-wrap {
      position: relative;
      height: toPad(216);
      overflow: hidden;
      .bg {
        width: toPad(290);
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
        img {
          width: 100%;
        }
      }
    }
    .one-to-three {
      display: flex;
      justify-content: space-evenly;
      position: absolute;
      bottom: toPad(10);
      left: 50%;
      transform: translateX(-50%);
      width: toPad(280);
      .member {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        &-img {
          img {
            width: toPad(54);
          }
        }
      }
    }

    .one-to-three {
      .member {
        &-img-rank1 {
          position: relative;
          img {
            width: toPad(78);
          }
        }
        &-rank-tit {
          text-align: center;
          width: toPad(110);
          height: toPad(46);
          overflow: hidden;
          font-size: toPad(12);
        }
      }
    }
    .multiple {
      min-width: 100%;
      flex-direction: row;
      justify-content: space-between;
      display: flex;
      padding: toPad(8) toPad(16);
      .left {
        display: flex;
        align-items: center;
        p {
          display: flex;
          flex-direction: column;
          margin-left: toPad(20);
          .member-name {
            text-align: left;
            line-height: 1.2;
            margin-bottom: toPad(4);
          }
        }
      }
      .member-score-wrap {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }
      .member-img {
        img {
          width: toPad(56);
        }
      }
    }
  }
  .rank-wrap-bg {
    background-size: cover;
    background-position: center;
    padding-bottom: 0;
    position: relative;
    &::before {
      content: "";
      background: url("../../../assets/report/board/bg.png") no-repeat;
      background-size: cover;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-position-x: center;
      background-size: 50%;
    }
  }
}
</style>
