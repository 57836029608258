<template>
  <div>
    <div class="enterDe-state-title">
      <label>Employee average score</label>

      <div class="enterDe-state-title-middle">
        <i class="el-icon-question qs5iclick"></i>

        <div class="businessUnit">
          <h3>5 Business Units:</h3>
          <ul>
            <li>JLR China</li>
            <li>IMSS</li>
            <li>Purchasing</li>
            <li>Product Engineering</li>
            <li>CJLR</li>
          </ul>
        </div>
      </div>

      <ul class="enterDe-state-title-control">
        <li
          v-for="(item, index) in roleList"
          :key="'roleFeed' + index"
          :class="{ selected: item.selected }"
          @click="roleChange_feed(item, index)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>

    <section class="employeeScore-chart" id="employeeScore_chart"></section>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: ["data", "data2"],
  data() {
    return {
      pageData: [],
      pageType: "",
      roleList: [
        { name: "Business Unit", type: "bu", selected: true },
        { name: "EVP", type: "evp" },
      ],
    };
  },
  methods: {
    roleChange_feed(data, index) {
      this.roleList.forEach((val) => {
        this.$set(val, "selected", false);
      });

      this.$set(this.roleList[index], "selected", true);

      this.pageType = data.type;
      this.pageInit(this.pageType);
    },
    pageInit(type) {
      let dataAryLs = [],
        dataTagAryLs = [];

      function areaPt(value) {
        let indexNum = "",
          valueP = parseFloat(value);

        if (valueP < 50) {
          indexNum = 0;
        }
        if (valueP >= 50 && valueP < 60) {
          indexNum = 1;
        }
        if (valueP >= 60 && valueP < 70) {
          indexNum = 2;
        }
        if (valueP >= 70 && valueP < 80) {
          indexNum = 3;
        }
        if (valueP >= 80 && valueP < 90) {
          indexNum = 4;
        }
        if (valueP >= 90 && valueP <= 100) {
          indexNum = 5;
        }

        return indexNum;
      }

      let maxNUm = 0;

      if (type === "evp") {
        this.pageData = this.data.rankEvp;
      } else {
        this.pageData = this.data.rankBu;
      }

      this.pageData.forEach((val) => {
        let dataLs = [];

        val.sections.forEach((valSe) => {
          if (valSe.userTotal >= maxNUm) {
            maxNUm = valSe.userTotal;
          }
        });
        for (let i = 0; i < 6; i++) {
          let valPi = {
            valueNew: val.scoreAvg,
            value:
              val.sections && val.sections[i] ? val.sections[i].userTotal : 0,
            label: { position: "top" },
          };

          dataLs.push(valPi);
        }

        let params = {
          name: val.deptName,
          type: "bar",
          barGap: 2,
          data: dataLs,
          label: {
            show: true,
            formatter: (val) => {
              return val.value == 0 ? "" : val.value;
            },
            fontSize: 10,
            lineHeight: 12,
          },
        };

        dataAryLs.push(params);
        dataTagAryLs.push(val.deptName);
      });

      let enterPriceAvgIndex = areaPt(this.data2.scoreAvg);

      let enterPriceAvgData = {
        type: "bar",
        barWidth: 1,
        itemStyle: {
          color: "#fff",
          borderWidth: 1,
          borderColor: "#629FD7",
          borderType: "dashed",
        },

        data: [
          { value: 0, itemStyle: { opacity: 0 } },
          { value: 0, itemStyle: { opacity: 0 } },
          { value: 0, itemStyle: { opacity: 0 } },
          { value: 0, itemStyle: { opacity: 0 } },
          { value: 0, itemStyle: { opacity: 0 } },
          { value: 0, itemStyle: { opacity: 0 } },
        ],
        label: {
          color: "#5184B8",
          show: true,
          formatter: (val) => {
            return val.value == 0
              ? ""
              : "Enterprise\naverage score\n" + val.data.valueNew;
          },

          fontSize: 10,
          lineHeight: 12,
        },
      };

      enterPriceAvgData.data[enterPriceAvgIndex] = {
        valueNew: this.data2.scoreAvg,
        value: maxNUm + 36,
        label: {
          position: [10, -20],
          align: "left",
        },
      };

      dataAryLs.unshift(enterPriceAvgData);

      let option = {
        color: [
          "#d93e1f",
          "#c1d13c",
          "#a2d13c",
          "#4eade9",
          "#d4973f",
          "#4e7de9",
          "#4ee9dc",
          "#d54ee9",
          "#ab71af",
          "#eeca3b",
          "#8fe94e",
          "#e94e88",
          "#f27e97",
          "#f2af7e",
          "#f5e798",
          "#ccf598",
          "#f5a598",
          "#989af5",
          "#bb98f5",
          "#a52fe8",
          "#ce7139",
        ],
        legend: {
          type: "scroll",
          top: 0,
          data: dataTagAryLs,
        },
        grid: {
          top: 80,
          bottom: 40,
        },
        xAxis: [
          {
            axisLabel: {
              rotate: 40,
            },
            type: "category",
            data: ["<50", "50-60", "60-70", "70-80", "80-90", "90-100"],
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "Persons",
            nameLocation: "end",
            nameTextStyle: { padding: [0, 0, 10, -20] },
          },
        ],
        series: dataAryLs,
      };

      let chartDom = document.getElementById("employeeScore_chart");

      echarts.init(chartDom).dispose();

      let myChart = echarts.init(chartDom);
      myChart.setOption(option);

      this.$forceUpdate();

      $(window).resize(function () {
        myChart.resize();
      });
    },
  },
  created() {},
  mounted() {
    this.pageInit();
  },
};
</script>
